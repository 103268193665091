import classNames from 'classnames';
import React from 'react';
import { Spinner } from '../../flayr-components';

interface ISolidBtnProps {
  className?: string;
  color?: 'primary-1' | 'primary-2' | 'hot-pink' | 'default';
  size?: 'sm' | 'md' | 'lg';
  rounded?: string | boolean;
  loading?: boolean;
  block?: boolean;
  onClick?: (e?: any) => void;
}

const SolidBtn: React.FC<React.PropsWithChildren<React.PropsWithChildren<ISolidBtnProps>>> = ({
  className,
  color = 'default',
  size,
  onClick,
  loading,
  children,
  block,
  rounded = 'lg',
}) => {
  return (
    <button
      className={classNames(
        'justify-center items-center text-center focus:outline-none',
        className,
        // display stuff
        {
          'inline-flex': !block,
          flex: block,
          'w-full': block && !className?.includes('w-'),
        },
        // color stuff
        {
          'text-white bg-primary-2': color === 'primary-2',
          'text-white bg-primary': color === 'hot-pink',
          'text-gray-300': color === 'default',
        },
        // size stuff
        {
          'px-6 text-xl min-h-[48px] py-2': size === 'lg',
          'px-5 min-h-[40px] py-1': size === 'md',
        },
        // border radius stuff
        {
          'rounded-lg': rounded === 'lg',
          'rounded-full': rounded === true,
        }
      )}
      onClick={onClick}
    >
      {children}
      {loading && (
        <span style={{ fontSize: '.27em' }} className="ml-3">
          <Spinner showing />
        </span>
      )}
    </button>
  );
};
export default SolidBtn;
