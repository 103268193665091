import classnames from 'classnames';
import React from 'react';
import './StarRating.css';

interface StarRatingProps {
  rating?: number;
  starClassName?: string;
  outlineStar?: boolean;
  fillColor?: string;
  starsCount?: number;
  className?: string;
  onSelect?: (e?: any) => void;
}
export const StarRating: React.FC<React.PropsWithChildren<StarRatingProps>> = ({
  rating = 0,
  starClassName = '',
  onSelect,
  fillColor,
  starsCount = 5,
  className = '',
  outlineStar,
}) => {
  function handleStarClick(e) {
    const { value } = e.target.dataset;
    onSelect?.(+value);
  }

  if (!rating || !starsCount) {
    return null;
  }

  return (
    <span
      className={`inline StarRating relative ${className}`}
      style={
        {
          '--fill-color': fillColor,
          '--fill': `${(rating / starsCount) * 100}%`,
        } as React.CSSProperties
      }
    >
      {[...Array(starsCount)].map((_v, num) => (
        <span
          key={num}
          className={classnames(starClassName, 'inline', {
            'StarRating-star-outlined': outlineStar,
            'StarRating-star': !outlineStar,
          })}
          data-value={num + 1}
          onClick={handleStarClick}
        />
      ))}
    </span>
  );
};
