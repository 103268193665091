import React from 'react';
import { SITE_URL } from '../../../../config';

interface IBreadcrumbsJsonLdProps {
  breadcrumbs?: any[];
}

const BreadcrumbsJsonLd: React.FC<React.PropsWithChildren<IBreadcrumbsJsonLdProps>> = ({
  breadcrumbs = [],
}) => {
  const jsonLd = React.useMemo(() => {
    if (!breadcrumbs.length) {
      return '';
    }
    let schema: any = {
      '@context': 'http://schema.org',
      '@type': 'BreadcrumbList',
      itemListElement: [],
    };
    const titleCase = (str) => {
      return str.replace(/\w\S*/g, (t) => {
        return t.charAt(0).toUpperCase() + t.substr(1).toLowerCase();
      });
    };
    breadcrumbs.map((itm, idx) =>
      schema.itemListElement.push({
        '@type': 'ListItem',
        position: idx + 1,
        name: titleCase(itm.label),
        item: `${SITE_URL}${itm.link}`,
      })
    );
    return JSON.stringify(schema);
  }, [breadcrumbs]);

  return (
    (!!breadcrumbs.length && !!jsonLd && (
      <script
        type="application/ld+json"
        dangerouslySetInnerHTML={{
          __html: jsonLd,
        }}
      />
    )) ||
    null
  );
};
export default BreadcrumbsJsonLd;
