import React from 'react';
import useSeoLandingPageData from '../../hooks/useSeoLandingPageData';
import LandingPageLayout from '../../layouts/LandingPageLayout/LandingPageLayout';

interface ISeoLandingPageProps {}

const SeoLandingPage: React.FC<React.PropsWithChildren<ISeoLandingPageProps>> = ({}) => {
  const state = useSeoLandingPageData();

  return <LandingPageLayout {...state} />;
};
export default SeoLandingPage;
