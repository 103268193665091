import React from 'react';

export interface IComponentsContext {
  mapbox?: {
    accessToken: string;
  };
  googleMaps?: {
    accessToken: string;
  };
  phoneInput?: {
    availableCountries: string[];
    defaultCountry: string;
  };
  modalOpen?: boolean;
  setModalOpen?: (e: boolean) => void;
}
export const ComponentsContext = React.createContext<IComponentsContext>({});
