import './Img.css';
import 'intersection-observer';

import classnames from 'classnames';
import React, { useEffect, useRef } from 'react';

import { getBroswser, getResizedImgUrl, resizeImage } from '../utils';

export type ImgSizes =
  | 'square'
  | '16x16'
  | '24x24'
  | '32x32'
  | '48x48'
  | '64x64'
  | '96x96'
  | '128x128'
  | '1by1'
  | '5by4'
  | '4by3'
  | '3by2'
  | '5by3'
  | '16by9'
  | '2by1'
  | '3by1'
  | '4by5'
  | '3by4'
  | '2by3'
  | '3by5'
  | '9by16'
  | '1by2'
  | '1by3';

export interface IScreenSizes {
  320?: number;
  375?: number;
  425?: number;
  640?: number;
  768?: number;
  1024?: number;
}

interface IImgProps {
  src?: string;
  /**
   * **Note:** this is applied to the container and not the `img`
   *
   * */
  id?: string;
  alt?: string;
  /**
   * placeholder (default image)
   * */
  placeholder?: string;
  /**
   * **Note:** this is applied to the container and not the `img`
   *
   * */
  className?: string;
  /**
   * className for the `img`
   * */
  imgClassName?: string;
  size?: ImgSizes;
  position?: 'top' | 'center' | 'bottom';
  cover?: boolean;
  style?: React.CSSProperties;
  imgStyle?: React.CSSProperties;
  lazy?: boolean;
  responsive?: boolean;
  screenSizes?: IScreenSizes;
  provider?: string;
  grayScale?: boolean;
  round?: boolean;
  roundedCorners?: boolean | 'sm' | 'md' | 'lg' | 'xl' | '2xl' | 'full';
  shadow?: 'xs' | 'md' | 'lg' | 'xl' | '2xl' | boolean;
  width?: number;
  height?: number;
  onClick?: (e: any) => void;
  onImgLoaded?: (e?: any) => void;
  crossOrigin?: '' | 'anonymous' | 'use-credentials' | undefined;
  imgId?: string;
  hideOnError?: boolean;
  placeholderDimension?: 'square' | 'rectangle';
  staticResponsiveSize?: number;
  loadingAnimation?: boolean;
  skipUnobserve?: boolean;
  sizes?: string;
}

const placeholderMapping = {
  square:
    'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAQAAAC1HAwCAAAAC0lEQVR42mPc3g4AAfsBQPu73qsAAAAASUVORK5CYII=',
  rectangle:
    'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAIAAAABCAYAAAD0In+KAAAAD0lEQVR42mMsLS2vZwACAAwyAeJOAM75AAAAAElFTkSuQmCC',
};
export const Img: React.FunctionComponent<React.PropsWithChildren<IImgProps>> = (props) => {
  const {
    src,
    id = '',
    alt = '',
    placeholder,
    className = '',
    imgClassName = '',
    size = '',
    position = 'center',
    cover = true,
    style = {},
    responsive,
    screenSizes = {
      320: 325,
      375: 380,
      425: 430,
      640: 650,
      768: 780,
      1024: 1040,
    },
    lazy = true,
    onClick,
    grayScale,
    crossOrigin,
    onImgLoaded,
    imgId,
    hideOnError,
    round,
    shadow,
    roundedCorners,
    staticResponsiveSize,
    placeholderDimension = 'square',
    imgStyle,
    width = 100,
    height = 100,
    loadingAnimation,
    skipUnobserve,
    sizes,
  } = props;
  const imgRef = useRef<HTMLImageElement>(null);
  const imgObserver = useRef<any>();

  const [retries, setRetries] = React.useState<number>(0);
  const [loaded, setLoaded] = React.useState<boolean>(false);
  const [responsiveSrcForSafari, setResponsiveSrcForSafari] =
    React.useState<any>();

  const browser = getBroswser();

  useEffect(() => {
    if (lazy && imgRef.current) {
      let observer: any = imgObserver.current;
      if (!observer && !!IntersectionObserver) {
        observer = new IntersectionObserver((entries, currentObserver) => {
          entries.forEach((entry: any) => {
            if (entry.isIntersecting) {
              if (!responsive || (responsive && browser !== 'safari')) {
                entry.target.src = entry.target.dataset.src;
              }
              if (responsive && browser !== 'safari') {
                entry.target.srcset = entry.target.dataset.srcset;
              }
              if (responsive && browser === 'safari') {
                const containerWidth = entry.target.width || 0;
                const url = getResizedImgUrl(
                  entry.target.dataset.src,
                  containerWidth * 2
                );
                setResponsiveSrcForSafari(url);
                entry.target.src = url;
              }
              if (!skipUnobserve) {
                currentObserver.unobserve(entry.target);
              }
            }
          });
        });
        imgObserver.current = observer;
      }
      if (observer) {
        observer.observe(imgRef.current);
      }
      if (!IntersectionObserver) {
        imgRef.current.src = imgRef.current.dataset.src || '';
        if (responsive) {
          imgRef.current.srcset = imgRef.current.dataset.srcset || '';
        }
      }
    }
  }, [src]);

  function generateSrcSet() {
    if (src) {
      return Object.keys(screenSizes)
        .map((sc) => {
          const size = `${staticResponsiveSize || sc}`;
          const url = resizeImage({ url: src, size });
          return url;
        })
        .join(', ');
    }
    return '';
  }

  function handleError() {
    if (hideOnError || retries === 5) {
      return imgRef.current?.classList.add('hiddden');
    }
    if (imgRef && imgRef.current) {
      imgRef.current.setAttribute('src', placeholder || '');
      let observer: any = imgObserver.current;
      setRetries(retries + 1);
      if (observer) {
        observer.observe(imgRef.current);
      }
    }
    return null;
  }

  function handleImgClick(e: any) {
    onClick && onClick({ ...e, src, id });
  }

  function handleImgLoaded() {
    const img = imgRef.current;
    const isSafari = getBroswser() === 'safari';
    if (
      (img?.src === src && img?.complete) ||
      (isSafari && (img?.src === responsiveSrcForSafari || img?.src === src))
    ) {
      onImgLoaded?.();
      loadingAnimation && setLoaded(true);
    }
  }

  function getSizes() {
    if (!responsive) {
      return undefined;
    }
    if (sizes) {
      return sizes;
    }
    const sizesArray = Object.keys(screenSizes);
    const newSizes = sizesArray.reduce((acc, cur, idx) => {
      if (idx === sizesArray.length - 1) {
        return acc + `${screenSizes[cur]}px`;
      }
      return acc + `(max-width: ${cur}) ${screenSizes[cur]}px, `;
    }, '');

    return newSizes;
  }

  return (
    <figure
      style={style}
      className={classnames('img Img overflow-hidden', className, {
        [`img-${size}`]: !!size,
        'no-cover': !cover,
        'Img-grayscale': grayScale,
        'rounded-full': round,
        [`rounded-${roundedCorners || ''}`]:
          roundedCorners && typeof roundedCorners === 'string',
        rounded: roundedCorners === true,
        shadow: shadow === true,
        relative: !className.includes('absolute'),
        [`shadow-${shadow}`]: !!shadow && typeof shadow === 'string',
      })}
      onClick={handleImgClick}
    >
      <img
        style={imgStyle}
        className={classnames(imgClassName, 'block', {
          'object-top': position === 'top',
          'object-bottom': position === 'bottom',
          'object-center': position === 'center',
          'object-cover': cover,
          relative: !imgClassName?.includes('absolute'),
          'h-auto': !imgClassName?.includes('h-'),
          'w-full': !imgClassName?.includes('w-'),
          'animate-pulse': loadingAnimation && !loaded,
        })}
        onError={handleError}
        src={
          (lazy && (placeholder || placeholderMapping[placeholderDimension])) ||
          src
        }
        data-src={src}
        data-srcset={responsive ? generateSrcSet() : undefined}
        sizes={getSizes()}
        ref={imgRef}
        alt={alt}
        crossOrigin={crossOrigin}
        onLoad={handleImgLoaded}
        id={imgId}
        width={width}
        height={height}
      />
    </figure>
  );
};
