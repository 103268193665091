import dayjs from 'dayjs';
import React from 'react';

function formatTime(date: string) {
  const localNow = new Date(date).getTime();

  var dateFormatOptions = {
    weekday: 'short',
    year: 'numeric',
    month: 'short',
    day: 'numeric',
    timeZone: 'UTC',
  };

  var timeFormatOptions = {
    timeStyle: 'short',
    // hour12: false,
    timeZone: 'UTC',
  };

  const formattedDate = {
    date: new Intl.DateTimeFormat('en-US', dateFormatOptions as any).format(
      localNow
    ),
    time: new Intl.DateTimeFormat('en-US', timeFormatOptions as any).format(
      localNow
    ),
  };

  return formattedDate;
}
interface IFormattedDateProps {
  date?: string;
  className?: string;
  format?: string;
  readable?: boolean;
  time?: boolean;
}

const FormattedDate: React.FC<React.PropsWithChildren<IFormattedDateProps>> = ({
  date,
  className,
  format = 'YYYY-MM-DD',
  readable,
  time,
}) => {
  if (!date) {
    return null;
  }
  format = (readable && 'ddd, DD MMM, YYYY') || format;

  const formattedDate =
    (time && formatTime(date).time) || dayjs(date).format(format);

  return <span className={className}>{formattedDate}</span>;
};
export default FormattedDate;
