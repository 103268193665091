import React, { useEffect, useState, FunctionComponent } from 'react';
import {
  Route,
  RouteProps,
  Redirect,
  RouteComponentProps,
  withRouter,
} from 'react-router';
import { ConnectedComponent } from 'react-redux';
import {
  isTokenExpired,
  getUserDataFromStorage,
  setUserDataToStorage,
} from '../../utils';

interface PrivateRouteProps extends RouteProps {
  component?:
    | React.ComponentClass<any>
    | ConnectedComponent<any, any>
    | FunctionComponent<React.PropsWithChildren<any>>;
  alwaysEnable?: boolean;
  fallbackUrl?: string;
}
const PrivateRoute: React.FC<React.PropsWithChildren<PrivateRouteProps & RouteComponentProps>> = ({
  component: Component,
  location,
  fallbackUrl = '/login',
  children,
  ...otherprops
}) => {
  const [isAuthenticated, setIsAuthenticated] = useState<
    boolean | 'intermediate' | 'inactive'
  >('intermediate');

  useEffect(() => {
    const token = getUserDataFromStorage().token;
    if (!isTokenExpired(token)) {
      setIsAuthenticated(true);
    } else {
      // expired or no token
      setUserDataToStorage({ pathRequested: location.pathname });
      setIsAuthenticated(false);
    }
  }, []);

  return (
    <Route
      {...otherprops}
      render={(props) => {
        switch (isAuthenticated) {
          case true:
            return (!!Component && <Component {...props} />) || <>{children}</>;
          case false:
            return <Redirect to={fallbackUrl} />;
        }
      }}
    />
  );
};
export default withRouter(PrivateRoute);
