import React from 'react';
import classnames from 'classnames';

interface TabBarProps {
  items: {
    link?: string;
    label?: string;
    icon?: React.ReactNode;
    notifications?: number;
  }[];
  justifyLinks?: 'between' | 'around';
  className?: string;
  sticky?: boolean;
  navLink?: React.ComponentClass<any> | React.FunctionComponent<React.PropsWithChildren<any>>;
  tag?: string;
  activeBgClassName?: string;
  floating?: boolean;
}

export const TabBar: React.FunctionComponent<React.PropsWithChildren<TabBarProps>> = ({
  items = [],
  className = '',
  sticky,
  navLink: NavLink,
  activeBgClassName = 'bg-rose-800 bg-opacity-50',
  justifyLinks = 'around',
  floating,
}) => {
  const links = items.map((item) => (
    <div key={item.link} className="relative flex items-center">
      {!!NavLink && (
        <NavLink
          to={item.link || ''}
          activeClassName={`${activeBgClassName} rounded-full text-white`}
          className={classnames(
            'external block m-0 active:text-white focus:text-white relative',
            {
              'h-6 px-3 flex items-center justify-center': !item.icon,
            }
          )}
        >
          {!item.icon && <div className="text-sm leading-3">{item.label}</div>}
          {item.icon}
        </NavLink>
      )}
      {!NavLink && (
        <a href={item.link}>
          {!item.icon && <div className="text-sm leading-3">{item.label}</div>}
          {item.icon}
        </a>
      )}
      {!!item.notifications && (
        <span className="absolute top-0 right-0 z-20 flex items-center justify-center h-5 px-2 text-xs font-semibold transform translate-x-1 translate-y-1 bg-white rounded-full shadow-lg pointer-events-none text-danger">
          <small>{item.notifications}</small>
        </span>
      )}
    </div>
  ));
  return (
    (!floating && (
      <div
        className={classnames(
          'flex justify-between h-11 px-2 text-white bg-primary sticky top-0',
          `justify-${justifyLinks}`,
          className
        )}
      >
        {links}
      </div>
    )) || (
      <div className="fixed top-0 left-0 z-20 w-full p-2">
        <div
          className={classnames(
            'flex justify-between h-11 px-2 text-white backdrop-blur-10 sticky top-0 rounded-lg overflow-hidden mx-auto lg:w-7/12',
            `justify-${justifyLinks}`,
            className
          )}
        >
          <div className="absolute inset-0 opacity-75 bg-primary" />
          {links}
        </div>
      </div>
    )
  );
};
