import React from 'react';
import ArrayMap from '../../lib/ArrayMap/ArrayMap';
import { IService } from '../../types/service';
import { IServiceType } from '../../types/serviceType';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { IBasicInfo } from '../../types/basicInfo';
import { faTimesCircle } from '@fortawesome/pro-light-svg-icons';
import { Flex } from '../../flayr-components/Flex/Flex';
import { Button } from '../../flayr-components/Button/Button';

interface ISearchFormServiceListProps {
  services: string[];
  serviceTypes: IServiceType[];
  onDelete?: (e?: any, id?: string) => void;
}

const SearchFormServiceList: React.FC<
  React.PropsWithChildren<React.PropsWithChildren<ISearchFormServiceListProps>>
> = ({ serviceTypes = [], services, onDelete }) => {
  function getService(id): IService {
    let servicesList: IBasicInfo[] = [];
    serviceTypes.map((st) => (servicesList = servicesList.concat(st.services)));
    return servicesList.find((service) => service.id === id) as IService;
  }

  function getServiceType(id) {
    return serviceTypes.find((st) => st.services.some((s) => s?.id === id));
  }
  function handleDelete(e, id) {
    e.stopPropagation();
    onDelete?.(e, id);
  }

  return (
    <section className="p-4">
      <ArrayMap
        items={services}
        render={(serviceId, idx) => {
          const service = getService(serviceId);
          return (
            <Flex y="start" className="w-full mb-3">
              <div className="w-6">
                <span className="font-medium">{idx + 1}.</span>
              </div>
              <div className="flex-grow">
                <div className="font-medium">
                  <>{getServiceType(serviceId)?.label}</>
                </div>
                <div>
                  <>{service?.label}</>
                </div>
              </div>
              <div className="self-center">
                <Button clear color="hot-pink" onClick={handleDelete} id={idx}>
                  <FontAwesomeIcon
                    className="text-xl pointer-events-none"
                    icon={faTimesCircle}
                  />
                </Button>
              </div>
            </Flex>
          );
        }}
      />
    </section>
  );
};
export default SearchFormServiceList;
