import Axios from 'axios';
import useLoaderStore from './useLoaderStore';
import { API_URL } from '../config';
import { getAuthHeader } from '../utils';
import useToast from './useToast';
import { IHttpResponse } from '../types/httpResponse';

export interface IUseBackendApiOption {
  auth?: boolean;
  loader?: boolean;
  headers?: any;
  onError?: (e: any) => void;
  showToastOnError?: boolean;
  showToastOnSuccess?: boolean;
  successMsg?: string;
  data?: any;
}
const defaultOptions: IUseBackendApiOption = {
  auth: true,
  loader: true,
  headers: {},
  onError: undefined,
  showToastOnError: false,
  showToastOnSuccess: false,
  successMsg: 'Success',
};

export default function useBackendApi() {
  const { showLoader, hideLoader } = useLoaderStore();
  const { errorToast, successToast, toastError, toastSuccess } = useToast();
  async function makeHttpCall<T = any>(
    type: any,
    url: any,
    options: IUseBackendApiOption,
    dataOrParams: any,
    showLoader: any,
    hideLoader: any
  ): Promise<T> {
    try {
      options = { ...defaultOptions, ...options };
      options.loader && showLoader();
      let headers = options.headers || {};
      if (options.auth) {
        headers = getAuthHeader(headers);
      }
      url = `${API_URL}${url}`;
      let res: { data: T } = { data: null } as any;
      switch (type) {
        case 'get':
          res = await Axios.get(url, { params: dataOrParams, ...headers });
          break;
        case 'post':
          res = await Axios.post(url, dataOrParams, headers);
          break;
        case 'patch':
          res = await Axios.patch(url, dataOrParams, headers);
          break;
        case 'del':
          if (options.data) {
            headers.data = options.data;
          }
          res = await Axios.delete(url, headers);
          break;
      }
      options.loader && hideLoader();

      options.showToastOnSuccess && toastSuccess(options.successMsg || '');
      return res.data;
    } catch (err) {
      return catchFn(err, options, hideLoader);
    }
  }

  function catchFn(
    error: any,
    options: IUseBackendApiOption,
    hideLoader: () => void
  ): any {
    options.loader && hideLoader();
    const errorData = error?.response?.data || error;
    options.showToastOnError && toastError(getErrorMessage(error));
    options.onError && options.onError(errorData);
    throw error;
  }

  function getErrorMessage(error: any) {
    return error.response.data.message;
  }
  async function get<T = any>(
    url: string,
    params: any = {},
    options: IUseBackendApiOption = {
      auth: true,
      loader: true,
      headers: {},
      onError: undefined,
      showToastOnError: false,
    }
  ): Promise<T> {
    return makeHttpCall<T>('get', url, options, params, showLoader, hideLoader);
    // try {
    // } catch (error) {
    //   console.log('catch2');
    //   return catchFn(error, options, hideLoader);
    // }
  }

  async function post(
    url: string,
    data: any = {},
    options: IUseBackendApiOption = {
      auth: true,
      loader: true,
      headers: {},
      onError: undefined,
      showToastOnError: false,
    }
  ) {
    return makeHttpCall('post', url, options, data, showLoader, hideLoader);
  }

  async function patch<T = any>(
    url: string,
    data: any = {},
    options: IUseBackendApiOption = {
      auth: true,
      loader: true,
      headers: {},
      onError: undefined,
      showToastOnError: false,
    }
  ) {
    return makeHttpCall<T>('patch', url, options, data, showLoader, hideLoader);
    // try {
    // } catch (error) {
    //   // return catchFn(error, options, hideLoader);
    // }
  }

  async function del(
    url: string,
    params: any = {},
    options: IUseBackendApiOption = {
      auth: true,
      loader: true,
      headers: {},
      onError: undefined,
      showToastOnError: false,
    }
  ) {
    try {
      return makeHttpCall('del', url, options, params, showLoader, hideLoader);
    } catch (error) {
      // return catchFn(error, options, hideLoader);
    }
  }

  return { get, post, patch, del };
}
