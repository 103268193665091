import React from 'react';

export interface IGradientTextProps {
  text?: string;
  gradientStyle?: string;
  gradientClassName?: string;
}

const GradientText: React.FC<React.PropsWithChildren<IGradientTextProps>> = ({
  text,
  gradientClassName = 'bg-current',
  gradientStyle,
  children,
}) => {
  return (
    <span className="leading-none tracking-tight">
      <span
        className={`bg-clip-text text-transparent ${gradientClassName}`}
        style={gradientStyle ? { background: gradientStyle } : undefined}
      >
        {text}
        {children}
      </span>
    </span>
  );
};
export { GradientText };
