import { faChevronLeft } from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import { Button, IButtonProps } from '../Button/Button';
import { Flex } from '../Flex/Flex';

export interface IActionBtnsProps {
  onNext?: (e?: any) => void;
  onPrev?: (e: any) => void;
  nextBtnContainerClassName?: string;
  prevBtnContainerClassName?: string;
  nextBtnLabel?: React.ReactChild;
  prevBtnLabel?: React.ReactChild;
  nextBtnProps?: IButtonProps;
  prevBtnProps?: IButtonProps;
  className?: string;
}

const ActionBtns: React.FC<React.PropsWithChildren<IActionBtnsProps>> = ({
  onNext,
  onPrev,
  nextBtnLabel = 'Next',
  prevBtnLabel = (
    <span className="inline-flex items-center">
      <FontAwesomeIcon icon={faChevronLeft} className="mr-2" /> Back
    </span>
  ),
  nextBtnProps = {},
  prevBtnProps = {},
  className,
  nextBtnContainerClassName = 'flex-grow',
  prevBtnContainerClassName = 'w-1/2',
}) => {
  return (
    <Flex className={className}>
      <div className={prevBtnContainerClassName}>
        <Button onClick={onPrev} {...{ clear: true, ...prevBtnProps }}>
          {prevBtnLabel}
        </Button>
      </div>
      <div className={nextBtnContainerClassName}>
        <Button
          onClick={onNext}
          {...{
            color: 'hot-pink',
            rounded: 'full',
            block: true,
            ...nextBtnProps,
          }}
        >
          {nextBtnLabel}
        </Button>
      </div>
    </Flex>
  );
};
export { ActionBtns };
