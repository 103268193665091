import './PlacesInput.css';

import { Flex } from '../../flayr-components/Flex/Flex';
import React from 'react';

import { ComponentsContext } from '../../contexts/componentsContext';
import MapboxGeocoder from '../MapboxGeocoder/MapboxGeocoder';
import { PLACES_INPUT_AVAILABLE_COUNTRIES } from '../../config';
import classNames from 'classnames';
import {
  convertMapboxLocationToLocation,
  getClearedLocation,
} from '../../utils/projectSpecificUtils';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes } from '@fortawesome/pro-light-svg-icons';

interface IPlacesInputProps {
  index?: number;
  placeholder?: string;
  value?: string;
  hideClear?: boolean;
  optionsZIndex?: number;
  autoFocus?: boolean;
  label?: string;
  id?: string;
  optionsContainerClassName?: string;
  optionsContainerStyle?: React.CSSProperties;
  labelClassName?: string;
  inputClassName?: string;
  inputStyle?: React.CSSProperties;
  validation?: any[];
  fixedOptions?: boolean;
  clearBtnClassName?: string;
  clearBtnContainerClassname?: string;
  formatLocation?: boolean;
  countries?: string[];
  proximity?: number[];
  filterResults?: (e?: any) => void;
  onFocus?: (e: any) => void;
  onSelect?: (e: any) => void;
  onClear?: (e?: any) => void;
  onChange?: (e: any) => void;
  onBlur?: (e: any) => void;
  onOptionsUpdate?: (e: any) => void;
}

const PlacesInput = React.forwardRef<HTMLInputElement, IPlacesInputProps>(
  (
    {
      onSelect,
      index,
      placeholder,
      value,
      hideClear,
      optionsZIndex = 40,
      autoFocus,
      onClear,
      label,
      labelClassName,
      validation,
      inputClassName = 'flex-grow px-4 py-2 text-lg bg-white bg-opacity-50 border border-gray-200 rounded focus:outline-none focus:border focus:border-gray-500 focus:bg-opacity-100',
      inputStyle,
      fixedOptions = true,
      clearBtnClassName = '',
      clearBtnContainerClassname,
      optionsContainerClassName,
      optionsContainerStyle,
      formatLocation,
      id,
      onFocus,
      onChange,
      countries = [],
      filterResults,
      proximity,
      onOptionsUpdate,
      onBlur,
    },
    ref
  ) => {
    const [viewport, setViewport] = React.useState<any>({});

    const optionsRef = React.useRef<HTMLInputElement>(null);
    const context = React.useContext(ComponentsContext);

    const onSelected = (viewport, item) => {
      setViewport(viewport);
      if (formatLocation) {
        item = convertMapboxLocationToLocation(item);
      }
      onSelect && onSelect({ ...item, index });
    };

    async function handleClear(e) {
      onClear?.(index);
      if (formatLocation) {
        onOptionsUpdate?.([]);
        onSelect?.(getClearedLocation());
      }
    }

    const queryParams = React.useMemo(() => {
      const qp: any = {};

      qp.country =
        (countries?.length && countries.join(',')) ||
        PLACES_INPUT_AVAILABLE_COUNTRIES;

      if (proximity && proximity.length === 2) {
        qp.proximity = {
          longitude: proximity[0],
          latitude: proximity[1],
        };
      }

      return qp;
    }, [countries, PLACES_INPUT_AVAILABLE_COUNTRIES, proximity]);

    if (!context.mapbox?.accessToken) {
      return null;
    }

    function getDimensions() {
      if (optionsRef.current) {
        const el = optionsRef.current;
        if (el) {
          const bb = el.getBoundingClientRect();
          return {
            '--left': `${bb.left || 0}px`,
            '--top': `${bb.top || 0}px`,
            '--z-index': optionsZIndex,
            '--width': `${bb.width || 0}px`,
          };
        }
      }
    }

    function handleOptionsUpdate(options) {
      if (formatLocation) {
        options = options.map((item) => convertMapboxLocationToLocation(item));
      }
      onOptionsUpdate?.(options);
    }

    return (
      <div
        className="relative PlacesInput"
        style={getDimensions() as React.CSSProperties}
      >
        <label className={labelClassName}>{label}</label>

        <MapboxGeocoder
          mapboxApiAccessToken={context.mapbox.accessToken}
          onSelected={onSelected}
          viewport={viewport}
          updateInputOnSelect
          hideOnSelect
          value={value}
          fixedOptions={fixedOptions}
          queryParams={queryParams}
          onChange={onChange}
          optionsContainerClassName={optionsContainerClassName}
          optionsContainerStyle={optionsContainerStyle}
          option
          onFocus={onFocus}
          onBlur={onBlur}
          filterResults={filterResults}
          onClear={handleClear}
          onOptionsUpdate={handleOptionsUpdate}
          inputComponent={(props) => {
            return (
              <Flex y="stretch" className="relative">
                <input
                  {...props}
                  className={inputClassName}
                  style={inputStyle}
                  placeholder={placeholder}
                  autoFocus={autoFocus}
                  id={id}
                  ref={ref}
                  autoComplete="off"
                />

                <div
                  className={classNames(
                    'absolute top-0 right-0 flex items-center h-full pr-1',
                    clearBtnContainerClassname
                  )}
                >
                  {value && !hideClear && (
                    <button
                      className={classNames(
                        `h-8 w-8 focus:outline-none cursor-pointer`,
                        clearBtnClassName,
                        {
                          'bg-gray-200 rounded-full shadow-sm':
                            !clearBtnClassName,
                        }
                      )}
                      onClick={handleClear}
                    >
                      <FontAwesomeIcon icon={faTimes} />
                    </button>
                  )}
                </div>
              </Flex>
            );
          }}
        />
        <div ref={optionsRef} className="w-full" />
      </div>
    );
  }
);
export default PlacesInput;
