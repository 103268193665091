import { useLocation } from 'react-router-dom';

export default function useQueryParams() {
  const location = useLocation();

  const parsedQuery =
    (location.search &&
      JSON.parse(
        '{"' +
          decodeURI(
            location.search
              .replace('?', '')
              .replace(/&/g, '","')
              .replace(/=/g, '":"')
          ) +
          '"}'
      )) ||
    {};

  return { query: parsedQuery };
}
