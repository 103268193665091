import { faPlus } from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import { Button } from '../../flayr-components/Button/Button';
import { Flex } from '../../flayr-components/Flex/Flex';
import { ShowIf } from '../../flayr-components/ShowIf/ShowIf';
import useToggle from '../../hooks/useToggle';
import SingularOrPluralText from '../../lib/SingularOrPluralText/SingularOrPluralText';
import SearchFormServiceList from '../SearchFormServiceList/SearchFormServiceList';
import ServiceSelect from '../ServiceSelect/ServiceSelect';

interface ISearchWizardServiceSelectProps {
  services?: string[];
  availableServices: any[];
  addBtnPlacement?: 'top-right' | 'bottom-right';
  serviceTypeLabel?: string;
  serviceLabel?: string;
  hideAddButton?: boolean;
  onSelect?: (e?: any) => void;
  renderAddBtn?: (e: { onAdd?: (e: any) => void }) => React.ReactNode;
}

const SearchWizardServiceSelect: React.FC<React.PropsWithChildren<React.PropsWithChildren<ISearchWizardServiceSelectProps>>> = ({
  availableServices = [],
  services = [],
  addBtnPlacement = 'bottom-right',
  onSelect,
  renderAddBtn,
  serviceTypeLabel,
  serviceLabel,
  hideAddButton,
}) => {
  const adding = useToggle();

  React.useEffect(() => {
    if (!services.length && adding.closed) {
      adding.open();
      return adding.close;
    }
    if (services.length && adding.opened) {
      adding.close();
      return adding.open;
    }
  }, [services, availableServices]);

  function handleServiceSelect(service) {
    adding.close();
    onSelect?.([...services, service]);
  }

  function handleDelete(_e, idxToRemove) {
    const filtered = services.filter((_, idx) => idx !== idxToRemove);
    onSelect?.(filtered);
  }

  const AddBtnSection = (
    <section hidden={adding.opened || hideAddButton}>
      {!renderAddBtn && (
        <Flex x="center" className="cursor-pointer" onClick={adding.open}>
          <Button
            color="primary"
            className="w-12 h-12 pointer-events-none"
            round
          >
            <FontAwesomeIcon className="text-xl" icon={faPlus} />
          </Button>
        </Flex>
      )}
      {renderAddBtn && renderAddBtn({ onAdd: adding.open })}
    </section>
  );

  return (
    <div>
      {addBtnPlacement === 'top-right' && (
        <div className="mb-4 -mt-4">{AddBtnSection}</div>
      )}

      {adding.opened && (
        <ServiceSelect
          serviceTypeLabel={serviceTypeLabel}
          serviceLabel={serviceLabel}
          serviceTypes={availableServices}
          onSelect={handleServiceSelect}
          onCancel={adding.close}
          size="lg"
        />
      )}

      {adding.closed && !!services.length && (
        <SearchFormServiceList
          serviceTypes={availableServices}
          services={services}
          onDelete={handleDelete}
        />
      )}

      {addBtnPlacement === 'bottom-right' && (
        <div className="mt-1">{AddBtnSection}</div>
      )}
    </div>
  );
};
export default SearchWizardServiceSelect;
