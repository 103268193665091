import { faBars, faTimes } from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import classNames from 'classnames';
import React from 'react';
import { Link, useLocation } from 'react-router-dom';
import { Button } from '../../flayr-components/Button/Button';
import { Flex } from '../../flayr-components/Flex/Flex';
import useAuth from '../../hooks/useAuth';
import useToggle from '../../hooks/useToggle';
import ArrayMap from '../../lib/ArrayMap/ArrayMap';
import LogoNew from '../../lib/LogoNew/LogoNew';
import Portal from '../../lib/Portal/Portal';

interface IMenuItem {
  label: string;
  url: string;
  openInNewTab?: boolean;
  external?: boolean;
  id?: string;
}
interface IHamburgerMenuProps {
  iconClassName?: string;
  menuItems?: Array<IMenuItem>;
}

const HamburgerMenu: React.FC<React.PropsWithChildren<React.PropsWithChildren<IHamburgerMenuProps>>> = ({
  menuItems = [],
  iconClassName = 'text-2xl',
}) => {
  const { loggedIn } = useAuth();
  const menu = useToggle();
  const location = useLocation();
  const [menuList, setMenuList] = React.useState<IMenuItem[]>([]);

  React.useEffect(() => {
    let items = menuItems;

    if (!menuItems.length) {
      items = [
        {
          label: 'Provider Dashboard',
          url: '/dashboard',
          id: 'customerDashboard',
        },
        {
          label: 'Customer Dashboard',
          url: '/customer-dashboard',
          id: 'providerDashboard',
        },
        { label: 'Log In', url: '/login', id: 'login' },
        { label: 'Log Out', url: '/logout', id: 'logout' },
        { label: 'Join Us', url: '/join' },
        // { label: 'Reviews', url: '/content/reviews' },
        {
          label: 'Reviews',
          url: '/content/reviews',
          // url: 'https://www.flayr.com.au/content/reviews',
          openInNewTab: true,
          external: true,
        },
        { label: 'Help', url: '/content/help' },
        { label: 'About Us', url: '/content/about' },
        { label: 'Legals - Customers', url: '/content/legals-customers' },
        { label: 'Legals - Providers', url: '/content/legals-providers' },
      ];
    }
    const list = items
      .filter((i) => {
        if (loggedIn && i.id === 'login') {
          return false;
        }
        if (
          !loggedIn &&
          ['customerDashboard', 'providerDashboard', 'logout'].includes(
            i.id || ''
          )
        ) {
          return false;
        }
        return true;
      })
      .map((i) => {
        if (i.id === 'logout') {
          return {
            ...i,
            url: `/logout?redirect=${location.pathname}`,
          };
        }
        return i;
      });

    setMenuList(list);
  }, [loggedIn]);

  return (
    <>
      <Button ariaLabel="Menu" clear onClick={menu.toggle}>
        <FontAwesomeIcon icon={faBars} className={iconClassName} />
      </Button>
      <Portal>
        <aside
          className={classNames(
            'transform fixed top-0 left-0 w-full h-full z-40 bg-white',
            {
              'translate-x-0': menu.opened,
              '-translate-x-full': menu.closed,
            }
          )}
        >
          <Flex x="between" className="p-4">
            <Link to="/">
              <LogoNew />
            </Link>
            <Button clear onClick={menu.toggle}>
              <FontAwesomeIcon className="text-xl" icon={faTimes} />
            </Button>
          </Flex>
          <div className="p-5">
            <ArrayMap
              items={menuList}
              render={({ label, url, openInNewTab, external }) => (
                <>
                  <Link
                    className={classNames('block p-3 text-gray-700', {
                      hidden: external,
                    })}
                    to={url}
                    target={(openInNewTab && '__blank') || undefined}
                    rel={(openInNewTab && 'noopener noreferrer') || undefined}
                  >
                    {label}
                  </Link>
                  <a
                    className={classNames('block p-3 text-gray-700', {
                      hidden: !external,
                    })}
                    href={url}
                    target={(openInNewTab && '__blank') || undefined}
                    rel={(openInNewTab && 'noopener noreferrer') || undefined}
                  >
                    {label}
                  </a>
                </>
              )}
            />
          </div>
        </aside>
      </Portal>
    </>
  );
};
export default HamburgerMenu;
