import React from 'react';
import { useInView } from 'react-intersection-observer';
import { useHistory } from 'react-router';
import HamburgerMenu from '../../../../components/HamburgerMenu/HamburgerMenu';
import SearchFormWizard from '../../../../components/SearchFormWizard/SearchFormWizard';
import SolidBtn from '../../../../components/SolidBtn/SolidBtn';
import SearchFormContainer from '../../../../containers/SearchFormContainer/SearchFormContainer';
import CreateSearchContextProvider from '../../../../contexts/createSearchContext';
import {
  Flex,
  StarRating,
  TestimonialCard,
} from '../../../../flayr-components';
import { Heading } from '../../../../flayr-components/Heading/Heading';
import { Img } from '../../../../flayr-components/Img/Img';
import { Spinner } from '../../../../flayr-components/Spinner/Spinner';
import useToggle from '../../../../hooks/useToggle';
import ArrayMap from '../../../../lib/ArrayMap/ArrayMap';
import Inset from '../../../../lib/Inset/Inset';
import LogoNew from '../../../../lib/LogoNew/LogoNew';
import Scroller from '../../../../lib/Scroller/Scroller';
import SearchIcon from '../../../../lib/SearchIcon/SearchIcon';
import { IMedia } from '../../../../types/media';

// const SearchFormContainer = React.lazy(
//   () => import('../../../../containers/SearchFormContainer/SearchFormContainer')
// );
// const CreateSearchContextProvider = React.lazy(
//   () => import('../../../../contexts/createSearchContext')
// );
// const SearchFormWizard = React.lazy(
//   () => import('../../../../components/SearchFormWizard/SearchFormWizard')
// );

interface IMobileBannerProps {
  title?: string;
  subTitle?: string;
  media?: IMedia[];
  testimonials?: any[];
  popularPlaces?: any[];
  providers?: any[];
  locationPlaceholder?: string;
  totalReviewScore?: number;
  totalReviewCount?: number;
  bannerImg?: {
    url?: string;
    alt?: string;
  };
  proximity?: number[];
  onLoaded?: (e?: any) => void;
}

const MobileBanner: React.FC<React.PropsWithChildren<IMobileBannerProps>> = ({
  title,
  subTitle,
  media = [],
  popularPlaces = [],
  testimonials = [],
  providers = [],
  totalReviewCount,
  totalReviewScore,
  onLoaded,
  locationPlaceholder,
  bannerImg,
  proximity,
}) => {
  const wizardModal = useToggle();
  const history = useHistory();
  const { inView, ref } = useInView();

  function handleCtaClick() {
    history.replace({
      pathname: history.location.pathname,
      search: '?stage=location',
    });
    wizardModal.open();
  }

  return (
    <>
      {/* {!inView && (
        <section className="fixed top-0 z-20 flex items-center w-full h-20 px-5 -mb-20 bg-black shadow md:hidden">
          <div className="relative w-full">
            <CtaBtn
              onCtaClick={handleCtaClick}
              placeholder={locationPlaceholder}
            />
          </div>
        </section>
      )} */}

      <section className="relative z-30 pt-5 pb-4 bg-black h-10/12-screen md:hidden">
        {!!bannerImg?.url && (
          <>
            {!!bannerImg.url && (
              <Img
                src={bannerImg.url}
                alt={bannerImg?.alt}
                className="absolute top-0 left-0 w-full h-full"
                imgClassName="absolute top-0 left-0 w-full h-full"
                responsive
                lazy={false}
                sizes="100vw"
              />
            )}
            <div className="absolute top-0 left-0 w-full h-full bg-gradient-to-b from-[rgba(0,0,0,.5)] to-black" />
          </>
        )}
        <div className="relative top-0 left-0 flex flex-col w-full h-full py-5">
          <Flex x="end" className="pr-2">
            <HamburgerMenu iconClassName="text-white text-xl" />
          </Flex>
          <section className="px-5 my-5">
            <div className="text-3xl font-medium text-white text-shadow-lg">
              <LogoNew />
            </div>
          </section>
          <section className="px-5 pb-5 text-white">
            <Heading tag="h1" size="4xl" bold="medium" className="min-h-[12vh]">
              {title}
            </Heading>
            <Heading tag="h2" className="flex items-center h-20 mt-3">
              {subTitle}
            </Heading>
          </section>

          <div className="z-20 h-32 px-5 mt-12">
            <div data-testid="cta-btn-container" className="relative w-full">
              <CtaBtn
                onCtaClick={handleCtaClick}
                placeholder={locationPlaceholder}
              />
            </div>
          </div>
          {!!popularPlaces.length && (
            <div className="w-full px-5 mb-8 -mt-1 text-white">
              <Heading tag="h2" size="lg" bold="medium">
                Popular places
              </Heading>
              <div className="flex w-full pb-3 space-x-3 overflow-x-auto flex-nowrap">
                <ArrayMap items={popularPlaces}>
                  {(place) => (
                    <a
                      href={place.link}
                      className="flex-none text-gray-200 underline"
                    >
                      {place.title || place.name}
                    </a>
                  )}
                </ArrayMap>
              </div>
            </div>
          )}

          {/* <div className="px-4 pb-3 mt-auto text-center text-white">
            Made with ❤️
            <span className="inline-block mx-2">+</span>
            <span className="inline-block mr-3">☕️</span>
            in Sydney, Australia
          </div> */}
        </div>

        <div
          hidden={!(providers.length || testimonials.length || media.length)}
          className="pl-5  text-white min-h-[36vh] py-8"
        >
          {!!providers.length && (
            <Scroller innerContainerClassName="space-x-5">
              <ArrayMap items={providers}>
                {(item, idx) => (
                  <div className="relative flex-none w-6/12">
                    <Img
                      roundedCorners="xl"
                      size="4by5"
                      src={item?.profileImg?.src}
                      alt={item?.profileImg?.alt}
                      responsive
                      onImgLoaded={
                        (idx === 0 &&
                          onLoaded &&
                          (() => setTimeout(onLoaded, 0))) ||
                        undefined
                      }
                      screenSizes={
                        { 320: 190, 375: 230, 414: 256, 640: 372 } as any
                      }
                    />
                    <Inset
                      position="bottom"
                      className="pb-2 text-xl font-medium text-center text-shadow-lg"
                    >
                      {item.name}
                    </Inset>
                  </div>
                )}
              </ArrayMap>
            </Scroller>
          )}
          {false && !!testimonials.length && (
            <div className="grid gap-4">
              <div className="text-center">
                {!!(totalReviewScore && totalReviewCount) && (
                  <p className="text-lg">
                    <span className="font-medium">{totalReviewScore}</span>{' '}
                    <StarRating rating={1} starsCount={1} />{' '}
                    <span className="text-gray-200">Based on </span>
                    <span className="font-medium">
                      {totalReviewCount?.toLocaleString()} Reviews
                    </span>
                  </p>
                )}
              </div>

              <div className="flex w-full space-x-4 overflow-x-auto flex-nowrap">
                <ArrayMap items={testimonials}>
                  {(item, idx) => (
                    <TestimonialCard
                      className="self-stretch flex-none w-9/12 ml-px"
                      shadow
                      rounded="lg"
                      name={item.customerName}
                      rating={item.reviewScore}
                      img={item.coverImg.src}
                      alt={item.coverImg.alt}
                      description={item.comment}
                      onImgLoaded={
                        (idx === 0 &&
                          onLoaded &&
                          (() => setTimeout(onLoaded, 0))) ||
                        undefined
                      }
                    />
                  )}
                </ArrayMap>
              </div>
            </div>
          )}
          {false && !!media.length && (
            <>
              <Heading className="mb-3" size="sm">
                Work of Flayr makeup artists
              </Heading>
              <Scroller innerContainerClassName="space-x-5">
                <ArrayMap
                  items={media}
                  render={(item, idx) => (
                    <div className="flex-none w-8/12">
                      <Img
                        roundedCorners="xl"
                        size="4by5"
                        src={item?.mediaUrl}
                        alt={item?.alt}
                        responsive
                        onImgLoaded={
                          (idx === 0 &&
                            onLoaded &&
                            (() => setTimeout(onLoaded, 0))) ||
                          undefined
                        }
                        screenSizes={
                          { 320: 190, 375: 230, 414: 256, 640: 372 } as any
                        }
                      />
                    </div>
                  )}
                />
              </Scroller>
            </>
          )}
        </div>
      </section>
      <div className="transform translate-y-20" ref={ref} />
      {wizardModal.opened && (
        <React.Suspense fallback={<Spinner showing fullScreen />}>
          <CreateSearchContextProvider>
            <SearchFormContainer
              fetchFormSettingsOnInputChange
              component={SearchFormWizard}
              externalProps={{
                useLocalState: false,
                open: wizardModal.opened,
                onClose: wizardModal.close,
                locationPlaceholder,
                proximity,
              }}
            />
          </CreateSearchContextProvider>
        </React.Suspense>
      )}
    </>
  );
};
export default MobileBanner;

const CtaBtn = ({ onCtaClick, placeholder }) => (
  <>
    <div className="relative">
      <button
        className="w-full py-2 pl-8 pr-4 text-lg text-left truncate bg-white border-2 border-transparent rounded-2xl focus:outline-none focus:border-primary"
        onClick={onCtaClick}
      >
        {placeholder}
      </button>
      <div className="absolute inset-y-0 left-0 flex items-center h-full pl-3 text-sm pointer-events-none">
        <SearchIcon />
      </div>
    </div>
    <div className="mt-3">
      <SolidBtn onClick={onCtaClick} size="lg" rounded color="hot-pink" block>
        Search
      </SolidBtn>
      {/* <Button rounded="lg" size="lg" block onClick={onCtaClick} color="primary">
      </Button> */}
    </div>
  </>
);
