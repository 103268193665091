import React from 'react';
import Toast from '../lib/Toast/Toast';

export interface IToastContext {
  open: boolean;
  type?: string;
  message?: string;
  showToast?: (type: string, message: string) => void;
  hideToast?: () => void;
}
export const ToastContext = React.createContext<IToastContext>({
  open: false,
});

export const ToastContextProvider = ToastContext.Provider;
