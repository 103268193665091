import React, { useEffect } from 'react';
import Heap from 'react-heap';
import { Provider as ReduxProvider } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';
import SnackbarProvider from 'react-simple-snackbar';
import Hotjar from './components/Hotjar/Hotjar';
import {
  ENV,
  GOOGLE_MAPS_ACCESS_TOKEN,
  HEAP_APP_ID,
  // HEAP_APP_ID,
  MAPBOX_ACCESS_TOKEN,
  PHONE_INPUT_DEFAULT_COUNTRY,
  PHONE_INPUT_SUPPORTED_COUNTRIES,
} from './config';
import { AppContextProvider } from './contexts/AppContext';
import { ComponentsContext } from './contexts/componentsContext';
import { GaTrackingContextProvider } from './contexts/GaTrackingContext';
import DeferLoading from './lib/DeferLoading/DeferLoading';
import AppRoutes from './routes/AppRoutes/AppRoutes';
import store from './store';
import './styles/global-styles.css';

function App() {
  const [modalOpen, setModalOpen] = React.useState<boolean>(false);

  useEffect(() => {
    const html = document.querySelector('html')!;
    const body = document.querySelector('body')!;
    if (modalOpen) {
      html.classList.add('overflow-y-hidden');
      body.classList.add('overflow-y-hidden');
    } else {
      if (body.classList.contains('overflow-y-hidden')) {
        html.classList.remove('overflow-y-hidden');
        body.classList.remove('overflow-y-hidden');
      }
    }
  }, [modalOpen]);
  return (
    <ReduxProvider store={store}>
      <AppContextProvider>
        <ComponentsContext.Provider
          value={{
            mapbox: {
              accessToken: MAPBOX_ACCESS_TOKEN,
            },
            googleMaps: {
              accessToken: GOOGLE_MAPS_ACCESS_TOKEN,
            },
            phoneInput: {
              availableCountries: PHONE_INPUT_SUPPORTED_COUNTRIES,
              defaultCountry: PHONE_INPUT_DEFAULT_COUNTRY,
            },
            modalOpen,
            setModalOpen,
          }}
        >
          <BrowserRouter>
            <SnackbarProvider>
              <GaTrackingContextProvider>
                <AppRoutes />
              </GaTrackingContextProvider>
            </SnackbarProvider>
          </BrowserRouter>
        </ComponentsContext.Provider>

        <Hotjar />

        {ENV !== 'development' && (
          <DeferLoading
            component={Heap}
            externalProps={{ appId: HEAP_APP_ID }}
          />
        )}
      </AppContextProvider>
    </ReduxProvider>
  );
}

export default App;
