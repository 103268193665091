import React from 'react';
import { ENV } from '../../config';

interface IHotjarProps {}

const Hotjar: React.FC<React.PropsWithChildren<React.PropsWithChildren<IHotjarProps>>> = ({}) => {
  React.useEffect(() => {
    if (ENV !== 'development') {
      const script = document.createElement('script');
      script.type = 'text/javascript';
      script.id = 'hotjar';
      script.defer = true;
      script.innerHTML = `
        (function(h,o,t,j,a,r){
            h.hj=h.hj||function(){(h.hj.q=h.hj.q||[]).push(arguments)};
            h._hjSettings={hjid:2964311,hjsv:6};
            a=o.getElementsByTagName('head')[0];
            r=o.createElement('script');r.async=1;
            r.src=t+h._hjSettings.hjid+j+h._hjSettings.hjsv;
            a.appendChild(r);
            })(window,document,'https://static.hotjar.com/c/hotjar-','.js?sv=');
      `;
      document.body.appendChild(script);
    }
  }, []);

  return null;
};
export default Hotjar;
