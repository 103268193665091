import React, { Component } from 'react';
import PropTypes from 'prop-types';
import MapboxClient from 'mapbox';
import classnames from 'classnames';

class MapboxGeocoder extends Component {
  debounceTimeout = null;
  state = {
    results: [],
    showResults: false,
    inputValue: '',
  };

  static getDerivedStateFromProps(nextProps, state) {
    if (state.inputValue.length === 0 && nextProps.initialInputValue !== '') {
      return {
        inputValue: nextProps.initialInputValue,
      };
    }

    return null;
  }

  onChange = (event) => {
    const {
      timeout,
      queryParams,
      localGeocoder,
      limit,
      localOnly,
      filterResults,
    } = this.props;
    const queryString = event.target.value;

    this.setState({
      inputValue: event.target.value,
    });
    if (!event.target.value) {
      this.props.onClear?.();
    }

    clearTimeout(this.debounceTimeout);
    this.debounceTimeout = setTimeout(() => {
      const localResults = localGeocoder ? localGeocoder(queryString) : [];
      const params = {
        ...queryParams,
        ...{ limit: limit - localResults.length },
      };
      let results = [];
      if (queryString.length < 3) {
        this.props.onOptionsUpdate?.(results);
      }
      if (params.limit > 0 && !localOnly && queryString.length > 0) {
        this.client.geocodeForward(queryString, params).then((res) => {
          const features = (res.entity && res.entity.features) || [];
          results = [...localResults, ...features];
          if (filterResults) {
            results = results.filter(filterResults);
          }
          this.setState({
            results,
          });
          if (queryString.length > 2) {
            this.props.onOptionsUpdate?.(results);
          }
          if (results.length && !this.state.showResults) {
            this.showResults();
          }
        });
      } else {
        const results = localResults;
        this.setState({
          results,
        });

        if (results.length && !this.state.showResults) {
          this.showResults();
        }
      }
    }, timeout);
  };

  onSelected = (item, e) => {
    e.stopPropagation();
    e.preventDefault();
    const {
      viewport,
      onSelected,
      transitionDuration,
      hideOnSelect,
      pointZoom,
      formatItem,
      updateInputOnSelect,
    } = this.props;
    const { center } = item;

    const newViewport = {
      longitude: center[0],
      latitude: center[1],
      zoom: pointZoom,
    };

    const { longitude, latitude, zoom } = newViewport;

    onSelected(
      { ...viewport, ...{ longitude, latitude, zoom, transitionDuration } },
      item
    );

    if (hideOnSelect) {
      this.setState({ results: [] });
    }

    if (updateInputOnSelect) {
      this.setState({ inputValue: formatItem(item) });
    }
  };

  showResults = (e) => {
    this.setState({ showResults: true });
    this.props.onFocus?.(e);
  };

  hideResults = () => {
    setTimeout(() => {
      this.setState({ showResults: false });
    }, 300);
    return true;
  };

  handleBlur = (e) => {
    this.hideResults();
    this.props.onBlur?.(e);
  };

  constructor(props) {
    super();
    this.client = new MapboxClient(props.mapboxApiAccessToken);
  }

  componentDidMount() {
    if (this.props.value) {
      this.setState({ inputValue: this.props.value });
    }
  }

  componentDidUpdate(prevProps) {
    if (prevProps.value !== this.props.value) {
      this.setState({ inputValue: this.props.value });
    }
  }
  componentWillUnmount() {
    !!this.state.results.length && this.hideResults();
  }

  render() {
    const { results, showResults, inputValue } = this.state;
    const { formatItem, className, inputComponent, itemComponent } = this.props;

    const Input = inputComponent || 'input';
    const Item = itemComponent || 'div';

    return (
      <div className={`react-geocoder ${className}`}>
        <Input
          onChange={this.onChange}
          onBlur={this.handleBlur}
          onFocus={this.showResults}
          value={inputValue}
        />

        {showResults && !!results.length && (
          <div
            className={classnames(
              'shadow-md md:translate-0 md:translate-x-2',
              this.props.optionsContainerClassName,
              {
                'react-geocoder-results': this.props.fixedOptions,
                'react-geocoder-results-abs': !this.props.fixedOptions,
              }
            )}
            style={this.props.optionsContainerStyle}
          >
            {results.map((item, index) => (
              <Item
                key={index}
                className="flex items-center text-sm leading-tight text-gray-700 cursor-pointer react-geocoder-item hover:bg-gray-100"
                onClick={(e) => this.onSelected(item, e)}
                item={item}
              >
                {formatItem(item)}
              </Item>
            ))}
          </div>
        )}
      </div>
    );
  }
}

MapboxGeocoder.propTypes = {
  timeout: PropTypes.number,
  queryParams: PropTypes.object,
  viewport: PropTypes.object.isRequired,
  onSelected: PropTypes.func.isRequired,
  onClear: PropTypes.func,
  transitionDuration: PropTypes.number,
  hideOnSelect: PropTypes.bool,
  pointZoom: PropTypes.number,
  mapboxApiAccessToken: PropTypes.string.isRequired,
  formatItem: PropTypes.func,
  className: PropTypes.string,
  inputComponent: PropTypes.func,
  itemComponent: PropTypes.func,
  limit: PropTypes.number,
  localGeocoder: PropTypes.func,
  localOnly: PropTypes.bool,
  updateInputOnSelect: PropTypes.bool,
  initialInputValue: PropTypes.string,
  value: PropTypes.string,
  fixedOptions: PropTypes.bool,
  optionsContainerClassName: PropTypes.string,
  optionsContainerStyle: PropTypes.object,
  filterResults: PropTypes.func,
  onOptionsUpdate: PropTypes.func,
  onBlur: PropTypes.func,
};

MapboxGeocoder.defaultProps = {
  timeout: 0,
  transitionDuration: 0,
  hideOnSelect: false,
  updateInputOnSelect: false,
  pointZoom: 16,
  formatItem: (item) => item.place_name,
  queryParams: {},
  className: '',
  limit: 5,
  initialInputValue: '',
  value: '',
  fixedOptions: true,
  optionsContainerClassName: undefined,
  optionsContainerStyle: undefined,
  filterResults: undefined,
  onOptionsUpdate: undefined,
  onBlur: undefined,
};

export default MapboxGeocoder;
