import React from 'react';

interface IGaTrackingContext {
  initialized?: boolean;
  initialize?: (e?: any) => void;
}
const GaTrackingContext = React.createContext<IGaTrackingContext>({
  initialized: false,
});

export const GaTrackingContextProvider: React.FC<
  React.PropsWithChildren<any>
> = (props) => {
  const [initialized, setInitialized] = React.useState<boolean>(false);

  return (
    <GaTrackingContext.Provider
      value={{ initialized, setInitialized }}
      {...props}
    />
  );
};

export function useGaTrackingContext() {
  const ctx = React.useContext(GaTrackingContext);
  return ctx;
}
