export const formFields = [
  // {
  //   name: 'timeConstraint',
  //   type: 'select',
  //   placeholder: 'Select Start time or Finish time*',
  //   options: ['start', 'finish'],
  // },
  {
    name: 'startTime',
    type: 'timeSelect',
    placeholder: 'I can start from*',
    dependsOn: 'timeConstraint',
    whenValueIs: 'start',
  },
  {
    name: 'finishTime',
    type: 'timeSelect',
    placeholder: 'I need to be finished by',
    dependsOn: 'timeConstraint',
    whenValueIs: 'finish',
    label: 'What time do you need to be ready by?*',
  },
  {
    name: 'name',
    type: 'input',
    placeholder: 'Enter your name',
    validation: ['required'],
    label: 'Your Name*',
  },
  {
    name: 'email',
    type: 'email',
    placeholder: 'Enter email',
    label: 'Your Email*',
    validation: ['required', 'email'],
  },
  {
    name: 'event',
    type: 'input',
    placeholder: 'Enter event',
    validation: ['required'],
    label: 'Event*',
  },
  {
    note: 'Your mobile is only used to notify you if the provider is available',
    name: 'mobile',
    type: 'mobile',
    placeholder: 'Your Mobile*',
  },
];
