export function generateId() {
  let a = new Uint32Array(3);
  crypto.getRandomValues(a);
  return (
    performance.now().toString(36) +
    Array.from(a)
      .map((A) => A.toString(36))
      .join('')
  ).replace(/\./g, '');
}

export function getResizedImgUrl(url: string, width: string | number) {
  if (!url) {
    return '';
  }
  if (url.match('media.flayr.life') || url.match('flayr-media')) {
    let imageName = url.replace(/^.*\/\/[^/]+\//, '');
    const cdnUrl = 'https://media.flayr.life';
    return `${cdnUrl}/${width}x${width}/${imageName}`;
  }
  return url;
}

function getResizedImgUrlForCdnLinks(url: string, width: string | number) {
  if (!url) {
    return '';
  }
  let imageName = url.replace(/^.*\/\/[^/]+\//, '');
  const cdnUrl = 'https://cdn.flayr.life/fit-in';
  return `${cdnUrl}/${width}x${width}/${imageName}`;
}

function getResizedImgUrlForLegacyLinks(url: string, width: string | number) {
  const filename = url.split('/').reverse()[0];
  const cdnUrl = 'https://d1fr8s0eujep1w.cloudfront.net';
  return `${cdnUrl}/fit-in/${width}x${width}/${filename} ${width}w`;
}

export function resizeImage({ url = '', size = '' }) {
  if (!url) {
    return '';
  }

  const defaultResizerDomains = ['media.flayr.life', 'flayr-media'];
  const matchesDefaultDomain = defaultResizerDomains.some((domain) =>
    url.match(domain)
  );

  const newResizerDomains = ['cdn.flayr'];

  const matchesNewResizerDomains = newResizerDomains.some((domain) =>
    url.match(domain)
  );

  const legacyDomain = [
    'https://dashboard-uploads-2.s3.ap-southeast-2.amazonaws.com',
  ];
  const matchesLegacyDomain = legacyDomain.some((domain) => url.match(domain));

  if (matchesDefaultDomain) {
    const imgUrl = getResizedImgUrl(url, size);
    let newUrl = `${imgUrl} ${size}w`;
    return newUrl;
  } else if (matchesNewResizerDomains) {
    const imgUrl = getResizedImgUrlForCdnLinks(url, size);
    let newUrl = `${imgUrl} ${size}w`;
    return newUrl;
  } else if (matchesLegacyDomain) {
    return getResizedImgUrlForLegacyLinks(url, size);
  } else {
    return url;
  }
}

// export function useCdn() {
//   if (url.match('flayr-media')) {
//     let parts = url.split('.com/');
//     let imageName = parts[1];
//     const cdnUrl = 'https://media.flayr.life';
//     let newUrl = `${cdnUrl}/${size}x${size}/${imageName} ${(resolution && resolution) || width}${(resolution && 'x') || 'w'}`;
//     return newUrl;
//   } else {
//     return url;
//   }
// }

export function getFontAwesomeIconArray(str): [any, any] {
  if (!str || typeof str !== 'string') {
    return [undefined, undefined];
  }
  const split = str.split(' ');
  return [split[0], split[1].replace('fa-', '')];
}

function getDeviceType() {
  var check = false;
  (function (a) {
    if (
      // eslint-disable-next-line
      /(android|bb\d+|meego).+mobile|avantgo|bada\/|blackberry|blazer|compal|elaine|fennec|hiptop|iemobile|ip(hone|od)|iris|kindle|lge |maemo|midp|mmp|mobile.+firefox|netfront|opera m(ob|in)i|palm( os)?|phone|p(ixi|re)\/|plucker|pocket|psp|series(4|6)0|symbian|treo|up\.(browser|link)|vodafone|wap|windows ce|xda|xiino/i.test(
        a
      ) ||
      // eslint-disable-next-line
      /1207|6310|6590|3gso|4thp|50[1-6]i|770s|802s|a wa|abac|ac(er|oo|s\-)|ai(ko|rn)|al(av|ca|co)|amoi|an(ex|ny|yw)|aptu|ar(ch|go)|as(te|us)|attw|au(di|\-m|r |s )|avan|be(ck|ll|nq)|bi(lb|rd)|bl(ac|az)|br(e|v)w|bumb|bw\-(n|u)|c55\/|capi|ccwa|cdm\-|cell|chtm|cldc|cmd\-|co(mp|nd)|craw|da(it|ll|ng)|dbte|dc\-s|devi|dica|dmob|do(c|p)o|ds(12|\-d)|el(49|ai)|em(l2|ul)|er(ic|k0)|esl8|ez([4-7]0|os|wa|ze)|fetc|fly(\-|_)|g1 u|g560|gene|gf\-5|g\-mo|go(\.w|od)|gr(ad|un)|haie|hcit|hd\-(m|p|t)|hei\-|hi(pt|ta)|hp( i|ip)|hs\-c|ht(c(\-| |_|a|g|p|s|t)|tp)|hu(aw|tc)|i\-(20|go|ma)|i230|iac( |\-|\/)|ibro|idea|ig01|ikom|im1k|inno|ipaq|iris|ja(t|v)a|jbro|jemu|jigs|kddi|keji|kgt( |\/)|klon|kpt |kwc\-|kyo(c|k)|le(no|xi)|lg( g|\/(k|l|u)|50|54|\-[a-w])|libw|lynx|m1\-w|m3ga|m50\/|ma(te|ui|xo)|mc(01|21|ca)|m\-cr|me(rc|ri)|mi(o8|oa|ts)|mmef|mo(01|02|bi|de|do|t(\-| |o|v)|zz)|mt(50|p1|v )|mwbp|mywa|n10[0-2]|n20[2-3]|n30(0|2)|n50(0|2|5)|n7(0(0|1)|10)|ne((c|m)\-|on|tf|wf|wg|wt)|nok(6|i)|nzph|o2im|op(ti|wv)|oran|owg1|p800|pan(a|d|t)|pdxg|pg(13|\-([1-8]|c))|phil|pire|pl(ay|uc)|pn\-2|po(ck|rt|se)|prox|psio|pt\-g|qa\-a|qc(07|12|21|32|60|\-[2-7]|i\-)|qtek|r380|r600|raks|rim9|ro(ve|zo)|s55\/|sa(ge|ma|mm|ms|ny|va)|sc(01|h\-|oo|p\-)|sdk\/|se(c(\-|0|1)|47|mc|nd|ri)|sgh\-|shar|sie(\-|m)|sk\-0|sl(45|id)|sm(al|ar|b3|it|t5)|so(ft|ny)|sp(01|h\-|v\-|v )|sy(01|mb)|t2(18|50)|t6(00|10|18)|ta(gt|lk)|tcl\-|tdg\-|tel(i|m)|tim\-|t\-mo|to(pl|sh)|ts(70|m\-|m3|m5)|tx\-9|up(\.b|g1|si)|utst|v400|v750|veri|vi(rg|te)|vk(40|5[0-3]|\-v)|vm40|voda|vulc|vx(52|53|60|61|70|80|81|83|85|98)|w3c(\-| )|webc|whit|wi(g |nc|nw)|wmlb|wonu|x700|yas\-|your|zeto|zte\-/i.test(
        a.substr(0, 4)
      )
    )
      check = true;
  })(navigator.userAgent || navigator.vendor || window['opera']);
  return check ? 'mobile' : 'desktop';
}

export function getOS() {
  if (getDeviceType() === 'desktop') {
    let OSName = 'Unknown OS';
    if (navigator.appVersion.indexOf('Win') !== -1) OSName = 'Windows';
    if (navigator.appVersion.indexOf('Mac') !== -1) OSName = 'MacOS';
    if (navigator.appVersion.indexOf('X11') !== -1) OSName = 'UNIX';
    if (navigator.appVersion.indexOf('Linux') !== -1) OSName = 'Linux';
    return OSName;
  } else {
    let userAgent = navigator.userAgent || navigator.vendor || window['opera'];
    if (
      userAgent.match(/iPad/i) ||
      userAgent.match(/iPhone/i) ||
      userAgent.match(/iPod/i)
    ) {
      return 'iOS';
    } else if (userAgent.match(/Android/i)) {
      return 'Android';
    } else {
      return 'unknown';
    }
  }
}

export function getBroswser() {
  let browserName = '';

  if (navigator.vendor.match(/google/i)) {
    browserName = 'chrome';
  } else if (navigator.vendor.match(/apple/i)) {
    browserName = 'safari';
  } else if (navigator.userAgent.match(/firefox\//i)) {
    browserName = 'firefox';
  } else if (navigator.userAgent.match(/edg\//i)) {
    browserName = 'edge';
  } else if (navigator.userAgent.match(/trident\//i)) {
    browserName = 'ie';
  } else {
    browserName = navigator.userAgent;
  }
  return browserName;
}
