import { INavLink } from '../../lib/Sidebar/Sidebar';

export const sidebarNavLinks: INavLink[] = [
  {
    label: 'Your details',
    url: '/signup/profile/register',
    disabled: true,
    id: 'userDetails',
  },
  {
    label: 'Location',
    url: '/signup/profile/set-location',
    disabled: true,
    id: 'location',
  },
  {
    label: 'Add a Service',
    url: '/signup/select-services',
    disabled: true,
    id: 'service',
  },
  {
    label: 'Pricing',
    url: '/signup/setup-services/:categoryId/pricing',
    disabled: true,
    id: 'pricing',
  },
  {
    label: 'Travel',
    url: '/signup/setup-services/:categoryId/confirm-travel',
    disabled: true,
    id: 'travel',
  },
  {
    label: 'Minimum job size',
    url: '/signup/setup-services/:categoryId/set-min-job-size',
    disabled: true,
    id: 'minJobCost',
  },
  {
    label: 'Instagram',
    url: '/signup/setup-services/:categoryId/set-instagram',
    disabled: true,
    id: 'instagram',
  },
  {
    label: 'Kit',
    url: '/signup/setup-services/:categoryId/set-kit',
    disabled: true,
    id: 'kit',
  },
  {
    label: 'Bio',
    url: '/signup/setup-services/:categoryId/set-bio',
    disabled: true,
    id: 'bio',
  },
  {
    label: 'Profile photo',
    url: '/signup/verification/photo',
    disabled: true,
    id: 'photo',
  },
  {
    label: 'Verify your identity',
    url: '/signup/verification/selfie',
    disabled: true,
    id: 'selfie',
  },
  {
    label: 'Submit',
    url: '/signup/verification/submit',
    disabled: true,
    id: '',
  },
];
