import React from 'react';
import { Route, Switch } from 'react-router-dom';
import SeoLandingPage from '../../pages/SeoLandingPage/SeoLandingPage';

const HappyPlaceLandingPage = React.lazy(
  () => import('../../pages/HappyPlaceLandingPage/HappyPlaceLandingPage')
);
const HalloweenLandingPage = React.lazy(
  () => import('../../pages/HalloweenLandingPage/HalloweenLandingPage')
);

interface ISeoPageRoutesProps {}

const SeoPageRoutes: React.FC<React.PropsWithChildren<ISeoPageRoutesProps>> = ({}) => {
  return (
    <React.Suspense
      fallback={
        <div className="fixed inset-0 flex items-center justify-center bg-black">
          <div className="text-3xl text-primary">Flayr</div>
        </div>
      }
    >
      <Switch>
        <Route exact path="/" component={SeoLandingPage} />

        {/* <Route
          exact
          path="/:country/makeup-artists/:city"
          component={SEP}
        />

        <Route
          exact
          path="/:country/makeup-artists/:city"
          component={SEP}
        /> */}
        {/* <Route
          exact
          path={[
            '/invite/:refId',
            '/hairdressing',
            '/makeup-and-hair',
            '/hair-and-makeup-artists',
          ]}
          component={GenericLandingPage}
        /> */}

        {/* event page */}
        {/* <Route
          exact
          path="/makeup-and-hair/:event"
          component={EventLandingPage}
        /> */}

        {/* <Route
          exact
          path={[
            '/popular-suburbs',
            '/popular-suburbs/:state',
            '/popular-suburbs/:state/:letter',
          ]}
          component={PopularSuburbsLandingPage}
        /> */}

        {/* <Route exact path="/:state" component={StateLandingPage} /> */}

        <Route exact path="/halloween" component={HalloweenLandingPage} />
        <Route exact path="/happy-place" component={HappyPlaceLandingPage} />
        <Route
          exact
          path={[
            '/:country/:category/:neighborhood',
            '/:country/:category/:neighborhood/:event',
          ]}
          component={SeoLandingPage}
        />
        <Route component={SeoLandingPage} />
      </Switch>
    </React.Suspense>
  );
};
export default SeoPageRoutes;
