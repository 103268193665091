import React from 'react';
import './Radio.css';
import classnames from 'classnames';

export interface IRadioProps {
  name?: string;
  value?: string;
  checked?: boolean;
  onChange?: (e: any) => void;
  readOnly?: boolean;
  size?: 'sm' | 'md' | 'lg';
  className?: string;
  id?: string;
}

const sizeClassMapping = {
  sm: 'w-4 h-4',
  md: 'w-6 h-6',
  lg: 'w-8 h-8',
};

const borderSizeMapping = {
  sm: 'border-2',
  md: 'border-4',
  lg: 'border-4',
};

const Radio: React.FC<React.PropsWithChildren<IRadioProps>> = ({
  name,
  value,
  readOnly,
  checked,
  onChange,
  size = 'md',
  className,
  id,
}) => {
  return (
    <label className="cursor-pointer Radio" htmlFor={id}>
      <input
        type="radio"
        name={name}
        value={value}
        checked={checked}
        readOnly={readOnly || !onChange}
        onChange={onChange}
        className="w-0 h-0 opacity-0 appearance-none"
        id={id}
      />
      <div
        className={classnames(
          'inline-flex items-stretch overflow-hidden rounded-full Radio-toggle p-px bg-gray-400',
          sizeClassMapping[size],
          className
        )}
      >
        <div
          className={classnames('w-full border-white rounded-full bg-white', {
            'border-2': 'sm' === size,
            'border-4': ['md', 'lg'].includes(size),
          })}
        />
      </div>
    </label>
  );
};
export { Radio };
