import axios from 'axios';
import React from 'react';
import { useLocation } from 'react-router-dom';
import { API_URL } from '../config';
import { londonLandingPageData } from '../data/london-landing';

function reducer(state, action) {
  switch (action.type) {
    case 'SET_MEDIA':
      return { ...state, gallery: action.payload };
    case 'SET_STATE':
      return { ...state, ...action.payload };
    default:
      return state;
  }
}

function humanizeAndCapitalize(str) {
  return str
    .split('_')
    .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
    .join(' ');
}

export default function useSeoLandingPageData(
  options: { jsonFileName?: string } = { jsonFileName: 'index.json' }
) {
  const location = useLocation();
  let [, , , city] = location.pathname.split('/');

  const [state, dispatch]: [any, (e?: any) => void] = React.useReducer(
    reducer,
    {
      title: `Find Hair And Makeup Artists ${
        !!city && `In ${humanizeAndCapitalize(city)}`
      }`,
      metaDescription: `Get the best prices from 100+ of the most reviewed Makeup Artists and Hair stylists${
        !!city && ` In ${humanizeAndCapitalize(city)}`
      }. Pick from mobile stylists or salons.`,
      city,
      banner: {
        title: `Find Hair And Makeup Artists ${
          !!city && `In ${humanizeAndCapitalize(city)}`
        }`,
        subTitle: `Get the best prices from 100+ of the most reviewed Makeup Artists and Hair stylists${
          !!city && ` In ${humanizeAndCapitalize(city)}`
        }. Pick from mobile stylists or salons.`,
      },
    }
  );

  React.useEffect(() => {
    const fetchData = async () => {
      if (city === 'london') {
        dispatch({ type: 'SET_STATE', payload: londonLandingPageData });
      } else {
        const api = `${API_URL}/v1/seo/gatsby/pages`;
        const endpoint = `${api}?link=${location.pathname}`;
        const res = await axios.get(endpoint);
        if (res.data?.data?.seoPage) {
          dispatch({ type: 'SET_STATE', payload: res.data.data.seoPage });
        }
      }
    };
    fetchData();
  }, [location.pathname]);

  // React.useEffect(() => {
  //   const fetchData = async () => {
  //     // TODO: enable data fetching later
  //     const cdn = 'cdn.flayr.life';
  //     const pathName =
  //       (location.pathname === '/' && 'index') || location.pathname;
  //     let url = `${cdn}/${pathName}/index.json`.replaceAll('//', '/');
  //     url = cdn;
  //     url = `https://${url}`;
  //     const reviewUrl = `https://${cdn}/reviews/makeup-hair-styling/index.json`;
  //     // const reviewRes = await axios.get(reviewUrl);
  //     try {
  //       const [reviewRes, res] = await Promise.all([
  //         axios.get(reviewUrl),
  //         axios.get(url),
  //       ]);
  //       // const res = await axios.get(url);
  //       dispatch({
  //         type: 'SET_STATE',
  //         payload: { ...res.data, ...reviewRes.data },
  //       });
  //     } catch (err) {
  //       url = `https://${cdn}/index.json`;
  //       const [reviewRes, res] = await Promise.all([
  //         axios.get(reviewUrl),
  //         axios.get(url),
  //       ]);
  //       dispatch({
  //         type: 'SET_STATE',
  //         payload: { ...res.data, ...reviewRes.data },
  //       });
  //     }
  //   };
  //   fetchData();
  // }, [jsonFileName]);

  return state;
}
