import classNames from 'classnames';
import React from 'react';
import Portal from '../../../../../lib/Portal/Portal';

interface IInputContainerProps {
  name: string;
  popDownItem?: React.ReactChild;
  open?: boolean;
  popDownPosition?: 'left' | 'right';
  popDownIdPrefix?: string;
}

const InputContainer: React.FC<React.PropsWithChildren<IInputContainerProps>> = ({
  name,
  popDownItem,
  open,
  children,
  popDownPosition = 'left',
  popDownIdPrefix,
}) => {
  return (
    <>
      <div className="flex items-stretch flex-grow w-full">{children}</div>
      <Portal target={`#${name}-popdown-${popDownIdPrefix}`}>
        <div
          hidden={!open}
          className={classNames('absolute top-0  pt-3 w-96', {
            'left-0': popDownPosition === 'left',
            'right-0': popDownPosition === 'right',
          })}
        >
          <div className="w-full px-3 pt-4 bg-white border border-gray-100 shadow-lg rounded-xl">
            {popDownItem}
          </div>
        </div>
      </Portal>
    </>
  );
};
export default InputContainer;
