import React from 'react';
import ArrayMap from '../ArrayMap/ArrayMap';
import { ICalendarMatrix } from '../Calendar/Calendar';
import ObjectKeysMap from '../ObjectKeysMap/ObjectKeysMap';
import classnames from 'classnames';
import dayjs, { Dayjs } from 'dayjs';
import { Flex } from '../../flayr-components/Flex/Flex';
import { Box } from '../../flayr-components/Box/Box';

interface ICalendarBodyProps {
  calendarMatrix: ICalendarMatrix;
  blockedOutDates?: any[];
  currentDate: Dayjs;
  selectedDates?: Dayjs[];
  datesWithBookings?: Dayjs[];
  disablePastDates?: boolean;
  disableUniformHeight?: boolean;
  onDateSelect?: (e?: any, date?: string) => void;
}

function isToday(currentDate: Dayjs, date: string) {
  currentDate = currentDate.set('date', +date);
  return dayjs().isSame(currentDate, 'date');
}

function isPast(currentDate: Dayjs, date: string) {
  currentDate = currentDate.set('date', +date);
  let today = dayjs().startOf('day');
  return currentDate.isBefore(today);
}

function isHighlightedDay(
  currentDate: Dayjs,
  date: string,
  selectedDays: Dayjs[]
) {
  if (!(currentDate && date.trim() && selectedDays.length)) {
    return false;
  }
  currentDate = currentDate.set('date', +date);
  return selectedDays.some((day) => day.isSame(currentDate, 'date'));
}

const CalendarBody: React.FC<React.PropsWithChildren<ICalendarBodyProps>> = ({
  calendarMatrix,
  currentDate,
  selectedDates = [],
  blockedOutDates = [],
  onDateSelect,
  disablePastDates,
  disableUniformHeight,
  datesWithBookings = [],
}) => {
  return (
    <>
      <Flex x="start" y="start" wrap={false}>
        <ObjectKeysMap
          object={calendarMatrix}
          render={(day, dates) => (
            <div
              className="flex-none mt-2"
              style={{ width: `${(1 / 7) * 100}%` }}
            >
              <div
                className={classnames(
                  'flex-grow text-xs font-medium text-center mb-2',
                  { 'text-gray-500': day[0] === 'S' }
                )}
              >
                {day[0]}
              </div>
              <ArrayMap
                items={dates}
                render={(date) => {
                  const isDateSelected = isHighlightedDay(
                    currentDate,
                    date,
                    selectedDates
                  );

                  const isDateBlockedOut = isHighlightedDay(
                    currentDate,
                    date,
                    blockedOutDates
                  );

                  const hasBookingsOnTheDay = isHighlightedDay(
                    currentDate,
                    date,
                    datesWithBookings
                  );

                  if (date === 'filler') {
                    return (
                      (!disableUniformHeight && <Box bg="transparent" />) ||
                      null
                    );
                  }
                  return (
                    <Box
                      bg="transparent"
                      padding="p-0"
                      center
                      id={date}
                      onClick={
                        (!(disablePastDates && isPast(currentDate, date)) &&
                          onDateSelect) ||
                        undefined
                      }
                      round="none"
                      className={classnames(
                        'flex-none cursor-pointer border-b',
                        {
                          'md:hover:bg-gray-100 md:hover:text-gray-700':
                            !isDateSelected,
                          'text-gray-300 pointer-events-none':
                            !date ||
                            (disablePastDates && isPast(currentDate, date)),
                        }
                      )}
                    >
                      <Box bg="transparent" padding="p-1">
                        {hasBookingsOnTheDay && (
                          <div className="absolute top-0 left-0 flex w-full h-full p-2">
                            <div className="w-2 h-2 ml-auto bg-green-500 rounded-full" />
                          </div>
                        )}
                        <span
                          className={classnames(
                            'w-full h-full flex items-center justify-center',
                            {
                              'text-primary font-medium':
                                isToday(currentDate, date) &&
                                !isDateSelected &&
                                !isDateBlockedOut,
                              'bg-primary text-white rounded-full':
                                isDateSelected,
                              'text-gray-400 line-through': isDateBlockedOut,
                            }
                          )}
                        >
                          {(date !== 'filler' && date) || ''}
                        </span>
                      </Box>
                    </Box>
                  );
                }}
              />
            </div>
          )}
        />
      </Flex>
    </>
  );
};
export default CalendarBody;
