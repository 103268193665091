import { combineReducers } from 'redux';
import { IAppStore } from '../types/appStore';
import { loaderReducer } from './loaderReducer';
import { userReducer } from './userReducer';
import { storesReducer } from './storesReducer';
import { categoriesReducer } from './categoriesReducer';
import { providerReducer } from './providerReducer';
import { LOGOUT } from '../actions/types';
import { signupCategoryReducer } from './signupCategoryReducer';
import { requestsReducer } from './requestsReducer';
import { quoteDataReducer } from './quoteDataReducer';
import { providerProfilesReducer } from './providerProfilesReducer';

const appReducer = combineReducers<IAppStore>({
  loader: loaderReducer,
  user: userReducer,
  stores: storesReducer,
  categories: categoriesReducer,
  provider: providerReducer,
  signupCategory: signupCategoryReducer,
  requests: requestsReducer,
  quoteData: quoteDataReducer,
  providerProfiles: providerProfilesReducer,
});

const rootReducer = (state, action) => {
  if (action.type === LOGOUT) {
    return appReducer(
      {
        loader: { showing: false },
        provider: { providerCategoryCount: 0 },
        stores: [],
        categories: [],
        user: {
          id: '',
          profileId: '',
          name: '',
          mobile: '',
          email: '',
        },
        signupCategory: null,
        requests: {
          pendingRequests: [],
          pendingRequestsCount: 0,
        },
        quoteData: {
          quote: undefined,
          filterBy: '',
          offers: { mobile: [], studio: [] },
          profiles: { mobile: [], studio: [] },
          bookedOffer: undefined,
        },
        providerProfiles: {},
      },
      { type: undefined }
    );
  }

  return appReducer(state, action);
};

export default rootReducer;
