import React from 'react';
import classnames from 'classnames';
import { ShowIf } from '../ShowIf/ShowIf';
import { Flex } from '../Flex/Flex';
import { Button } from '../Button/Button';

export interface IModalProps {
  open?: boolean;
  onClose?: (e?: any) => void;
  title?: React.ReactNode;
  titleContainerClassName?: string;
  size?: 'xs' | 'sm' | 'md' | 'lg';
  id?: string;
  footer?: boolean;
  okLabel?: string;
  cancelLabel?: string;
  hideCloseBtn?: boolean;
  onCancel?: (e?: any) => void;
  onOk?: (e?: any) => void;
  okBtnProps?: any;
  cancelBtnProps?: any;
  disableScrollLock?: boolean;
  removeWhenClosed?: boolean;
  fullScreen?: boolean | { sm: boolean; md: boolean };
}

const Modal: React.FC<React.PropsWithChildren<IModalProps>> = ({
  open,
  onClose,
  children,
  title,
  size = 'md',
  id,
  footer,
  okLabel = 'Ok',
  cancelLabel = 'Close',
  onCancel,
  onOk,
  okBtnProps = {},
  cancelBtnProps = {},
  hideCloseBtn,
  disableScrollLock,
  fullScreen,
  removeWhenClosed,
  titleContainerClassName,
}) => {
  React.useEffect(() => {
    if (!disableScrollLock && open) {
      setTimeout(() => {
        let html = document?.querySelector('html');
        html?.classList.add('overflow-hidden');
      }, 0);
    }
    return () => {
      if (!disableScrollLock) {
        let html = document?.querySelector('html');
        if (html?.classList.contains('overflow-hidden')) {
          html.classList.remove('overflow-hidden');
        }
      }
    };
  }, [open]);

  function handleCancel() {
    if (onCancel) {
      return onCancel?.();
    }
    onClose?.();
  }

  if (removeWhenClosed && !open) {
    return null;
  }

  return (
    <div
      className={classnames(
        'fixed top-0 bottom-0 left-0 right-0 z-30 flex items-center content-center',
        { hidden: !open, invisible: !open }
      )}
    >
      <div
        className="fixed top-0 bottom-0 left-0 right-0 z-30 bg-gray-900 opacity-75"
        onClick={onClose}
      />

      <div
        className={classnames(
          'mx-auto relative z-40 bg-white  shadow-lg p-4 w-full flex flex-col',
          {
            'max-w-11/12-screen max-h-11/12-screen rounded-2xl': !fullScreen,
            'md:max-w-11/12-screen md:max-h-11/12-screen': !(fullScreen as any)
              ?.md,
            [`md:w-3/12 lg:w-2/12`]: size === 'xs' && !(fullScreen as any)?.md,
            [`md:w-4/12 lg:w-3/12`]: size === 'sm' && !(fullScreen as any)?.md,
            [`md:w-1/2`]: size === 'md' && !(fullScreen as any)?.md,
            [`md:w-8/12 lg:w-7/12`]: size === 'lg' && !(fullScreen as any)?.md,
            'w-full h-screen':
              fullScreen === true || (fullScreen as any)?.sm === true,
            'md:h-auto md:rounded-2xl': (fullScreen as any)?.md === false,
          }
        )}
        id={id}
      >
        <div
          className={classnames(
            'flex justify-between mb-3',
            titleContainerClassName
          )}
        >
          <div>
            <ShowIf condition={typeof title === 'string'} elseTemplate={title}>
              <h2 className="text-xl font-medium">{title}</h2>
            </ShowIf>
          </div>

          {!hideCloseBtn && (
            <button
              className="flex items-center justify-center flex-none w-6 h-6 p-1 transform translate-x-3 -translate-y-3 bg-gray-200 rounded-full"
              onClick={onClose}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width=".7em"
                x="0"
                y="0"
                version="1.1"
                viewBox="0 0 122.878 122.88"
                xmlSpace="preserve"
                fill="currentColor"
              >
                <path d="M1.426 8.313a4.87 4.87 0 016.886-6.886l53.127 53.127 53.127-53.127a4.87 4.87 0 116.887 6.886L68.324 61.439l53.128 53.128a4.87 4.87 0 01-6.887 6.886L61.438 68.326 8.312 121.453a4.868 4.868 0 11-6.886-6.886l53.127-53.128L1.426 8.313z"></path>
              </svg>
            </button>
          )}
        </div>

        <div className="flex-grow overflow-y-auto">{children}</div>

        {footer && (
          <Flex x="end" y="center" className="mt-4">
            <Button
              onClick={handleCancel}
              size="md"
              {...({
                ...{ className: 'min-w-20' },
                ...cancelBtnProps,
              } as any)}
            >
              {cancelLabel}
            </Button>

            <Button
              onClick={onOk}
              size="md"
              {...({
                ...{ color: 'dark', className: 'ml-4 min-w-20' },
                ...okBtnProps,
              } as any)}
            >
              {okLabel}
            </Button>
          </Flex>
        )}
      </div>
    </div>
  );
};

export { Modal };
