import React from 'react';
interface IArrayMapProps {
  items?: any[];
  render?: (item, index) => React.ReactNode;
  keyField?: string;
  children?: (item, index) => React.ReactNode;
}

const ArrayMap: React.FC<IArrayMapProps> = ({
  items = [],
  render,
  keyField = '',
  children,
}) => {
  if (!(items && Array.isArray(items))) {
    return null;
  }

  return (
    <>
      {items.map((itm, key) => (
        <React.Fragment key={itm[keyField] || key}>
          <>
            {render?.(itm, key)}
            {(typeof children === 'function' && children(itm, key)) || children}
          </>
        </React.Fragment>
      ))}
    </>
  );
};
export default ArrayMap;
