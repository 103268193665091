import React from 'react';
import classnames from 'classnames';

interface IOption {
  label: string;
  value: string;
}

interface IValidation {
  rule: string;
  message: string;
}
interface SelectProps {
  name?: string;
  value?: string;
  label?: string;
  hideLabel?: boolean;
  labelClassName?: string;
  id?: string;
  options?: string[] | IOption[];
  validation?: Array<IValidation | string>;
  placeholder?: string;
  size?: string;
  className?: string;
  selectClassName?: string;
  leftIcon?: React.ReactNode;
  onChange?: (e: any) => void;
  onBlur?: (e?: any) => void;
}

const inputSizePaddingMapping = {
  sm: '',
  md: 'px-3 py-1',
  lg: 'py-2 px-4',
};

const inputSizeClassMapping = {
  sm: inputSizePaddingMapping.sm,
  md: inputSizePaddingMapping.md,
  lg: `${inputSizePaddingMapping.lg} text-lg`,
};
const Select = React.forwardRef<HTMLSelectElement, SelectProps>(
  (
    {
      name,
      value,
      options = [],
      onChange,
      placeholder = 'select one',
      label,
      hideLabel,
      id,
      size = 'md',
      labelClassName,
      validation,
      className = '',
      leftIcon,
      selectClassName,
      onBlur,
    },
    ref
  ) => {
    const [errMsg, setErrMsg] = React.useState('');

    function clearErrMsg() {
      setErrMsg('');
    }
    function handleBlur(e: any) {
      onBlur && onBlur(e);
      runValidation(e);
    }

    function handleChange(e: any) {
      onChange && onChange(e);
      runValidation(e);
    }

    function runValidation(e: any) {
      let valid = true;
      let errMsg = '';
      validation &&
        validation.map((v: any) => {
          const rule = typeof v === 'string' ? v : v.rule;
          switch (rule) {
            case 'required':
              errMsg =
                typeof v === 'string' ? `${name} is required` : v.message;
              if (!e.target.value) {
                return (valid = false);
              }
              break;
            default:
              valid = v.validator(e.target.value);
              errMsg = v.message;
              break;
          }
          return v;
        });

      if (valid) {
        clearErrMsg();
      } else {
        setErrMsg(errMsg);
      }
    }

    const inputClassName = classnames(
      selectClassName,
      inputSizeClassMapping[size],
      `w-full block bg-gray-100 pr-8 rounded appearance-none`,
      'text-gray-700',
      'focus:outline-none focus:bg-white focus:bg-gray-50 border focus:border-gray-500',
      {
        'border-gray-200': !errMsg,
        'border-red-500': errMsg,
      }
    );

    return (
      <div className={`mb-3 ${className}`}>
        <div>
          {!hideLabel && !!label && (
            <label className={labelClassName} htmlFor={id}>
              {label}
            </label>
          )}
          <div className="relative">
            {!!leftIcon && (
              <div
                className={classnames(
                  'absolute flex items-center content-center h-full pointer-events-none',
                  inputSizeClassMapping[size]
                )}
              >
                {leftIcon}
              </div>
            )}
            <select
              name={name}
              id={name}
              value={value}
              className={inputClassName}
              onChange={handleChange}
              onBlur={handleBlur}
              ref={ref}
            >
              <option value="">{placeholder}</option>
              {!!options.length && (
                <>
                  {['string', 'number'].includes(typeof options[0]) &&
                    (options as string[]).map((opt) => (
                      <option value={opt} key={opt}>
                        {opt}
                      </option>
                    ))}
                  {typeof options[0] === 'object' &&
                    (options as IOption[]).map((opt) => (
                      <option value={opt.value} key={opt.value}>
                        {opt.label}
                      </option>
                    ))}
                </>
              )}
            </select>
            <div className="absolute inset-y-0 right-0 flex items-center px-2 text-gray-700 pointer-events-none">
              <svg
                className="w-4 h-4 fill-current"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 20 20"
              >
                <path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z" />
              </svg>
            </div>
          </div>
        </div>
        {!!errMsg && (
          <span className="inline-block px-3 text-xs text-red-400">
            {errMsg}
          </span>
        )}
      </div>
    );
  }
);

export { Select };
