import { IOffer } from '../types/quote/offer';
import {
  APPEND_REQUEST_TO_QUOTE_DATA,
  RESET_QUOTE_DATA,
  SET_QUOTE_DATA,
  SET_QUOTE_DATA_FILTER_BY,
} from './types';

export function setQuoteDataAction(payload: any) {
  return {
    type: SET_QUOTE_DATA,
    payload,
  };
}

export function setQuoteDataFilterByAction(payload: string) {
  return {
    type: SET_QUOTE_DATA_FILTER_BY,
    payload,
  };
}

export function resetQuoteDataAction() {
  return {
    type: RESET_QUOTE_DATA,
  };
}

export function appendRequestToQuoteDataAction(payload: IOffer) {
  return {
    type: APPEND_REQUEST_TO_QUOTE_DATA,
    payload,
  };
}
