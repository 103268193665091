import {IStore} from '../types/store';
import { SET_STORES } from '../actions/types';
const initialState: IStore[] = [];

export function storesReducer(state = initialState, {type, payload}) {
  switch(type) {
    case SET_STORES:
      return payload; 
    default:
      return state;
  }
}