import classNames from 'classnames';
import React from 'react';
import ArrayMap from '../../lib/ArrayMap/ArrayMap';

interface ISwipeTransitionProps {
  direction?: 'x' | 'y';
  activeStepIndex?: number;
  children?: React.ReactNode[] | React.ReactNode;
  duration?: '300' | '500';
  className?: string;
  collapseHeightWhenInactive?: boolean;
  onTransitionComplete?: (e: any) => void;
}

const SwipeTransition: React.FC<React.PropsWithChildren<React.PropsWithChildren<ISwipeTransitionProps>>> = ({
  direction = 'x',
  activeStepIndex = 0,
  children,
  duration = '500',
  className,
  onTransitionComplete,
  collapseHeightWhenInactive,
}) => {
  React.useEffect(() => {
    if (activeStepIndex) {
      const timeoutDuration = +duration + 100;
      const timeout = setTimeout(() => {
        onTransitionComplete?.(activeStepIndex);
      }, timeoutDuration);
      return () => {
        clearTimeout(timeout);
      };
    }
  }, [duration, activeStepIndex]);

  return (
    <div
      className={classNames('w-full flex', className, {
        'flex-col': direction === 'y',
      })}
      data-testid="swipe-transition-container"
    >
      {Array.isArray(children) && (
        <ArrayMap items={children?.filter((c) => !!c)}>
          {(item, idx) => (
            <div
              className={classNames(
                'transition-transform transform flex-none w-full',
                {
                  'translate-none': activeStepIndex === idx,
                  'opacity-0 absolute left-0 w-0 h-0 overflow-hidden':
                    activeStepIndex !== idx,
                  'translate-y-full':
                    direction === 'y' && activeStepIndex < idx,
                  '-translate-y-full':
                    direction === 'y' && activeStepIndex > idx,
                  'translate-x-full':
                    direction === 'x' && activeStepIndex < idx,
                  '-translate-x-full':
                    direction === 'x' && activeStepIndex > idx,
                  'duration-500': duration === '500',
                  'duration-300': duration === '300',
                  'h-0': collapseHeightWhenInactive && activeStepIndex !== idx,
                }
              )}
            >
              {item}
            </div>
          )}
        </ArrayMap>
      )}
      {!Array.isArray(children) && <>{children}</>}
    </div>
  );
};
export default SwipeTransition;
