import React from 'react';
import { useCreateSearchContext } from '../../../../../contexts/createSearchContext';
import { ShowIf } from '../../../../../flayr-components/ShowIf/ShowIf';
import Calendar from '../../../../../lib/Calendar/Calendar';
import FormattedDate from '../../../../../lib/FormattedDate/FormattedDate';
import InputContainer from '../InputContainer/InputContainer';

interface IDateInputProps {
  open?: boolean;
  name?: string;
  placeholder?: string;
  popDownIdPrefix?: string;
  onOpen?: (e: any) => void;
}

const DateInput: React.FC<React.PropsWithChildren<IDateInputProps>> = ({
  placeholder = 'Set Date',
  name = 'date',
  open,
  onOpen,
  popDownIdPrefix,
}) => {
  const { userInput, setUserInput } = useCreateSearchContext();

  function handleDateSelect(eventDate) {
    setUserInput?.({ eventDate });
  }

  function handleOpen(e) {
    if (!open) {
      e.stopPropagation();
    }
    onOpen?.(name);
  }

  return (
    <InputContainer
      name={name}
      open={open}
      popDownItem={
        <div
          className="w-full aspect-square"
          onClick={(e) => e.stopPropagation()}
        >
          {open && (
            <Calendar
              selectedDates={
                (userInput?.eventDate && [userInput.eventDate]) || []
              }
              date={(userInput.eventDate && userInput.eventDate) || undefined}
              onDateSelect={handleDateSelect}
            />
          )}
        </div>
      }
      popDownIdPrefix={popDownIdPrefix}
    >
      <div className="w-full pl-2" onClick={handleOpen}>
        <ShowIf
          condition={userInput?.eventDate}
          elseTemplate={
            <span className="text-[#A3A3A3] leading-[21px]">{placeholder}</span>
          }
        >
          <FormattedDate date={userInput?.eventDate || undefined} readable />
        </ShowIf>
      </div>
    </InputContainer>
  );
};
export default DateInput;
