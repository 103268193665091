import {
  faChevronLeft,
  faChevronRight,
} from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import classnames from 'classnames';
import React from 'react';
import { useSwipeable } from 'react-swipeable';
import { Button } from '../Button/Button';
import { Flex } from '../Flex/Flex';
import { Img } from '../Img/Img';
import { ShowIf } from '../ShowIf/ShowIf';

interface CarouselProps {
  items?: React.ReactNode[];
  dotsColor?: string;
  arrows?: boolean;
  className?: string;
  dots?: boolean;
  squareImg?: boolean;
  overlapArrowOnContent?: boolean;
  arrowClassName?: string;
  showItemsCount?: boolean;
  leftIcon?: React.ReactNode;
  rightIcon?: React.ReactNode;
  infinite?: boolean;
  totalImages?: number;
  responsiveImage?: boolean;
  screenSizes?: any;
  customArrows?: (e: {
    prev: any;
    next: any;
    first?: boolean;
    last?: boolean;
  }) => React.ReactChild;
  onNext?: (e?: any) => void;
  onPrev?: (e?: any) => void;
}

const Swipeable = ({ children, ...props }) => {
  const handlers = useSwipeable(props);
  return <div {...handlers}>{children}</div>;
};
export const Carousel: React.FC<React.PropsWithChildren<CarouselProps>> = ({
  items = [],
  dotsColor = 'white',
  arrows,
  className = '',
  dots,
  squareImg,
  overlapArrowOnContent,
  arrowClassName = 'text-3xl',
  showItemsCount,
  leftIcon = faChevronLeft,
  rightIcon = faChevronRight,
  infinite,
  totalImages,
  responsiveImage,
  screenSizes,
  onNext,
  onPrev,
  customArrows,
}) => {
  const [activeIdx, setActiveIndex] = React.useState<number>(0);

  function next() {
    if (activeIdx < items.length - 1) {
      const idx = activeIdx + 1;
      setActiveIndex(idx);
      onNext?.(idx);
    }
  }

  function prev() {
    if (activeIdx > 0) {
      const idx = activeIdx - 1;
      setActiveIndex(idx);
      onPrev?.(idx);
    }
  }

  function handleDotClick(e) {
    const { index } = e.target.dataset;
    setActiveIndex(+index);
  }

  return (
    <div className={className}>
      <Swipeable onSwipedRight={prev} onSwipedLeft={next}>
        <Flex y="center" x="between" className="w-full">
          {arrows && (
            <Flex center className="relative z-10 w-16">
              <Button
                clear
                onClick={prev}
                hidden={!infinite && activeIdx === 0}
              >
                <Arrow
                  icon={leftIcon}
                  className={classnames(arrowClassName, {
                    'text-white': overlapArrowOnContent,
                  })}
                />
              </Button>
            </Flex>
          )}
          <div
            className={classnames('w-full overflow-x-hidden relative', {
              '-mx-16': overlapArrowOnContent,
            })}
          >
            <Flex
              wrap={false}
              className="w-full"
              style={{
                transform: `translate3d(-${activeIdx * 100}%, 0px, 0px)`,
                transition: 'transform 0.3s ease-in-out',
                willChange: 'transform',
              }}
            >
              {items?.map((item, idx) => (
                <div className="flex-none w-full" key={idx}>
                  <ShowIf
                    condition={typeof item === 'string'}
                    elseTemplate={item}
                  >
                    <Img
                      src={item as string}
                      placeholder={
                        (!squareImg &&
                          'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAIAAAABCAYAAAD0In+KAAAAD0lEQVR42mMsLS2vZwACAAwyAeJOAM75AAAAAElFTkSuQmCC') ||
                        undefined
                      }
                      position="top"
                      size={(squareImg && 'square') || undefined}
                      responsive={responsiveImage}
                      screenSizes={screenSizes}
                    />
                  </ShowIf>
                </div>
              ))}
            </Flex>
            <ShowIf condition={showItemsCount}>
              <div className="absolute bottom-0 right-0 py-3 pr-6 text-lg text-white text-shadow-md">
                {activeIdx + 1} of {totalImages || items.length}
              </div>
            </ShowIf>
          </div>
          {arrows && (
            <Flex center className="relative z-10 w-16">
              <Button
                clear
                onClick={next}
                hidden={!infinite && activeIdx === items.length - 1}
              >
                <Arrow
                  icon={rightIcon}
                  className={classnames(arrowClassName, {
                    'text-white': overlapArrowOnContent,
                  })}
                />
              </Button>
            </Flex>
          )}
          {!!customArrows &&
            customArrows({
              prev,
              next,
              first: activeIdx === 0,
              last: activeIdx === items.length - 1,
            })}
        </Flex>
      </Swipeable>
      {/* dots */}
      {dots && (
        <Flex className="mt-4" x="center" y="center">
          {items.length > 1 &&
            items.map((_itm, idx) => (
              <div
                key={idx}
                data-index={idx}
                className={classnames(
                  'm-1 w-3 h-3  md:m-3 md:w-4 md:h-4 rounded-full border cursor-pointer',
                  `border-${dotsColor}`,
                  { [`bg-${dotsColor}`]: idx === activeIdx }
                )}
                onClick={handleDotClick}
              />
            ))}
        </Flex>
      )}
    </div>
  );
};

const Arrow: React.FC<React.PropsWithChildren<{ className?: string; icon: any }>> = ({
  className,
  icon,
}) => {
  return (
    <span className={className}>
      <FontAwesomeIcon icon={icon} />
    </span>
  );
};
