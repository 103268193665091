import React from 'react';
import { IFontSize } from '../types/fontSize';
import classnames from 'classnames';

export interface ILabelProps extends IFontSize {
  text?: string;
  color?: 'primary' | 'success' | 'info' | 'danger';
  className?: string;
}

const classMapping = {
  primary: 'bg-primary-1 text-white',
  secondary: 'bg-secondary-1 text-white',
  success: 'bg-green-500 text-white',
  info: 'bg-orange-500 text-white',
  danger: 'bg-red-500 text-white',
  default: 'bg-gray-100',
};

export const Label: React.FC<React.PropsWithChildren<ILabelProps>> = ({
  text,
  color = 'default',
  className = '',
  size = 'md',
}) => {
  return (
    <span
      className={classnames(
        'inline-block px-3 rounded-md',
        classMapping[color],
        className,
        `text-${size}`
      )}
    >
      {text}
    </span>
  );
};
