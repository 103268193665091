import React from 'react';
import { INavLink } from '../lib/Sidebar/Sidebar';

type completionStepName =
  | 'userDetails'
  | 'location'
  | 'service'
  | 'pricing'
  | 'travel'
  | 'minJobCost'
  | 'instagram'
  | 'kit'
  | 'photo'
  | 'selfie';

interface ISignupProgressContext {
  links: INavLink[];
  setLinks?: (e?: any) => void;
  completeSteps?: (id?: completionStepName[]) => void;
}

const SignupProgressContext = React.createContext<ISignupProgressContext>({
  links: [],
});

export default SignupProgressContext;
