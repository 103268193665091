import {
  faChevronLeft,
  faChevronRight,
} from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import { Flex } from '../../flayr-components/Flex/Flex';

interface ICalendarNextPrevBtnsProps {
  onNext?: (e?: any) => void;
  onPrev?: (e?: any) => void;
  title?: string;
  className?: string;
}

const CalendarNextPrevBtns: React.FC<React.PropsWithChildren<ICalendarNextPrevBtnsProps>> = ({
  onNext,
  onPrev,
  title,
  className,
}) => {
  return (
    <Flex x="center" className={className}>
      <button
        className="w-10 px-2 py-1 -ml-1 focus:outline-none text-primary"
        onClick={onPrev}
      >
        <span>
          <FontAwesomeIcon icon={faChevronLeft} />
        </span>
      </button>
      <div className="w-6/12 px-1 text-lg text-center">{title}</div>
      <button
        className="w-10 px-2 py-1 focus:outline-none text-primary"
        onClick={onNext}
      >
        <span>
          <FontAwesomeIcon icon={faChevronRight} />
        </span>
      </button>
    </Flex>
  );
};
export default CalendarNextPrevBtns;
