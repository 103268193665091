import { ADD_CATEGORY, SET_CATEGORIES } from '../actions/types';
import { ICategory } from '../types/category';

const initialState: ICategory[] = [];

export function categoriesReducer(state = initialState, { type, payload }) {
  switch (type) {
    case ADD_CATEGORY:
      return [...state, payload];
    case SET_CATEGORIES:
      return payload;
    default:
      return state;
  }
}
