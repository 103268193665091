import React from 'react';
import dayjs, { Dayjs } from 'dayjs';
import { getCalendarMatrix } from '../../utils/dateUtils';
import CalendarNavBar from '../CalendarNavBar/CalendarNavBar';
import CalendarBody from '../CalendarBody/CalendarBody';

interface ICalendarProps {
  date?: string;
  blockedOutDates?: any[];
  datesWithBookings?: any[];
  selectedDates?: string[];
  className?: string;
  disablePastDate?: boolean;
  enablePastDates?: boolean;
  disableUniformHeight?: boolean;
  dateFormatFn?: (e: Dayjs) => string;
  onDateSelect?: (e?: any) => void;
  onCurrentDateChange?: (e?: any) => void;
}

export interface ICalendarMatrix {
  Su: string[];
  Mo: string[];
  Tu: string[];
  We: string[];
  Th: string[];
  Fr: string[];
  Sa: string[];
}

const Calendar: React.FC<React.PropsWithChildren<ICalendarProps>> = ({
  date,
  selectedDates = [],
  blockedOutDates = [],
  datesWithBookings = [],
  className,
  onDateSelect,
  enablePastDates,
  disableUniformHeight,
  dateFormatFn = (d) => d.format('YYYY-MM-DD'),
  onCurrentDateChange,
}) => {
  const [currentDate, setCurrentDate] = React.useState<Dayjs>();

  const [calendarMatrix, setCalendarMatrix] = React.useState<ICalendarMatrix>();
  const [currentDateSplit, setCurrentDateSplit] = React.useState<{
    month: string;
    year: string;
  }>();

  const [selectedDatesConvertedToDayJs, setSelectedDatesConvertedToDayJs] =
    React.useState<Dayjs[]>([]);
  const [blokedOutDatesConvertedToDayJs, setBlokedOutDatesConvertedToDayJs] =
    React.useState<Dayjs[]>([]);
  const [datesWithBookingsConvertedToDayjs, setDatesWithBookingsToDayJs] =
    React.useState<Dayjs[]>([]);

  React.useEffect(() => {
    let currentDate = dayjs();
    if (date) {
      currentDate = dayjs(date);
    }
    setCurrentDate(currentDate);
  }, [date]);

  React.useEffect(() => {
    if (currentDate) {
      const calendarMatrix = getCalendarMatrix(currentDate);
      setCalendarMatrix(calendarMatrix);
      const curMonth = currentDate.format('MMMM');
      const curYear = currentDate.format('YYYY');
      setCurrentDateSplit({ month: curMonth, year: curYear });

      if (!currentDate.isSame(dayjs(date), 'day')) {
        onCurrentDateChange?.(currentDate.format('YYYY-MM-DD'));
      }
    }
  }, [currentDate]);

  React.useEffect(() => {
    if (selectedDates.length) {
      setSelectedDatesConvertedToDayJs(
        selectedDates.map((date) => dayjs(date))
      );
    }
  }, [selectedDates]);

  React.useEffect(() => {
    if (blockedOutDates.length) {
      setBlokedOutDatesConvertedToDayJs(
        blockedOutDates.map((date) => dayjs(date))
      );
    }
  }, [blockedOutDates]);

  React.useEffect(() => {
    if (datesWithBookings.length) {
      setDatesWithBookingsToDayJs(datesWithBookings.map((date) => dayjs(date)));
    }
  }, [datesWithBookings]);

  function handleNextMonthSelect(e) {
    e.stopPropagation();
    const newDate = currentDate?.add(1, 'month');
    setCurrentDate(newDate);
  }

  function handlePrevMonthSelect(e) {
    e.stopPropagation();
    const newDate = currentDate?.subtract(1, 'month');
    setCurrentDate(newDate);
  }

  function handleNextYearSelect() {
    const newDate = currentDate?.add(1, 'year');
    setCurrentDate(newDate);
  }

  function handlePrevYearSelect() {
    const newDate = currentDate?.subtract(1, 'year');
    setCurrentDate(newDate);
  }

  function handleDateSelect(_e, date?: string) {
    const dateToFormat = (currentDate as Dayjs)?.set?.('date', +(date || ''));
    onDateSelect?.(dateFormatFn(dateToFormat));
  }

  if (!calendarMatrix) {
    return null;
  }
  return (
    <div className={className}>
      <section className="mb-2">
        <CalendarNavBar
          {...(currentDateSplit || {})}
          onNextMonth={handleNextMonthSelect}
          onPrevMonth={handlePrevMonthSelect}
          onNextYear={handleNextYearSelect}
          onPrevYear={handlePrevYearSelect}
        />
      </section>
      <CalendarBody
        calendarMatrix={calendarMatrix}
        currentDate={currentDate || dayjs()}
        selectedDates={selectedDatesConvertedToDayJs}
        blockedOutDates={blokedOutDatesConvertedToDayJs}
        onDateSelect={handleDateSelect}
        disablePastDates={!enablePastDates}
        disableUniformHeight={disableUniformHeight}
        datesWithBookings={datesWithBookingsConvertedToDayjs}
      />
    </div>
  );
};
export default Calendar;
