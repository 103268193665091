import { faTimes } from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import classnames from 'classnames';
import React from 'react';
import { Button } from '../Button/Button';
import { ShowIf } from '../ShowIf/ShowIf';
import { IRoundedCorners } from '../types/roundedCorners';
import { getFontAwesomeIconArray } from '../utils';
import './Input.css';

function isEmailValid(email: string) {
  const re =
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return re.test(String(email).toLowerCase());
}

interface IValidation {
  message?: string;
  rule?: string;
  validator?: (e: any) => boolean;
}

interface InputProps extends IRoundedCorners {
  // container
  className?: string;
  /**
   * bottom margin
   *
   * */
  mb?: string;
  onClick?: (e?: any) => void;

  // addons
  leftIcon?: React.ReactNode;
  rightIcon?: React.ReactNode;
  clearable?: boolean;
  clearIcon?: React.ReactNode;

  // input

  // html5 input types + custom
  type?:
    | 'button'
    | 'checkbox'
    | 'color'
    | 'date'
    | 'datetime-local'
    | 'email'
    | 'file'
    | 'hidden'
    | 'image'
    | 'month'
    | 'number'
    | 'password'
    | 'radio'
    | 'range'
    | 'reset'
    | 'search'
    | 'submit'
    | 'tel'
    | 'text'
    | 'time'
    | 'url'
    | 'week'
    // custom types
    | 'places'
    | 'textarea';
  name?: string;
  placeholder?: string;
  value?: string | number;
  id?: string;
  inputClassName?: string;
  size?: string;
  validation?: Array<IValidation | string>;
  inputStyle?: React.CSSProperties;
  tabIndex?: number;
  disabled?: boolean;
  autoFocus?: boolean;
  readOnly?: boolean;
  autoCapitalize?:
    | 'off' // no capitalize
    | 'none' // no capitalize
    | 'on' // first letter in a sentence
    | 'sentences' // first letter in a sentence
    | 'words' // first letter of each word
    | 'characters'; // all chars, i.e. uppercase the whole thing
  hideSpinner?: boolean;
  runValidateOnEvents?: 'all' | 'onChange' | 'onBlur' | 'onValueChange';
  inputBg?: string;
  inputBgOnFocus?: string;
  resetInputClassName?: boolean;
  formatter?: (e?: any) => void;

  // input events
  onSelect?: (e?: any) => void;
  onClear?: (e?: any) => void;
  onChange?: (e?: any) => void;
  onInputClick?: (e?: any) => void;
  onBlur?: (e?: any) => void;
  onKeyUp?: (e?: any) => void;
  onKeyDown?: (e?: any) => void;

  // label
  label?: string;
  labelClassName?: string;

  // textarea
  /**
   * **Note** only applicable on textarea
   *
   * */
  rows?: number;
  /**
   * **Note** only applicable on textarea
   *
   * */
  autoExpandHeight?: boolean;
}

const inputSizePaddingMapping = {
  sm: '',
  md: 'px-3 py-1',
  lg: 'py-2 px-4',
};

const inputSizeClassMapping = {
  sm: inputSizePaddingMapping.sm,
  md: inputSizePaddingMapping.md,
  lg: `${inputSizePaddingMapping.lg} text-lg`,
};

const Input = React.forwardRef<any, InputProps>(
  (
    {
      className = '',
      mb = '3',
      onClick,

      clearable,
      leftIcon,
      rightIcon,
      clearIcon = <FontAwesomeIcon icon={faTimes} />,

      type = 'text',
      name,
      value,
      placeholder,
      inputClassName = '',
      inputStyle,
      id,
      label,
      labelClassName = '',
      size = 'md',
      validation = [],
      tabIndex,
      disabled,
      autoFocus,
      autoCapitalize,
      hideSpinner,
      readOnly,
      formatter,
      rounded = true,
      inputBg = 'gray-100',
      inputBgOnFocus = 'white',
      runValidateOnEvents = 'all',
      resetInputClassName,

      onClear,
      onChange,
      onInputClick,
      onBlur,
      onKeyUp,
      onKeyDown,

      rows = 3,
      autoExpandHeight,
    },
    ref
  ) => {
    const [errMsg, setErrMsg] = React.useState('');

    React.useEffect(() => {
      if (runValidateOnEvents === 'onValueChange') {
        runValidation({ target: { value } }, 'onValueChange');
      }
    }, [runValidateOnEvents, value]);

    function clearErrMsg() {
      setErrMsg('');
    }

    function handleBlur(e: any) {
      runValidation(e, 'onBlur');
      onBlur?.(e);
    }

    function handleChange(e: any) {
      runValidation(e, 'onChange');
      let value = e.target.value;
      if (formatter) {
        value = formatter(e.target.value);
        e.target.value = value;
      }
      onChange?.(e);
    }

    function runValidation(e: any, event: string) {
      let valid = true;
      let errMsg = '';
      const invalidItems: string[] = [];
      validation &&
        validation.map((v: any) => {
          const rule = typeof v === 'string' ? v : v.rule;
          if (invalidItems.length) {
            return;
          }
          switch (rule) {
            case 'required':
              const capName = name?.[0].toUpperCase() + (name || '').slice(1);
              errMsg =
                typeof v === 'string' ? `${capName} is required` : v.message;
              valid = e.target.value?.trim();
              break;
            case 'email':
              valid = type === 'email' && isEmailValid(e.target.value);
              errMsg = 'Email address is not valid';
              break;
            // custom validation
            default:
              valid = v.validator(e.target.value);
              errMsg = v.message || `${name} is not valid`;
              break;
          }
          !valid && invalidItems.push('invalid');
          return v;
        });

      if (valid) {
        clearErrMsg();
      } else {
        ['all', event].includes(runValidateOnEvents) && setErrMsg(errMsg);
      }
    }

    function handleClear() {
      onClear?.({ name, value, id });
    }
    const calculatedInputClassName = React.useMemo(() => {
      if (resetInputClassName) {
        return inputClassName || '';
      }
      return classnames(
        `${inputClassName} w-full block text-base focus:outline-none border border-gray-200 focus:border-gray-500 ${inputSizeClassMapping[size]}`,
        `bg-${inputBg} focus:bg-${inputBgOnFocus}`,
        {
          'border-red-500': !!errMsg,
          'bg-gray-200': disabled,
          rounded: rounded === true,
          [`rounded-${rounded}`]: rounded && typeof rounded === 'string',
        }
      );
    }, [
      inputClassName,
      inputBg,
      inputBgOnFocus,
      errMsg,
      disabled,
      rounded,
      resetInputClassName,
    ]);

    return (
      <div
        className={classnames(`Input ${className} mb-${mb}`, {
          'Input-number-spinner-hidden': hideSpinner,
        })}
        onClick={onClick}
      >
        <label className={`${labelClassName} text-black`} htmlFor={id}>
          {label}
        </label>
        <div className="relative">
          {type !== 'places' && type !== 'textarea' && (
            <input
              ref={ref}
              id={id}
              placeholder={placeholder}
              className={calculatedInputClassName}
              type={type}
              name={name}
              value={value}
              autoComplete="off"
              tabIndex={tabIndex}
              style={inputStyle}
              disabled={disabled}
              autoFocus={autoFocus}
              autoCapitalize={autoCapitalize}
              readOnly={readOnly}
              onChange={handleChange}
              onBlur={handleBlur}
              onClick={onInputClick}
              onKeyUp={onKeyUp}
              onKeyDown={onKeyDown}
            />
          )}

          {type === 'textarea' && (
            <ShowIf
              condition={autoExpandHeight}
              elseTemplate={
                <textarea
                  ref={ref}
                  id={id}
                  placeholder={placeholder}
                  className={calculatedInputClassName}
                  name={name}
                  value={value}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  autoComplete="off"
                  onClick={onInputClick}
                  onKeyUp={onKeyUp}
                  onKeyDown={onKeyDown}
                  readOnly={readOnly}
                  tabIndex={tabIndex}
                  rows={rows}
                  autoFocus={autoFocus}
                  autoCapitalize={autoCapitalize}
                />
              }
            >
              <div className="relative">
                <pre
                  style={{
                    minHeight: `${rows}em `,
                    fontFamily: 'inherit',
                    whiteSpace: 'pre-wrap',
                    wordBreak: 'keep-all',
                  }}
                  className={classnames(
                    inputClassName,
                    inputSizeClassMapping[size],
                    'invisible'
                  )}
                >
                  {value}
                </pre>
                <textarea
                  ref={ref}
                  id={id}
                  placeholder={placeholder}
                  className={`${calculatedInputClassName} absolute top-0 left-0 h-full w-full overflow-hidden resize-none block`}
                  style={inputStyle}
                  disabled={disabled}
                  autoFocus={autoFocus}
                  autoCapitalize={autoCapitalize}
                  name={name}
                  value={value}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  readOnly={readOnly}
                  autoComplete="off"
                  onClick={onInputClick}
                  onKeyUp={onKeyUp}
                  onKeyDown={onKeyDown}
                  tabIndex={tabIndex}
                  rows={rows}
                />
              </div>
            </ShowIf>
          )}

          {!!leftIcon && (
            <div
              className={classnames(
                'absolute top-0 bottom-0 left-0 flex items-center',
                inputSizePaddingMapping[size]
              )}
            >
              <ShowIf
                condition={typeof leftIcon === 'string'}
                elseTemplate={leftIcon}
              >
                <FontAwesomeIcon
                  icon={getFontAwesomeIconArray(leftIcon as string)}
                />
              </ShowIf>
            </div>
          )}
          {!!rightIcon && !clearable && (
            <div
              className={classnames(
                'absolute top-0 bottom-0 right-0 flex items-center',
                inputSizePaddingMapping[size]
              )}
            >
              {rightIcon}
            </div>
          )}
          {!!value && clearable && (
            <div className="absolute top-0 bottom-0 right-0 flex items-center">
              <Button clear onClick={handleClear}>
                <span className="pointer-events-none">{clearIcon}</span>
              </Button>
            </div>
          )}
        </div>
        {!!errMsg && (
          <span className="inline-block px-3 text-xs text-red-400">
            {errMsg}
          </span>
        )}
      </div>
    );
  }
);
export { Input };
