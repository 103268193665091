import React from 'react';
import { useCreateSearchContext } from '../../../../../contexts/createSearchContext';
import { Flex } from '../../../../../flayr-components';
import { Button } from '../../../../../flayr-components/Button/Button';
import SingularOrPluralText from '../../../../../lib/SingularOrPluralText/SingularOrPluralText';
import InputContainer from '../InputContainer/InputContainer';
import SearchWizardServiceSelect from '../../../../../components/SearchWizardServiceSelect/SearchWizardServiceSelect';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUser } from '@fortawesome/pro-light-svg-icons';

interface IServicesInputProps {
  open?: boolean;
  name?: string;
  placeholder?: string;
  popDownIdPrefix?: string;
  onOpen?: (e: any) => void;
  onFinish?: (e?: any) => void;
}

const ServicesInput: React.FC<React.PropsWithChildren<IServicesInputProps>> = ({
  placeholder = 'Select services',
  name = 'services',
  popDownIdPrefix,
  open,
  onOpen,
  onFinish,
}) => {
  const { userInput, setUserInput, store, serviceTypes, fetchFormSettings } =
    useCreateSearchContext();

  React.useEffect(() => {
    if (open && !store?.id) {
      fetchFormSettings?.();
    }
  }, [open, fetchFormSettings, store]);

  function handleSelect(services) {
    setUserInput?.({ services });
  }

  function handleOpen(e) {
    if (!open) {
      e.stopPropagation();
    }
    onOpen?.(name);
  }

  const services = serviceTypes || [];

  const selectedServices = userInput?.services || [];

  const value = (!!userInput?.services?.length && (
    <span className="flex items-center">
      <FontAwesomeIcon
        icon={faUser}
        className="inline-block mr-2 text-sm leading-none"
      />{' '}
      x {userInput?.services?.length}
    </span>
  )) || <span className="text-[#A3A3A3] leading-[21px]">{placeholder}</span>;

  return (
    <InputContainer
      name={name}
      open={open}
      popDownIdPrefix={popDownIdPrefix}
      popDownItem={
        <div className="aspect-[2/1]" onClick={(e) => e.stopPropagation()}>
          {open && (
            <SearchWizardServiceSelect
              availableServices={services}
              services={selectedServices}
              onSelect={handleSelect}
              renderAddBtn={({ onAdd }) => (
                <Flex x="between" className="px-4 pt-5 border-t" wrap>
                  <span className="mb-4 font-medium">
                    {userInput.services?.length}{' '}
                    <SingularOrPluralText
                      number={userInput.services?.length || 0}
                      singular="person"
                      plural="people"
                    />
                  </span>

                  <Button
                    className="mb-4 uppercase"
                    color="hot-pink"
                    round
                    onClick={onAdd}
                  >
                    Add another person
                  </Button>
                </Flex>
              )}
            />
          )}
          <div className="my-4">
            <Button block size="lg" color="hot-pink" round onClick={onFinish}>
              Done
            </Button>
          </div>
        </div>
      }
      popDownPosition="right"
    >
      <div className="w-full pl-2" onClick={handleOpen}>
        <div onClick={handleOpen}>{value}</div>
      </div>
    </InputContainer>
  );
};
export default ServicesInput;
