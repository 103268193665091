import { faStar } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import classNames from 'classnames';
import React from 'react';

interface IReviewBadgeProps {
  score?: number | string;
  className?: string;
  fontSizeClassName?: string;
  starColorClassName?: string;
  starFontSizeClassName?: string;
  starSpacingClassName?: string;
  heightClassName?: string;
}

const ReviewBadge: React.FC<React.PropsWithChildren<IReviewBadgeProps>> = ({
  score,
  className,
  fontSizeClassName = 'text-sm',
  starColorClassName = 'text-review-star',
  starFontSizeClassName = 'text-xs',
  starSpacingClassName = 'space-x-1.5',
  heightClassName,
}) => {
  return (
    <span
      className={classNames(
        'inline-flex items-center px-3 leading-[1.5em] lg:space-x-2 font-semibold bg-gray-F8 rounded-full py-1',
        starSpacingClassName,
        fontSizeClassName,
        heightClassName,
        className
      )}
    >
      <span className="pt-px">{score}</span>
      <span className={`${starFontSizeClassName} ${starColorClassName}`}>
        <FontAwesomeIcon icon={faStar} />
      </span>
    </span>
  );
};
export default ReviewBadge;
