import React from 'react';

export default function useScrollLock(
  lock = false,
  targets = ['html'],
  timeout = 0
) {
  React.useEffect(() => {
    if (lock) {
      const els = targets.map((target) => document.querySelector(target));
      setTimeout(() => {
        els.forEach((el) => {
          el?.classList.add('overflow-y-hidden');
        });
      }, 0);
      return () => {
        els.forEach((el) => {
          el?.classList.remove('overflow-y-hidden');
        });
      };
    }
  }, [lock, timeout]);
}
