import { faChevronLeft } from '@fortawesome/pro-light-svg-icons';
import classNames from 'classnames';
import React from 'react';
import { Flex } from '../../flayr-components';
import { Button } from '../../flayr-components/Button/Button';
import { Heading } from '../../flayr-components/Heading/Heading';
import useScrollLock from '../../hooks/useScrollLock';
import IconBtn from '../../lib/IconBtn/IconBtn';
import Portal from '../../lib/Portal/Portal';
import FooterCta from '../FooterCta/FooterCta';

interface IFormWizardProps extends React.HTMLAttributes<HTMLDivElement> {
  open?: boolean;
  steps?: string[];
  activeStep?: string;
  titleClassName?: string;
  subTitleClassName?: string;
  title?: string;
  subTitle?: string;
  nextBtnLabel?: string;
  nextBtnDisabled?: boolean;
  className?: string;
  hideClearBtn?: boolean;
  hideBackBtn?: boolean;
  loading?: boolean;
  drawerView?: boolean;
  hideFooter?: boolean;
  nextBtnClassName?: string;
  clearBtnLabel?: string;
  scrollToTopOnNext?: boolean;
  scrollToTopOnClear?: boolean;
  footerCtaContainerProps?: any;
  onClear?: (e?: any) => void;
  onNext?: (e?: any) => void;
  onBack?: (e: any) => void;
  onClose?: (e?: any) => void;
}

const FormWizard: React.FC<
  React.PropsWithChildren<React.PropsWithChildren<IFormWizardProps>>
> = ({
  open,
  title,
  hideClearBtn,
  hideBackBtn,
  subTitle,
  children,
  nextBtnLabel = 'Next',
  nextBtnDisabled,
  subTitleClassName = '',
  titleClassName,
  className,
  loading,
  drawerView,
  clearBtnLabel = 'Clear',
  onClear,
  onNext,
  onClose,
  onBack,
  hideFooter,
  nextBtnClassName,
  scrollToTopOnNext,
  scrollToTopOnClear,
  footerCtaContainerProps,
  ...props
}) => {
  useScrollLock(open);
  const containerRef = React.useRef<HTMLDivElement>(null);

  function handleNext() {
    if (scrollToTopOnNext) {
      setTimeout(() => {
        if (containerRef.current) containerRef.current.scrollTo(0, 0);
      }, 0);
    }
    onNext?.();
  }

  function handleClear() {
    if (scrollToTopOnClear) {
      setTimeout(() => {
        if (containerRef.current) containerRef.current.scrollTo(0, 0);
      }, 0);
    }
    onClear?.();
  }

  if (!open) {
    return null;
  }

  const Title = (
    <Heading
      tag="h2"
      bold="medium"
      className={classNames(
        'mt-2 text-4xl text-white xs:mt-0 xs:text-2xl',
        titleClassName
      )}
    >
      {title}
    </Heading>
  );

  const SubTitle = (
    <Heading
      tag="h3"
      bold="medium"
      className={classNames(
        `text-3xl text-primary xs:text-xl`,
        subTitleClassName
      )}
    >
      {subTitle}
    </Heading>
  );
  return (
    <>
      <Portal className={className}>
        {!drawerView && (
          <div
            {...props}
            ref={containerRef}
            className="fixed inset-0 z-30 overflow-y-auto bg-white"
          >
            <section
              data-testid="wizard-header"
              className="sticky top-0 p-4 pb-8 bg-black"
            >
              <div className="w-full">
                {!hideBackBtn && (
                  <IconBtn
                    onClick={onBack}
                    color="plain"
                    icon={faChevronLeft}
                    round
                    className="pl-0 pr-0 w-7 h-7"
                  />
                )}
              </div>

              <section className="w-full pb-4 mt-8 xs:pb-1 xs:mt-4 lg:mx-auto lg:w-2/3">
                {SubTitle}
                {Title}
              </section>
            </section>

            <div className="relative z-10 w-full px-4 pt-10 pb-20 -mt-3 bg-white rounded-t-xl">
              {/* 
                we need overflow-x-hidden because it would cause weird scroll on safari on the 2nd step 

                but doing so would cause a problem with places input not showing menu because of its absolute positioned menu
                so we had to put min-h-6/12 screen to fix the error
              */}
              <div className="w-full overflow-x-hidden lg:mx-auto lg:w-2/3 min-h-6/12-screen">
                {children}
              </div>

              {!hideFooter && (
                <FooterCta
                  leftContent={
                    <Button
                      hidden={hideClearBtn}
                      onClick={handleClear}
                      className="-ml-4 underline"
                      clear
                    >
                      {clearBtnLabel}
                    </Button>
                  }
                  btnLabel={nextBtnLabel}
                  ctaClassName={classNames('pl-8 pr-8', nextBtnClassName)}
                  ctaDisabled={nextBtnDisabled}
                  ctaProps={{ loading }}
                  onCtaClick={handleNext}
                  containerProps={footerCtaContainerProps}
                />
              )}
            </div>
          </div>
        )}
      </Portal>
      {drawerView && (
        <div className={classNames('flex flex-col w-full h-full', className)}>
          <section className="flex p-4 pb-8 space-x-4">
            <div className="pt-2 pr-4">
              {!hideBackBtn && (
                <IconBtn
                  onClick={onBack}
                  color="plain"
                  icon={faChevronLeft}
                  round
                  className="pl-0 pr-0 w-7 h-7"
                />
              )}
            </div>

            <section>
              {SubTitle}

              {Title}
            </section>
          </section>

          <section className="flex flex-grow p-8">
            <div className="flex-none w-7"></div>
            <div className="flex-grow w-full">{children}</div>
          </section>

          {!hideFooter && (
            <Flex
              data-testid="search-wizard-footer-drawer"
              className="sticky bottom-0 left-0 z-10 w-full px-4 pb-8"
            >
              <Button hidden={hideClearBtn} onClick={onClear} clear>
                <span className="text-white underline">{clearBtnLabel}</span>
              </Button>

              <div className="w-1/2 ml-auto">
                <Button
                  block
                  shadow
                  color="hot-pink"
                  size="lg"
                  className={classNames(
                    'pl-10 pr-10 ml-auto',
                    nextBtnClassName
                  )}
                  disabled={nextBtnDisabled}
                  loading={loading}
                  onClick={onNext}
                >
                  {nextBtnLabel}
                </Button>
              </div>
            </Flex>
          )}
        </div>
      )}
    </>
  );
};
export default FormWizard;
