import Axios from 'axios';
import { generateId } from '.';

export function fileToDataURI(file: Blob): Promise<string> {
  return new Promise((resolve, reject) => {
    try {
      const fileReader = new FileReader();
      fileReader.onload = () => resolve(fileReader.result as string);
      fileReader.readAsDataURL(file);
    } catch (err) {
      console.log(err);
      reject(err);
    }
  });
}

export function dataURItoBlob(dataURI: string) {
  // convert base64 to raw binary data held in a string
  // doesn't handle URLEncoded DataURIs - see SO answer #6850276 for code that does this
  var byteString = atob(dataURI.split(',')[1]);

  // separate out the mime component
  var mimeString = dataURI.split(',')[0].split(':')[1].split(';')[0];

  // write the bytes of the string to an ArrayBuffer
  var ab = new ArrayBuffer(byteString.length);

  // create a view into the buffer
  var ia = new Uint8Array(ab);

  // set the bytes of the buffer to the correct values
  for (var i = 0; i < byteString.length; i++) {
    ia[i] = byteString.charCodeAt(i);
  }

  // write the ArrayBuffer to a blob, and you're done
  var blob = new Blob([ab], { type: mimeString });
  return blob;
}

export async function defaultFileUploader(
  file,
  id?: string,
  options: any = { convertToWebp: false }
) {
  const dataUri = await fileToDataURI(file);
  return await uploadDataUrlToS3(dataUri, id, options);
}

export async function uploadDataUrlToS3(
  dataUri,
  id?: string,
  options: any = { convertToWebp: false }
) {
  if (!id) {
    id = 'uploads';
  }
  const AWS_IMG_UPLOAD_URL =
    'https://yvc594rjsb.execute-api.ap-southeast-2.amazonaws.com/prod/upload';
  let fileName = `${id}/`.replaceAll('//', '/');
  fileName += generateId().replace(/\s|_{2,}/g, '_');
  const res = await Axios.post(AWS_IMG_UPLOAD_URL, {
    fileName,
    dataUri,
    ...options,
  });
  return res.data.fileUrl || '';
}
