import React from 'react';
import { useSwipeable } from 'react-swipeable';

interface ISwipeableProps {
  onSwipeLeft?: (e?: any) => void;
  onSwipeRight?: (e?: any) => void;
  className?: string;
}

const Swipeable: React.FC<React.PropsWithChildren<ISwipeableProps & any>> = ({
  children,
  className,
  ...props
}) => {
  const handlers = useSwipeable(props);
  return (
    <div className={className} {...handlers}>
      {children}
    </div>
  );
};
export default Swipeable;
