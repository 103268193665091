import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import classnames from 'classnames';
import React from 'react';
import { Flex } from '../Flex/Flex';
import { ShowIf } from '../ShowIf/ShowIf';
import { getFontAwesomeIconArray } from '../utils';

export interface IListItemProps {
  leftIcon?: React.ReactNode;
  title?: React.ReactNode;
  subTitle?: React.ReactNode;
  rightIcon?: React.ReactNode;
  className?: string;
  onClick?: (e?: any, id?: string | number) => void;
  id?: string | number;
  leftIconClassName?: string;
  rightIconClassName?: string;
  titleClassName?: string;
  subTitleClassName?: string;
  border?: boolean | 'full';
  mb?: string;
  pb?: string;
}

const ListItem: React.FC<React.PropsWithChildren<IListItemProps>> = ({
  leftIcon,
  title,
  subTitle,
  rightIcon = null,
  className = '',
  id,
  onClick,
  children,
  leftIconClassName = '',
  rightIconClassName = '',
  titleClassName = '',
  subTitleClassName = '',
  border,
  mb = '3',
  pb = '3',
}) => {
  function handleClick(e) {
    onClick?.(e, id);
  }

  return (
    <Flex
      className={classnames(className, `mb-${mb} pb-${pb}`, {
        'border-b border-gray-200': border === 'full',
        'cursor-pointer': !!onClick,
      })}
      y="center"
      onClick={handleClick}
    >
      <aside className={classnames('flex-none', leftIconClassName)}>
        <ShowIf
          condition={typeof leftIcon === 'string'}
          elseTemplate={leftIcon}
        >
          <FontAwesomeIcon icon={getFontAwesomeIconArray(leftIcon as string)} />
        </ShowIf>
      </aside>
      <div className="flex-grow px-4">
        {!!title && <h3 className={titleClassName}>{title}</h3>}
        {!!subTitle && <div className={subTitleClassName}>{subTitle}</div>}
        {children}
      </div>
      <aside className={classnames('flex-none', rightIconClassName)}>
        {rightIcon}
      </aside>
    </Flex>
  );
};
export { ListItem };
