import classNames from 'classnames';
import React from 'react';

interface IInsetProps {
  position?: 'top' | 'right' | 'bottom' | 'left' | 'cover' | 'top-right';
  className?: string;
}

const Inset: React.FC<React.PropsWithChildren<IInsetProps>> = ({
  position = 'cover',
  className,
  children,
}) => {
  return (
    <div
      className={classNames(className, 'absolute', {
        'inset-0': position === 'cover',
        'inset-x-0 bottom-0': position === 'bottom',
        'top-0 right-0': position === 'top-right',
      })}
    >
      {children}
    </div>
  );
};
export default Inset;
