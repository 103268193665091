import React from 'react';
import TimeSelect from '../TimeSelect/TimeSelect';
import PhoneInput from 'react-phone-number-input';
import { ComponentsContext } from '../../contexts/componentsContext';
import { Select } from '../../flayr-components/Select/Select';
import { Input } from '../../flayr-components/Input/Input';

interface ICheckAvailabilityFormInputProps {
  field?: any;
  formValue?: any;
  onChange?: (e?: any) => void;
}

const CheckAvailabilityFormInput: React.FC<
  React.PropsWithChildren<
    React.PropsWithChildren<ICheckAvailabilityFormInputProps>
  >
> = ({ field, formValue, onChange }) => {
  const { phoneInput } = React.useContext(ComponentsContext);
  const {
    name,
    type,
    placeholder,
    validation,
    options,
    dependsOn,
    whenValueIs,
  } = field;
  const value = formValue[name] || '';

  function handleMobileChange(value) {
    onChange?.({ target: { name, value } });
  }

  if (
    !field.dependsOn ||
    (field.dependsOn && formValue[dependsOn] === whenValueIs)
  ) {
    switch (type) {
      case 'select':
        return (
          <Select
            name={name}
            value={value}
            options={options}
            placeholder={placeholder}
            onChange={onChange}
            size="lg"
          />
        );
      case 'timeSelect':
        return (
          <TimeSelect
            placeholder={placeholder}
            name={name}
            value={value}
            onChange={onChange}
            size="lg"
          />
        );
      case 'mobile':
        return (
          <PhoneInput
            defaultCountry={phoneInput?.defaultCountry as any}
            placeholder={placeholder}
            inputComponent={Input}
            onChange={handleMobileChange}
            value={value}
            name={name}
            numberInputProps={{
              size: 'lg',
              validation: ['required'],
            }}
          />
        );
      default:
        return (
          <Input
            type={type}
            name={name}
            value={value}
            placeholder={placeholder}
            validation={validation}
            onChange={onChange}
            size="lg"
          />
        );
    }
  }
  return null;
};
export default CheckAvailabilityFormInput;
