import { faSearch } from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Flex } from '../../flayr-components/Flex/Flex';
import React from 'react';
import PlacesInput from '../../lib/PlacesInput/PlacesInput';
import { ILocation } from '../../types/location/location';
import {
  convertMapboxLocationToLocation,
  getClearedLocation,
} from '../../utils/projectSpecificUtils';

interface ISearchWizardLocationInputProps {
  onLocationSelect?: (e: any) => void;
  onLocationOptionsUpdate?: (e: any) => void;
  placeholder?: string;
  location?: ILocation;
  autoFocus?: boolean;
  proximity?: number[];
  onBlur?: (e?: any) => void;
}

const SearchWizardLocationInput: React.FC<React.PropsWithChildren<React.PropsWithChildren<ISearchWizardLocationInputProps>>> = ({
  location,
  onLocationSelect,
  autoFocus,
  placeholder,
  onLocationOptionsUpdate,
  onBlur,
  proximity,
}) => {
  function handleSelect(e) {
    onLocationSelect?.(convertMapboxLocationToLocation(e));
  }
  function handleClear() {
    onLocationSelect?.(getClearedLocation());
  }

  return (
    <div>
      <div className="relative">
        <PlacesInput
          value={location?.address?.placeName || ''}
          placeholder={placeholder}
          inputClassName="text-lg pl-10 pr-4 bg-gray-100 bg-opacity-50 md:bg-opacity-100 w-full rounded-xl border border-gray-200 text-gray-600 focus:outline-none focus:border-gray-300 py-2"
          clearBtnClassName="md:text-gray-600 bg-gray-200 rounded-full"
          fixedOptions={false}
          autoFocus={autoFocus}
          onSelect={handleSelect}
          onClear={handleClear}
          onBlur={onBlur}
          onOptionsUpdate={onLocationOptionsUpdate}
          proximity={proximity}
        />
        <Flex className="absolute top-0 left-0 h-12 pl-3 text-sm text-gray-400 pointer-events-none">
          <FontAwesomeIcon icon={faSearch} />
        </Flex>
      </div>
    </div>
  );
};
export default SearchWizardLocationInput;
