import axios from 'axios';
import React from 'react';

interface IAppContext {
  userCountry: { countryCode: string; coordinates: number[] };
  setUserCountry: (e: any) => void;
  getUserCountry: () => void;
}

const AppContext = React.createContext<IAppContext>({
  userCountry: {
    countryCode: '',
    coordinates: [],
  },
  setUserCountry: () => {},
  getUserCountry: () => {},
});

const AppContextProvider: React.FC<React.PropsWithChildren<any>> = (props) => {
  const [userCountry, setUserCountry] = React.useState<{
    countryCode: string;
    coordinates: number[];
  }>({ countryCode: '', coordinates: [] });

  async function getUserCountry() {
    const res = await axios.get('https://freegeoip.app/json/');
    const countryCode = res.data.country_code;
    const coordinates = [res.data.longitude, res.data.latitude];
    setUserCountry({ countryCode, coordinates });
  }

  return (
    <AppContext.Provider
      value={{ userCountry, setUserCountry, getUserCountry }}
      {...props}
    />
  );
};

function useAppContext() {
  const ctx = React.useContext(AppContext);
  return ctx;
}

export { AppContextProvider, useAppContext };
