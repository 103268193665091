import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { LOGOUT } from '../actions/types';
import {
  clearUserDataFromStorage,
  getUserDataFromStorage,
  isTokenExpired,
  setUserDataToStorage,
} from '../utils';
import React from 'react';

export default function useAuth() {
  const history = useHistory();

  const dispatch = useDispatch();

  const [loggedIn, setLoggedIn] = React.useState<boolean>(false);

  function logOut(url?, pathRequested = '') {
    if (!pathRequested) {
      clearUserDataFromStorage();
    } else {
      setUserDataToStorage({ token: '', pathRequested });
    }

    dispatch({ type: LOGOUT });
    if (typeof url !== 'string') {
      url = '/login';
    }
    history.push(url);
  }

  React.useEffect(() => {
    const token = getUserDataFromStorage().token;
    if (token && !isTokenExpired(token)) {
      setLoggedIn(true);
    } else {
      clearUserDataFromStorage();
    }
  }, []);

  return {
    logOut,
    loggedIn,
  };
}
