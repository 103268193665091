import React from 'react';
import { useHistory } from 'react-router-dom';
import { Button, Flex } from '../../flayr-components';
import { Heading } from '../../flayr-components/Heading/Heading';
import useCreateSearchForm from '../../hooks/useCreateSearchForm';
import useScrollLock from '../../hooks/useScrollLock';
import useSteps from '../../hooks/useSteps';
import SingularOrPluralText from '../../lib/SingularOrPluralText/SingularOrPluralText';
import { addToSerchParams } from '../../utils';
import {
  convertMapboxLocationToLocation,
  getClearedLocation,
} from '../../utils/projectSpecificUtils';
import FormWizard from '../FormWizard/FormWizard';
import SearchWizardInputItem from '../SearchWizardInputItem/SearchWizardInputItem';
// import SearchWizardLocationInput from '../SearchWizardLocationInput/SearchWizardLocationInput';
import SearchWizardServiceSelect from '../SearchWizardServiceSelect/SearchWizardServiceSelect';
import SwipeTransition from '../SwipeTransition/SwipeTransition';

interface ISearchFormWizardProps {
  open?: boolean;
  selectedStep?: string;
  className?: string;
  updateExistingSearch?: boolean;
  resetWithInitialValueOnClose?: boolean;
  locationPlaceholder?: string;
  proximity?: number[];
  onClose?: (e?: any) => void;
  onSubmit?: (e?: any) => void;
}

const setpsHeadingsMapping = {
  location: {
    title: `What neighborhood or place?`,
    subTitle: 'Location',
  },
  date: {
    title: `When is your event?`,
    subTitle: 'Date',
  },
  time: {
    title: `What time do you need to be ready by?`,
    subTitle: 'Time',
  },
  services: {
    title: `What services?`,
    subTitle: 'Services',
  },
};

const stepValueMapping = {
  location: 'location',
  date: 'eventDate',
  time: 'finishTime',
  services: 'services',
};
const SearchFormWizard: React.FC<React.PropsWithChildren<React.PropsWithChildren<ISearchFormWizardProps>>> = ({
  selectedStep,
  open,
  className,
  onClose,
  updateExistingSearch,
  resetWithInitialValueOnClose,
  onSubmit,
  locationPlaceholder,
  proximity,
}) => {
  useScrollLock(open);
  const history = useHistory();

  const [locations, setLocations] = React.useState<any[]>([]);

  const {
    userInput,
    // store,
    // category,
    serviceTypes,
    // resetLocalState,
    setUserInput,
    searchContext,
  } = useCreateSearchForm({
    onNextStep: handleNext,
  });

  const {
    step,
    isLastStep,
    isFirstStep,
    index,
    nextStep,
    prevStep,
    setStep,
    prev,
    next,
  } = useSteps(['location', 'date', 'time', 'services']);

  React.useEffect(() => {
    if (open && selectedStep) {
      setStep(selectedStep);
    }
  }, [open, selectedStep]);

  const isFormValid = React.useMemo(() => {
    const { location, eventDate, finishTime, services } = userInput;
    switch (step) {
      case 'location':
        return !!location?.coordinates.length || !!locations.length;
      case 'date':
        return !!eventDate;
      case 'time':
        return !!finishTime;
      case 'services':
        return !!services?.length;
    }
  }, [userInput, step, locations]);

  function handleNext() {
    if (isLastStep || updateExistingSearch) {
      let input = { ...userInput };
      if (
        updateExistingSearch &&
        step === 'location' &&
        !userInput.location.coordinates.length &&
        locations.length
      ) {
        input.location = locations[0];
        setUserInput?.(input);
        return;
      }
      onSubmit?.(input);
      onClose?.();
      return;
    }
    if (isFormValid) {
      if (
        step === 'location' &&
        !userInput.location.coordinates.length &&
        locations.length
      ) {
        setUserInput?.({ ...userInput, location: locations[0] });
        return;
      }
      const search = addToSerchParams(history.location.search, {
        stage: nextStep?.step,
      });
      history.replace({
        pathname: history.location.pathname,
        search,
      });
      next();
    }
  }

  function handleBack() {
    if (isFirstStep) {
      if (resetWithInitialValueOnClose && searchContext.initialSearch) {
        setUserInput?.({ ...searchContext.initialSearch, skipSubmit: true });
      }
      return onClose?.();
    }
    const search = addToSerchParams(history.location.search, {
      stage: prevStep?.step,
    });
    history.replace({
      pathname: history.location.pathname,
      search,
    });
    prev();
  }

  function handleClear() {
    let data: any = { ...userInput };
    switch (step) {
      case 'location':
        data.location = getClearedLocation();
        break;
      case 'services':
        data.services = [];
        break;
      default:
        data[stepValueMapping[step]] = '';
        break;
    }
    setUserInput?.(data);
  }

  function handleInputChange(e) {
    if (step === 'time') {
      e = e.target.value;
    }

    const input = { [stepValueMapping[step]]: e };
    setUserInput?.({ ...userInput, ...input });
  }

  function handleLocationOptionsUpdate(options) {
    options = options?.map((o) => convertMapboxLocationToLocation(o));
    setLocations(options);
  }

  function handleLocationInputBlur(e) {
    if (e.target.value && locations.length) {
      const location = locations[0];
      setUserInput?.({ ...userInput, location });
    }
  }

  return (
    <FormWizard
      className={className}
      open={open}
      onClose={onClose}
      activeStep={step}
      onBack={handleBack}
      title={setpsHeadingsMapping[step]?.title}
      subTitle={setpsHeadingsMapping[step]?.subTitle}
      onNext={handleNext}
      nextBtnDisabled={!isFormValid}
      nextBtnLabel={
        (updateExistingSearch && 'Update') || (isLastStep && 'Search') || 'Next'
      }
      onClear={handleClear}
      data-testid="search-form-wizard"
    >
      <SwipeTransition activeStepIndex={index} duration="500">
        <SearchWizardInputItem
          stepName="location"
          value={userInput}
          availableServices={serviceTypes}
          valueMapping={stepValueMapping}
          onChange={handleInputChange}
          placeholder={locationPlaceholder}
          onLocationOptionsUpdate={handleLocationOptionsUpdate}
          proximity={proximity}
          autoFocus={
            step === 'location' && !userInput.location?.coordinates?.length
          }
        />

        <div className="px-3 -mt-6">
          <SearchWizardInputItem
            stepName="date"
            value={userInput}
            availableServices={serviceTypes}
            valueMapping={stepValueMapping}
            onChange={handleInputChange}
          />
        </div>

        <SearchWizardInputItem
          stepName="time"
          value={userInput}
          availableServices={serviceTypes}
          valueMapping={stepValueMapping}
          onChange={handleInputChange}
        />

        <div className="pb-12">
          <div className="pb-12">
            <Heading size="lg">Services</Heading>
            <SearchWizardServiceSelect
              addBtnPlacement="bottom-right"
              availableServices={serviceTypes || []}
              services={userInput.services || []}
              onSelect={handleInputChange}
              renderAddBtn={({ onAdd }) => (
                <Flex x="between" className="px-4 pt-5 border-t" wrap>
                  <span className="mb-4 font-medium">
                    {userInput.services?.length}{' '}
                    <SingularOrPluralText
                      number={userInput.services.length}
                      singular="person"
                      plural="people"
                    />
                  </span>

                  <Button
                    className="mb-4 uppercase"
                    color="hot-pink"
                    round
                    onClick={onAdd}
                  >
                    Add another person
                  </Button>
                </Flex>
              )}
            />
          </div>
        </div>
      </SwipeTransition>
    </FormWizard>
  );
};
export default SearchFormWizard;
