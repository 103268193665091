import React from 'react';
import { Link } from 'react-router-dom';
import SearchFormContainer from '../../../../containers/SearchFormContainer/SearchFormContainer';
import { Flex, useToggle } from '../../../../flayr-components';
import LogoNew from '../../../../lib/LogoNew/LogoNew';
import Portal from '../../../../lib/Portal/Portal';
import CreateSearchBar from '../CreateSearchBar/CreateSearchBar';

interface IDesktopSearchBarAltProps {
  proximity?: number[];
  locationPlaceholder?: string;
}

const DesktopSearchBarAlt: React.FC<React.PropsWithChildren<IDesktopSearchBarAltProps>> = ({
  proximity,
  locationPlaceholder,
}) => {
  const startSearch = useToggle();
  return (
    <Portal>
      {startSearch.opened && (
        <div
          className="fixed top-0 left-0 z-30 hidden w-full h-full bg-gray-700 opacity-25 lg:block"
          onClick={startSearch.toggle}
        />
      )}
      <div className="fixed top-0 left-0 z-40 items-center hidden w-full bg-white shadow-lg lg:block">
        <Flex className="w-11/12 py-px mx-auto">
          <Link to="/">
            <LogoNew className="text-2xl" />
          </Link>

          <div className="flex-none flex-grow pl-5">
            <div className="w-full mx-auto border rounded-full xl:w-9/12">
              <SearchFormContainer
                component={CreateSearchBar}
                externalProps={{
                  popDownIdPrefix: 'nav',
                  locationPlaceholder,
                  proximity,
                }}
              />
            </div>
          </div>
          {/* {startSearch.closed && (
            <div className="mx-auto text-gray-600 w-80">
              <Flex
                className="w-full p-1 border border-[#EAEAEA] cursor-pointer rounded-xl bg-[#F3F3F3]"
                onClick={startSearch.toggle}
              >
                <button className="flex items-center justify-center w-10 h-10 bg-white rounded-xl text-[#6B6B6B]">
                  <SearchIcon />
                </button>
                <span className="self-center pl-4 text-sm text-[#666]">
                  Start search
                </span>
              </Flex>
            </div>
          )}
          {startSearch.opened && (
            <React.Suspense fallback={null}>
              <div className="flex-none flex-grow pl-5">
                <div className="w-full mx-auto border rounded-full xl:w-9/12">
                  <SearchFormContainer
                    component={CreateSearchBar}
                    externalProps={{
                      popDownIdPrefix: 'nav',
                      locationPlaceholder,
                      proximity,
                    }}
                  />
                </div>
              </div>
            </React.Suspense>
          )} */}
        </Flex>
      </div>
    </Portal>
  );
};
export default DesktopSearchBarAlt;
