import React from 'react';
import { Heading, Flex, Button } from '../../flayr-components';

import Calendar from '../../lib/Calendar/Calendar';
import SingularOrPluralText from '../../lib/SingularOrPluralText/SingularOrPluralText';
import CustomerDetailsForm from '../CustomerDetailsForm/CustomerDetailsForm';
import SearchWizardLocationInput from '../SearchWizardLocationInput/SearchWizardLocationInput';
import SearchWizardServiceSelect from '../SearchWizardServiceSelect/SearchWizardServiceSelect';
import TimeSelect from '../TimeSelect/TimeSelect';

interface ISearchWizardInputItemProps {
  placeholder?: string;
  stepName?: string;
  value?: any;
  valueMapping?: any;
  availableServices?: any[];
  autoFocus?: boolean;
  label?: string;
  proximity?: number[];
  onChange?: (e: any) => void;
  onLocationOptionsUpdate?: (e: any) => void;
  onBlur?: (e: any) => void;
}

const SearchWizardInputItem: React.FC<React.PropsWithChildren<React.PropsWithChildren<ISearchWizardInputItemProps>>> = ({
  availableServices = [],
  stepName = '',
  value,
  valueMapping = {
    location: 'location',
    date: 'date',
    time: 'time',
    services: 'services',
  },
  placeholder,
  autoFocus,
  label,
  onChange,
  onLocationOptionsUpdate,
  onBlur,
  proximity,
}) => {
  const parsedValue = value?.[valueMapping[stepName]];

  switch (stepName) {
    case 'location':
      return (
        // the height needs to be there otherwise the location options will be hidden
        // because it overflows outside the modal
        <div className="lg:py-10 lg:min-h-5/12-screen">
          <SearchWizardLocationInput
            onLocationSelect={onChange}
            location={parsedValue}
            autoFocus={autoFocus}
            placeholder={placeholder}
            onLocationOptionsUpdate={onLocationOptionsUpdate}
            proximity={proximity}
            onBlur={onBlur}
          />
        </div>
      );
    case 'date':
      return (
        <Calendar
          className="w-full max-w-lg px-2 pt-5 pb-12 mx-auto lg:pb-0"
          selectedDates={[parsedValue] || undefined}
          onDateSelect={onChange}
          date={parsedValue || undefined}
        />
      );
    case 'time':
      return (
        <div className="pb-12">
          <TimeSelect
            size="lg"
            label={label || 'Time to be ready by'}
            onChange={onChange}
            name="finishTime"
            value={parsedValue || ''}
          />
        </div>
      );
    case 'services':
      return (
        <div className="pb-12">
          <Heading className="my-3" size="lg">
            Services
          </Heading>
          <SearchWizardServiceSelect
            availableServices={availableServices || []}
            services={parsedValue || []}
            onSelect={onChange}
            renderAddBtn={({ onAdd }) => (
              <Flex x="between" className="px-4 pt-5 border-t" wrap>
                <span className="mb-4 font-medium">
                  {parsedValue?.length}{' '}
                  <SingularOrPluralText
                    number={parsedValue?.length || 0}
                    singular="person"
                    plural="people"
                  />
                </span>

                <Button className="mb-4 uppercase" color="dark" onClick={onAdd}>
                  Add another person
                </Button>
              </Flex>
            )}
          />
        </div>
      );
    case 'details':
      return (
        <>
          <div className="mb-4 font-medium text-center">Your details</div>
          <CustomerDetailsForm
            value={value?.requesterInfo}
            onChange={onChange}
          />
        </>
      );
    default:
      return null;
  }
};
export default SearchWizardInputItem;
