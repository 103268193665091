import React, { useState } from 'react';
import classnames from 'classnames';
export interface IReadMoreProps {
  text: React.ReactNode;
  lines?: 2 | 3 | 4 | 5;
  showMoreText?: string;
  showLessText?: string;
  showHideTextClassName?: string;
  hideShowMore?: boolean;
  className?: string;
  open?: boolean;
}

export const ReadMore: React.FC<React.PropsWithChildren<IReadMoreProps>> = ({
  text = '',
  lines = 3,
  hideShowMore,
  showLessText = 'show less',
  showMoreText = 'show more',
  showHideTextClassName = 'text-sm font-medium',
  className,
  open = false,
}) => {
  const [showFullContent, setShowFullContent] = useState(open);
  const [contentOverflows, setContentOverflows] = React.useState<any>(true);
  const ref = React.useRef<HTMLDivElement>(null);

  React.useEffect(() => {
    // hide the read-more bit if the content is less than the number of lines provided
    const el = ref.current;
    if (el) {
      const getStyle = (el, styleProp) => {
        var x: any = el;
        let y: any;
        if (x.currentStyle) y = x.currentStyle[styleProp];
        else if ((window as any).getComputedStyle)
          y = (document as any).defaultView
            .getComputedStyle(x, null)
            .getPropertyValue(styleProp);
        return y;
      };

      const divHeight = el.scrollHeight;
      const lineHeight = +getStyle(el, 'line-height').replace('px', '');
      var contentLines = divHeight / lineHeight;
      contentLines <= lines && setContentOverflows(false);
    }
  }, []);

  function toggleContent() {
    setShowFullContent(!showFullContent);
  }

  return (
    <div className={classnames(className)}>
      <div
        className={classnames({
          'line-clamp-2': lines === 2 && !showFullContent,
          'line-clamp-3': lines === 3 && !showFullContent,
          'line-clamp-4': lines === 4 && !showFullContent,
          'line-clamp-5': lines === 5 && !showFullContent,
        })}
        ref={ref}
      >
        {text}
      </div>
      {!hideShowMore && contentOverflows && (
        <span
          onClick={toggleContent}
          className={classnames('cursor-pointer', showHideTextClassName)}
        >
          {(showFullContent && showLessText) || showMoreText}
        </span>
      )}
    </div>
  );
};
