import { TDropShadow } from '../types/dropShadow';
import { TRoundedCorners } from '../types/roundedCorners';
import { TNegativeSpacing, TSpacing } from '../types/spacing';

export function getShadowClassNames(shadow?: TDropShadow) {
  if (!shadow) {
    return undefined;
  }
  return {
    shadow: shadow === true,
    'shadow-none': shadow === 'none',
    'shadow-xs': shadow === 'xs',
    'shadow-md': shadow === 'md',
    'shadow-lg': shadow === 'lg',
    'shadow-xl': shadow === 'xl',
    'shadow-2xl': shadow === '2xl',
  };
}

export function getRoundedClassNames(rounded?: TRoundedCorners) {
  if (!rounded) {
    return undefined;
  }
  return {
    rounded: rounded === true,
    'rounded-none': rounded === 'none',
    'rounded-sm': rounded === 'sm',
    'rounded-md': rounded === 'md',
    'rounded-lg': rounded === 'lg',
    'rounded-xl': rounded === 'xl',
    'rounded-2xl': rounded === '2xl',
    'rounded-3xl': rounded === '3xl',
    'rounded-full': rounded === 'full',
  };
}
export function getZIndexClassNames(z) {
  return {
    'z-10': z === '10',
    'z-20': z === '20',
    'z-30': z === '30',
    'z-40': z === '40',
    'z-50': z === '50',
  };
}

export function getPaddingClassNames(padding?: TSpacing) {
  if (!padding) {
    return undefined;
  }
  return {
    'p-0': padding === '0',
    'p-1': padding === '1',
    'p-2': padding === '2',
    'p-3': padding === '3',
    'p-4': padding === '4',
    'p-5': padding === '5',
    'p-6': padding === '6',
    'p-7': padding === '7',
    'p-8': padding === '8',
    'p-9': padding === '9',
    'p-10': padding === '10',
    'p-12': padding === '12',
    'p-14': padding === '14',
    'p-16': padding === '16',
    'p-20': padding === '20',
    'p-24': padding === '24',
    'p-28': padding === '28',
    'p-32': padding === '32',
    'p-36': padding === '36',
    'p-40': padding === '40',
    'p-44': padding === '44',
    'p-48': padding === '48',
    'p-52': padding === '52',
    'p-56': padding === '56',
    'p-60': padding === '60',
    'p-64': padding === '64',
    'p-72': padding === '72',
    'p-80': padding === '80',
    'p-96': padding === '96',
    'p-px': padding === 'px',
  };
}

export function getMarginClassNames(margin?: TSpacing | TNegativeSpacing) {
  if (!margin) {
    return undefined;
  }
  return {
    'p-0': margin === '0',
    'p-0.5': margin === '0.5',
    'p-1': margin === '1',
    'p-1.5': margin === '1.5',
    'p-2': margin === '2',
    'p-3': margin === '3',
    'p-4': margin === '4',
    'p-5': margin === '5',
    'p-6': margin === '6',
    'p-7': margin === '7',
    'p-8': margin === '8',
    'p-9': margin === '9',
    'p-10': margin === '10',
    'p-11': margin === '11',
    'p-12': margin === '12',
    'p-14': margin === '14',
    'p-16': margin === '16',
    'p-20': margin === '20',
    'p-24': margin === '24',
    'p-28': margin === '28',
    'p-32': margin === '32',
    'p-36': margin === '36',
    'p-40': margin === '40',
    'p-44': margin === '44',
    'p-48': margin === '48',
    'p-52': margin === '52',
    'p-56': margin === '56',
    'p-60': margin === '60',
    'p-64': margin === '64',
    'p-72': margin === '72',
    'p-80': margin === '80',
    'p-96': margin === '96',
    'p-px': margin === 'px',
    '-p-0.5': margin === '-0.5',
    '-p-1': margin === '-1',
    '-p-1.5': margin === '-1.5',
    '-p-2': margin === '-2',
    '-p-3': margin === '-3',
    '-p-4': margin === '-4',
    '-p-5': margin === '-5',
    '-p-6': margin === '-6',
    '-p-7': margin === '-7',
    '-p-8': margin === '-8',
    '-p-9': margin === '-9',
    '-p-10': margin === '-10',
    '-p-11': margin === '-11',
    '-p-12': margin === '-12',
    '-p-14': margin === '-14',
    '-p-16': margin === '-16',
    '-p-20': margin === '-20',
    '-p-24': margin === '-24',
    '-p-28': margin === '-28',
    '-p-32': margin === '-32',
    '-p-36': margin === '-36',
    '-p-40': margin === '-40',
    '-p-44': margin === '-44',
    '-p-48': margin === '-48',
    '-p-52': margin === '-52',
    '-p-56': margin === '-56',
    '-p-60': margin === '-60',
    '-p-64': margin === '-64',
    '-p-72': margin === '-72',
    '-p-80': margin === '-80',
    '-p-96': margin === '-96',
    '-p-px': margin === '-px',
  };
}
