import React from 'react';
import CookieConsent from '../../components/CookieConsent/CookieConsent';
import { Heading, Img, ReadMore } from '../../flayr-components';
import useDeviceMediaQuery from '../../hooks/useDeviceMediaQuery';
import useToggle from '../../hooks/useToggle';
import ArrayMap from '../../lib/ArrayMap/ArrayMap';
import BreadcrumbsJsonLd from './components/BreadcrumbsJsonLd/BreadcrumbsJsonLd';
import DesktopBanner from './components/DesktopBanner/DesktopBanner';
import MobileBanner from './components/MobileBanner/MobileBanner';
import PageMetadata from './components/PageMetadata/PageMetadata';

// const DesktopBanner = React.lazy(
//   () => import('./components/DesktopBanner/DesktopBanner')
// );

// const CommonPageSections = React.lazy(
//   () => import('./components/CommonPageSections/CommonPageSections')
// );

interface ILandingPageLayoutProps {
  metaTitle?: string;
  seoTitle?: string;
  seoDescription?: string;
  websiteSchema?: any;
  serviceSchema?: any;
  reviewSchema?: any;
  faqSchema?: any;
  title?: string;
  metaDescription?: string;
  banner?: any;
  media?: any[];
  basePath?: string;
  services?: any[];
  popularCities?: any;
  popularPlaces?: any[];
  customerReviews?: any[];
  events?: any[];
  totalReviewScore?: number;
  totalReviewCount?: number;
  city?: string;
  providers?: any[];
  recentBookings?: any[];
  personServiceNameMapping?: any;
  faqs?: any[];
  center?: { lat: number; lng: number };
  mapItems?: any[];
  breadcrumbs?: any[];
  suburbList?: any[];
  sectionTitleMapping?: {
    services: string;
    popularCities: string;
    customerReiews: string;
    events: string;
    providers: string;
    recentBookings: string;
    similarEvents?: string;
    specialEvents?: string;
  };
}

const LandingPageLayout: React.FC<React.PropsWithChildren<ILandingPageLayoutProps>> = (props) => {
  const { isMobile } = useDeviceMediaQuery();
  const bannerSectionLoaded = useToggle(true);

  React.useEffect(() => {
    window.scrollTo(0, 0);
  }, [props]);

  const {
    metaTitle,
    websiteSchema,
    seoTitle,
    seoDescription,
    serviceSchema,
    reviewSchema,
    faqSchema,
    title,
    metaDescription,
    banner,
    media,
    basePath,
    sectionTitleMapping,
    breadcrumbs,
    popularPlaces,
    totalReviewCount,
    totalReviewScore,
    customerReviews,
    providers,
    center,
  } = props;

  return (
    <main className="bg-gray-50 font-poppins tracking-[.05em] leading-[1.5em] text-sm text-gray-primary">
      <PageMetadata
        title={seoTitle || websiteSchema?.name || metaTitle || title}
        description={
          seoDescription || websiteSchema?.description || metaDescription
        }
      />
      {/* banner section */}
      {isMobile && (
        <MobileBanner
          title={banner?.title}
          subTitle={banner?.subTitle}
          onLoaded={bannerSectionLoaded.open}
          bannerImg={{ url: banner?.img, alt: banner?.alt }}
          locationPlaceholder={banner.locationPlaceholder || 'Location'}
          // media={media}
          // popularPlaces={popularPlaces}
          // testimonials={customerReviews}
          // totalReviewCount={totalReviewCount}
          // totalReviewScore={totalReviewScore}
          // providers={providers}
          proximity={(!!center && [center.lng, center.lat]) || undefined}
        />
      )}
      {!isMobile && (
        <DesktopBanner
          bannerTitle={banner?.title}
          bannerSubTitle={banner?.subTitle}
          bannerImg={banner?.img}
          bannerImgAlt={banner?.alt}
          imgPosition={banner?.position}
          popularPlaces={popularPlaces}
          locationPlaceholder={banner.locationPlaceholder || 'Location'}
          media={media}
          onLoaded={bannerSectionLoaded.open}
          proximity={(!!center && [center.lng, center.lat]) || undefined}
        />
        // <React.Suspense fallback={<Spinner className="my-8" showing />}>
        // </React.Suspense>
      )}
      {/* rest of the page */}
      {/* {false && bannerSectionLoaded.opened && !isMobile && (
        <React.Suspense fallback={null}>
          <CommonPageSections
            sectionTitleMapping={sectionTitleMapping}
            {...props}
          />
        </React.Suspense>
      )} */}

      {/* testimonials list */}
      <div className="w-full p-5 pb-12 -mt-1 text-white bg-black md:py-12">
        <div className="w-full mx-auto md:w-9/12">
          <Heading className="mt-4 mb-8 capitalize" size="3xl" bold>
            What our customers are saying
          </Heading>
          <div className="flex w-full space-x-5 overflow-x-auto flex-nowrap">
            <ArrayMap items={customerReviews}>
              {(item, idx) => (
                <div className="flex flex-col self-stretch flex-none w-8/12 ml-px overflow-hidden rounded-2xl md:w-72">
                  <Img
                    src={item.coverImg?.src}
                    alt={item.coverImg?.alt}
                    size="3by4"
                  />
                  <div className="flex-grow p-3 bg-gray-700">
                    <div className="text-base font-semibold text-center">
                      {item.customerName}
                    </div>
                    <div className="mt-2 text-sm">
                      <ReadMore
                        className="leading-[1.5em]"
                        text={item.comment}
                        showMoreText="Read more"
                      />
                    </div>
                  </div>
                </div>
              )}
            </ArrayMap>
          </div>
        </div>
      </div>

      <CookieConsent />

      {/* schema section */}
      {/* localbusiness schema */}
      {!!serviceSchema &&
        typeof serviceSchema === 'string' &&
        JSON.parse(serviceSchema).map((content, i) => (
          <script
            type="application/ld+json"
            dangerouslySetInnerHTML={{
              __html: content,
            }}
            key={i}
          />
        ))}
      {/* website schema */}
      {!!websiteSchema && (
        <script
          type="application/ld+json"
          dangerouslySetInnerHTML={{
            __html: `
              {
                "@context": "http://schema.org",
                "@type": "WebSite",
                "name": "${websiteSchema.name}",
                "description": "${websiteSchema.description}",
                "url": "https://www.flayr.com.au${basePath}"
              }
            `,
          }}
        />
      )}
      {/* review schema */}
      {!!reviewSchema && (
        <script
          type="application/ld+json"
          dangerouslySetInnerHTML={{
            __html: reviewSchema,
          }}
        />
      )}
      {/* faq schema */}
      {!!faqSchema && (
        <script
          type="application/ld+json"
          dangerouslySetInnerHTML={{
            __html: faqSchema,
          }}
        />
      )}

      {/* breadcrumbs schema */}
      {!!breadcrumbs?.length && <BreadcrumbsJsonLd breadcrumbs={breadcrumbs} />}
    </main>
  );
};
export default LandingPageLayout;
