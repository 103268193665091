import React from 'react';

interface IPageMetadataProps {
  title?: string;
  description?: string;
}

const PageMetadata: React.FC<React.PropsWithChildren<IPageMetadataProps>> = ({ title, description }) => {
  React.useEffect(() => {
    if (title) {
      document.title = title;
    }
    if (description) {
      let metaDescription = document.head.children.namedItem('description');
      metaDescription?.setAttribute('content', description);
    }
  }, [title, description]);

  return null;
};
export default PageMetadata;
