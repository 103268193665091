import React, { lazy, Suspense } from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';
import { SearchFormContextProvider } from '../../contexts/SearchFormContext';
import SignupProgressContext from '../../contexts/signupProgressContext';
import { ToastContextProvider } from '../../contexts/toastContext';
import { Spinner } from '../../flayr-components/Spinner/Spinner';
import useGclidTracking from '../../hooks/useGclidTracking';
import useLoaderStore from '../../hooks/useLoaderStore';
import ModalToast from '../../lib/ModalToast/ModalToast';
import Portal from '../../lib/Portal/Portal';
import PrivateRoute from '../../lib/PrivateRoute/PrivateRoute';
import { INavLink } from '../../lib/Sidebar/Sidebar';

import SeoPageRoutes from '../SeoPageRoutes/SeoPageRoutes';
import { sidebarNavLinks } from './appRoutesStaticData';

const ForgotPasswordPage = lazy(
  () => import('../../pages/ForgotPasswordPage/ForgotPasswordPage')
);

const LogoutPage = lazy(() => import('../../pages/LogoutPage/LogoutPage'));

const SelectDashboardPage = lazy(
  () => import('../../pages/SelectDashboardPage/SelectDashboardPage')
);

const MagicLinkLoginPage = lazy(
  () => import('../../pages/MagicLinkLoginPage/MagicLinkLoginPage')
);

const ProviderMagicLinkLoginPage = lazy(
  () =>
    import(
      '../../pages/ProviderLoginWithMagicLinkPage/ProviderLoginWithMagicLinkPage'
    )
);

const NotificationAppPage = lazy(
  () => import('../../pages/NotificationAppPage/NotificationAppPage')
);

const ProfilePage = lazy(
  () => import('../../pages/customerPages/ProfilePage/ProfilePage')
);

const ComingSoonPage = lazy(
  () => import('../../pages/ComingSoonPage/ComingSoonPage')
);

const ResetPasswordPage = lazy(
  () => import('../../pages/ResetPasswordPage/ResetPasswordPage')
);

const LoginPage = lazy(() => import('../../pages/LoginPage/LoginPage'));

const JoinPage = lazy(() => import('../../pages/JoinPage/JoinPage'));

const NotFoundPage = lazy(
  () => import('../../pages/NotFoundPage/NotFoundPage')
);

const ReviewFormPage = lazy(
  () => import('../../pages/ReviewFormPage/ReviewFormPage')
);

const PostReviewPage = lazy(
  () => import('../../pages/PostReviewPage/PostReviewPage')
);

// content routes
const ContentRoutes = lazy(() => import('../ContentRoutes/ContentRoutes'));

// verify routes
const VerifyRoutes = lazy(() => import('../VerifyRoutes/VerifyRoutes'));

const ProviderProfileRoutes = lazy(
  () => import('../ProviderProfileRoutes/ProviderProfileRoutes')
);

const LiveCoursesRoutes = lazy(
  () => import('../LiveCoursesRoutes/LiveCoursesRoutes')
);

// route components
const DashboardRoutes = lazy(
  () => import('../DashboardRoutes/DashboardRoutes')
);

const ResubmissionRoutes = lazy(
  () => import('../ResubmissionRoutes/ResubmissionRoutes')
);
const SignupRoutes = lazy(() => import('../SignupRoutes/SignupRoutes'));

// const SeoPageRoutes = lazy(() => import('../SeoPageRoutes/SeoPageRoutes'));

const QuotePage = lazy(
  () => import('../../pages/customerPages/QuotePage/QuotePage')
);

const OffersRoutes = lazy(() => import('../OffersRoutes/OffersRoutes'));

const CheckoutRoutes = lazy(() => import('../CheckoutRoutes/CheckoutRoutes'));

const CustomerDashboardRoutes = lazy(
  () => import('../CustomerDashboardRoutes/CustomerDashboardRoutes')
);

const LoginAsPage = lazy(() => import('../../pages/LoginAsPage/LoginAsPage'));

interface AppRoutesProps {}

const AppRoutes: React.FC<React.PropsWithChildren<AppRoutesProps>> = () => {
  const { loader } = useLoaderStore();

  useGclidTracking();

  const [toast, setToast] = React.useState<{
    open: boolean;
    type: string;
    message: string;
  }>({
    open: false,
    type: '',
    message: '',
  });

  function showToast(type, message) {
    setToast({ open: true, type, message });
  }

  function hideToast(onClose?) {
    setToast({ open: false, type: '', message: '' });
    typeof onClose === 'function' && onClose?.();
  }

  const [sidebarLinks, setSidebarLinks] =
    React.useState<INavLink[]>(sidebarNavLinks);

  return (
    <SearchFormContextProvider>
      <SignupProgressContext.Provider
        value={{
          links: sidebarLinks,
          setLinks: setSidebarLinks,
        }}
      >
        <ToastContextProvider value={{ ...toast, showToast, hideToast }}>
          <Suspense fallback={<Spinner showing fullScreen />}>
            <Switch>
              <Redirect exact path="/products/:location" to="/" />
              <Redirect path="/blog" to="/" />

              <Route
                exact
                path={[
                  '/join',
                  '/join-mua',
                  '/join-sg',
                  '/join-now',
                  '/join-flayr',
                  '/nails/join-us',
                  '/providers/join-us',
                  '/providers/join-us',
                  '/hairdressers/join-us-1',
                  '/hairdressers/join-us-2',
                  '/hairdressers/join-us-3',
                  '/hairdressers/join-us-4',
                  '/hairdressers/join-us-5',
                  '/hairdressers/join-us-mobile',
                  '/hairdressers/join-us-studio',
                ]}
                component={JoinPage}
              />

              <Route
                exact
                path="/providers/notifications-app"
                component={NotificationAppPage}
              />
              {/* content routes */}
              <Redirect
                exact
                path="/makeup-and-hair/gallery"
                to="/content/gallery"
              />

              <Route path="/content">
                <ContentRoutes />
              </Route>
              {/* verify routes */}
              <Route path="/verify">
                <VerifyRoutes />
              </Route>
              {/* signup routes */}
              <Route path="/signup">
                <SignupRoutes />
              </Route>

              {/* login */}
              <Route exact path="/login" component={LoginPage} />
              <Route exact path="/logout" component={LogoutPage} />
              <PrivateRoute
                exact
                path="/select-dashboard"
                component={SelectDashboardPage}
              />
              <Route
                exact
                path={`/login/magic-link/:token`}
                component={MagicLinkLoginPage}
              />

              <Route
                exact
                path={`/login/provider/:token`}
                component={ProviderMagicLinkLoginPage}
              />

              <Route
                exact
                path="/forgot-password"
                component={ForgotPasswordPage}
              />
              <Route
                exact
                path="/reset-password/:token"
                component={ResetPasswordPage}
              />

              <Redirect exact path="/account" to="/dashboard" />
              <PrivateRoute path="/dashboard">
                <DashboardRoutes />
              </PrivateRoute>

              <PrivateRoute path="/resubmission">
                <ResubmissionRoutes />
              </PrivateRoute>

              <Route exact path="/search/:quoteId" component={QuotePage} />

              <Route path={['/@', '/profile']}>
                <ProviderProfileRoutes />
              </Route>
              <Route path="/book">
                <CheckoutRoutes />
              </Route>
              {/* reviews */}
              {/* type is either customer or provider */}
              <Route
                exact
                path="/review/:appointmentId/:type/:key"
                component={ReviewFormPage}
              />
              <Route
                exact
                path="/review/:appointmentId/:type/:key/:providerName"
                component={ReviewFormPage}
              />

              <Route
                exact
                path="/post-review/:appointmentId/:type/:key/:score"
                component={PostReviewPage}
              />

              <Route exact path="/coming-soon" component={ComingSoonPage} />
              {/* quote page */}

              <Route
                exact
                path={['/quote/:quoteId', '/quotes/:quoteId']}
                component={QuotePage}
              />
              <Route
                exact
                path={[
                  '/quote/:quoteId/@/:profileId',
                  '/quotes/:quoteId/@/:profileId',
                ]}
                component={ProfilePage}
              />

              {/* offer routes */}
              <Route path={['/offer', '/offers']}>
                <OffersRoutes />
              </Route>

              <Route path="/customer-dashboard">
                <CustomerDashboardRoutes />
              </Route>

              <Redirect exact path="/:id/get-quote" to="/" />

              <Route exact path="/login-as/:token" component={LoginAsPage} />

              <Route
                exact
                path={['/404', '/not-found']}
                component={NotFoundPage}
              />

              <Route path="/lessons">
                <LiveCoursesRoutes />
              </Route>

              <Route path="/">
                <SeoPageRoutes />
              </Route>
            </Switch>
          </Suspense>
          <ModalToast {...toast} onClose={hideToast} />
        </ToastContextProvider>
      </SignupProgressContext.Provider>

      <Portal target="body">
        <Spinner showing={loader.showing} fullScreen />
      </Portal>
    </SearchFormContextProvider>
  );
};
export default AppRoutes;
