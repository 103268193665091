import { useContext } from 'react';
import { useSnackbar } from 'react-simple-snackbar';
import { ToastContext } from '../contexts/toastContext';

export default function useToast() {
  const [openSnackbar] = useSnackbar();
  const { showToast, hideToast } = useContext(ToastContext);

  function catchErrorToast(errObj) {
    if (errObj?.response?.data) {
      openSnackbar(errObj?.response?.data);
    }
  }

  function successToast(msg: string) {
    openSnackbar(msg);
  }

  function errorToast(msg: string) {
    openSnackbar(msg);
  }

  function toastSuccess(message) {
    showToast?.('success', message);
    triggerClose();
  }

  function toastError(message) {
    showToast?.('error', message);
    triggerClose();
  }

  function triggerClose(duration = 5000) {
    hideToast && setTimeout(hideToast, duration);
  }

  return {
    catchErrorToast,
    successToast,
    errorToast,
    toastSuccess,
    hideToast,
    toastError,
  };
}
