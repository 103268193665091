import classnames from 'classnames';
import React from 'react';
import { useInView } from 'react-intersection-observer';
import { Flex } from '../../flayr-components/Flex/Flex';
import { Img } from '../../flayr-components/Img/Img';
import { ShowIf } from '../../flayr-components/ShowIf/ShowIf';
import useToggle from '../../hooks/useToggle';
import DesktopSearchBar from '../../layouts/LandingPageLayout/components/DesktopSearchBar/DesktopSearchBar';

interface IPageBannerProps {
  img?: string;
  imgPosition?: 'top' | 'center' | 'bottom';
  alt?: string;
  title?: string;
  subTitle?: string;
  className?: string;
  extension?: React.ReactNode;
  locationPlaceholder?: string;
  popularPlaces?: any[];
  proximity?: number[];
  onSubmit?: (e?: any) => void;
  onLoaded?: (e: any) => void;
}

const PageBanner: React.FC<React.PropsWithChildren<React.PropsWithChildren<IPageBannerProps>>> = ({
  img,
  alt,
  title,
  subTitle,
  className,
  children,
  imgPosition = 'top',
  extension,
  onLoaded,
  onSubmit,
  locationPlaceholder,
  popularPlaces = [],
  proximity,
}) => {
  const desktopRef = useInView({ threshold: 1 });
  const mobileRef = useInView({ threshold: 1 });
  const startSearch = useToggle();
  const scrolledToTop = useToggle();

  React.useEffect(() => {
    if (scrolledToTop.opened && mobileRef.inView && desktopRef.inView) {
      const el = document.querySelector('#location-search-form');
      (el as HTMLInputElement)?.focus();
    }
  }, [scrolledToTop.opened, mobileRef.inView, desktopRef.inView]);

  return (
    <div className={classnames('relative', className)}>
      <div className="absolute top-0 left-0 w-full h-full bg-gray-800 rounded-t-xl">
        <div className="absolute top-0 left-0 hidden w-full h-full overflow-hidden opacity-75 md:block rounded-t-xl">
          {!!img && (
            <Img
              src={img}
              className="absolute top-0 left-0 w-full h-full opacity-75 rounded-t-xl"
              imgClassName="absolute top-0 left-0 w-full h-full"
              alt={alt}
              lazy={false}
              responsive
              onImgLoaded={onLoaded}
              position={imgPosition as any}
              sizes="100vw"
            />
          )}
        </div>
      </div>
      {/* overlay */}
      {/* <Overlay className="bg-gradient-to-b from-transparent via-transparent to-gray-900 lg:hidden" /> */}

      <ShowIf condition={!children} elseTemplate={children}>
        <Flex
          column
          className="absolute top-0 left-0 w-full h-full px-5 pt-32 rounded-t-xl"
        >
          <section className="hidden px-4 mx-auto mt-20 mb-16 text-center text-white md:px-0 text-shadow-xl md:w-11/12 lg:w-9/12 xl:w-8/12 md:block">
            <h1 className="mb-3 lg:mb-5 font-semibold text-center text-lg lg:text-4xl leading-[1.5em]">
              {title}
            </h1>

            <h2 className="flex items-center justify-center text-center leading-[1.5em] lg:text-base text-xs">
              {subTitle}
            </h2>
          </section>

          <DesktopSearchBar
            proximity={proximity}
            locationPlaceholder={locationPlaceholder}
          />

          {/* <section className="w-full mt-5 mb-20 md:mx-auto md:w-11/12 lg:w-9/12 xl:w-8/12">
            <CreateSearchContextProvider>
              <div ref={desktopRef.ref} />
              <div className="hidden h-20 md:block">
                {desktopRef.inView && (
                  <SearchFormContainer
                    component={CreateSearchBar}
                    onSubmit={onSubmit}
                    externalProps={{
                      locationPlaceholder,
                      proximity,
                    }}
                  />
                )}
              </div>
              <div className="mt-8 -ml-3 text-shadow">
                {!!popularPlaces.length && (
                  <div className="w-full px-5 mb-8 text-white">
                    <Heading tag="h2" size="lg" bold="medium">
                      Popular places
                    </Heading>
                    <div className="flex w-full pb-3 space-x-3 overflow-x-auto flex-nowrap">
                      <ArrayMap items={popularPlaces}>
                        {(place) => (
                          <a
                            href={place.link}
                            className="flex-none text-gray-200 underline"
                          >
                            {place.title || place.name}
                          </a>
                        )}
                      </ArrayMap>
                    </div>
                  </div>
                )}
              </div>

              {!desktopRef.inView && (
                <Portal>
                  {startSearch.opened && (
                    <div
                      className="fixed top-0 left-0 z-30 hidden w-full h-full bg-gray-700 opacity-25 md:block"
                      onClick={startSearch.toggle}
                    />
                  )}
                  <div className="fixed top-0 left-0 z-40 items-center hidden w-full bg-white shadow-lg md:block">
                    <Flex className="w-11/12 py-3 mx-auto">
                      <Logo />
                      {startSearch.closed && (
                        <div className="w-3/12 mx-auto text-gray-600">
                          <Flex
                            className="w-full h-12 px-1 border rounded-lg shadow-sm cursor-pointer"
                            onClick={startSearch.toggle}
                          >
                            <span className="self-center pl-2 mr-auto font-medium">
                              Start search
                            </span>
                            <Button
                              color="dark"
                              rounded="lg"
                              className="w-9 h-9"
                            >
                              <FontAwesomeIcon icon={faSearch} />
                            </Button>
                          </Flex>
                        </div>
                      )}
                      {startSearch.opened && (
                        <div className="flex-none flex-grow pb-2 pl-5">
                          <div className="w-full mx-auto mt-2 border xl:w-9/12 rounded-xl">
                            <SearchFormContainer
                              component={CreateSearchBar}
                              externalProps={{
                                popDownIdPrefix: 'nav',
                                locationPlaceholder,
                              }}
                            />
                          </div>
                        </div>
                      )}
                    </Flex>
                  </div>
                </Portal>
              )}
            </CreateSearchContextProvider>
          </section>
          {extension} */}
        </Flex>
      </ShowIf>
    </div>
  );
};
export default PageBanner;
