import React from 'react';
import { Flex } from '../../../../../flayr-components';

interface ISearchBtnProps {
  loading?: boolean;
  onSubmit?: (e: any) => void;
}

const SearchBtn: React.FC<React.PropsWithChildren<ISearchBtnProps>> = ({ onSubmit, loading }) => {
  return (
    <Flex className="relative z-10 self-center h-16 px-2 pr-3 -ml-1 rounded-r-xl">
      <button
        className={`
          text-white text-sm leading-[1.5em] bg-primary font-semibold
          px-7
          h-11
          rounded-full
          flex items-center
      `}
        onClick={onSubmit}
      >
        Search {loading && <Spin />}
      </button>
    </Flex>
  );
};
export default SearchBtn;

const Spin: React.FC<React.PropsWithChildren<any>> = () => {
  return (
    <svg
      className="w-5 h-5 ml-3 text-white animate-spin"
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      viewBox="0 0 24 24"
    >
      <circle
        className="opacity-25"
        cx="12"
        cy="12"
        r="10"
        stroke="currentColor"
        strokeWidth="4"
      />
      <path
        className="opacity-75"
        fill="currentColor"
        d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
      />
    </svg>
  );
};
