import * as React from 'react';
import classnames from 'classnames';

interface IShowIfProps {
  condition?: any;
  elseTemplate?: React.ReactNode;
  hideUsingCss?: boolean;
}
const ShowIf: React.FC<React.PropsWithChildren<IShowIfProps>> = ({
  condition,
  children,
  elseTemplate = null,
  hideUsingCss,
}) => {
  if (hideUsingCss) {
    return (
      <div className={classnames({ hidden: !condition })}>
        {children}
        <div className={classnames({ hidden: condition })}>{elseTemplate}</div>
      </div>
    );
  }
  if (!condition) {
    return elseTemplate ? <>{elseTemplate}</> : null;
  }
  return <>{children}</>;
};

export { ShowIf };
