import React from 'react';
import classnames from 'classnames';
import { ShowIf } from '../ShowIf/ShowIf';
import { IDropShadow } from '../types/dropShadow';
import {
  getRoundedClassNames,
  getShadowClassNames,
} from '../utils/component-library-utils';
import { IRoundedCorners, TRoundedCorners } from '../types/roundedCorners';
import { TSpacing } from '../types/spacing';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { getFontAwesomeIconArray } from '../utils';

export interface IBoxProps extends IDropShadow, IRoundedCorners {
  className?: string;
  width?: string;
  square?: boolean;
  center?: boolean;
  bg?: string;
  padding?: string;
  icon?: React.ReactNode | string;
  text?: React.ReactNode | string;
  round?: TRoundedCorners;
  id?: string;
  data?: any;
  onClick?: (e?: any, id?: string, data?: any) => void;
}

const Box: React.FC<React.PropsWithChildren<IBoxProps>> = ({
  className = '',
  bg = 'gray-100',
  width = 'full',
  padding = 'p-3',
  children,
  onClick,
  square = true,
  round = true,
  shadow,
  center,
  icon,
  text,
  id,
  data,
}) => {
  function handleClick(e) {
    onClick?.(e, id, data);
  }

  return (
    <div
      className={classnames('relative', `w-${width}`)}
      style={square ? { paddingTop: '100%' } : undefined}
      onClick={handleClick}
      id={id}
    >
      <div
        className={classnames(
          className,
          `bg-${bg}`,
          padding,
          getRoundedClassNames(round),
          getShadowClassNames(shadow),
          {
            'absolute top-0 left-0 w-full h-full': square,
            'flex items-center justify-center text-center':
              center || !!icon || !!text,
          }
        )}
      >
        {(!!icon || !!text) && (
          <div>
            <div>
              <ShowIf condition={typeof icon === 'string'} elseTemplate={icon}>
                <FontAwesomeIcon
                  icon={getFontAwesomeIconArray(icon as string)}
                />
              </ShowIf>
            </div>
            <div>{text}</div>
          </div>
        )}
        {children}
      </div>
    </div>
  );
};
export { Box };
