import React from 'react';
import classnames from 'classnames';

export interface IFlexProps extends React.HTMLAttributes<HTMLDivElement> {
  x?: 'start' | 'center' | 'end' | 'around' | 'between';
  y?: 'start' | 'center' | 'end' | 'stretch';
  wrap?: boolean | 'reverse';
  center?: boolean;
  column?: boolean;
}
const Flex: React.FC<React.PropsWithChildren<IFlexProps>> = ({
  children,
  className = '',
  x,
  y,
  wrap,
  style = {},
  column,
  center,
  onClick,
  ...props
}) => {
  return (
    <div
      {...props}
      onClick={onClick}
      className={classnames('flex', className, {
        'justify-start': x === 'start' || !x,
        'justify-center': x === 'center' || center,
        'justify-end': x === 'end',
        'justify-around': x === 'around',
        'justify-between': x === 'between',

        'flex-col': column,

        'items-start': y === 'start',
        'items-center': !y || y === 'center' || center,
        'items-end': y === 'end',
        'items-stretch': y === 'stretch',

        'flex-wrap': wrap,
        'flex-no-wrap': wrap === false,
        'flex-wrap-reverse': wrap === 'reverse',
      })}
      style={style}
    >
      {children}
    </div>
  );
};

export { Flex };
