import React from 'react';
import ArrayMap from '../../lib/ArrayMap/ArrayMap';
import { debug } from '../../utils';
import CheckAvailabilityFormInput from '../CheckAvailabillityFormInput/CheckAvailabilityFormInput';
import { formFields } from './customerDetailsFormFields';

interface IAvailabilityFormProps {
  value?: any;
  onChange?: (e?: any) => void;
  fields?: string[];
}

const AvailabilityForm: React.FC<React.PropsWithChildren<React.PropsWithChildren<IAvailabilityFormProps>>> = ({
  value,
  onChange,
  fields = ['name', 'email', 'event', 'mobile'],
}) => {
  return (
    <ArrayMap
      items={formFields.filter((field) => fields.includes(field?.name))}
      render={(field) => (
        <>
          {!!field.note && (
            <p className="mb-1 leading-snug text-gray-800">{field.note}</p>
          )}
          <div className="">
            <div className="text-sm">{field.label}</div>
            <CheckAvailabilityFormInput
              field={field}
              formValue={value || ''}
              onChange={onChange}
            />
          </div>
        </>
      )}
    />
  );
};
export default AvailabilityForm;
