import {
  SET_PROVIDER_PROFILE_ITEM,
  UPDATE_PROVIDER_PROFILE_ITEM_STATUS,
} from '../actions/types';
import { IOffer } from '../types/quote/offer';

const initialState: {
  [profileId: string]: IOffer;
} = {};

export function providerProfilesReducer(
  state = initialState,
  { type, payload }
) {
  switch (type) {
    case SET_PROVIDER_PROFILE_ITEM:
      return {
        ...state,
        ...payload,
      };
    case UPDATE_PROVIDER_PROFILE_ITEM_STATUS:
      const { field, status } = payload;
      const existingValue = state[field];
      const newValue = {
        ...existingValue,
        offer: { ...existingValue.offer, status },
      };
      return {
        ...state,
        [field]: newValue,
      };
    default:
      return state;
  }
}
