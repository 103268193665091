import React from 'react';
// removed this because it was making the component too heavy
// import mapboxgl from 'mapbox-gl';
import { ComponentsContext } from '../../contexts/componentsContext';
export interface ILayer {
  type: 'circle' | 'marker';
  fill?: string;
  fillOpacity?: string;
  border?: string;
  borderOpacity?: string;
  radius?: number;
  center?: number[];
  dotOnCenter?: boolean;
  dotFill?: string;
  element?: { tag: string; content?: string; className?: string };
}

interface IMapProps {
  center?: number[];
  id: string;
  zoom?: number;
  width?: number | string;
  height?: number | string;
  layers?: ILayer[];
  className?: string;
  apiKey?: string;
}

const MapboxMap: React.FC<React.PropsWithChildren<IMapProps>> = ({
  center = [0, 0],
  id,
  zoom = 9,
  width = '100%',
  height = 400,
  layers = [],
  className,
  apiKey = '',
}) => {
  const { mapbox } = React.useContext(ComponentsContext);
  const [map, setMap] = React.useState<any>();
  const [mapLoaded, setMapLoaded] = React.useState<boolean>(false);
  const [options, setOptions] = React.useState<any>(undefined);

  const [markers, setMarkers] = React.useState<any[]>([]);

  React.useEffect(() => {
    setOptions({ ...options, zoom, center });
  }, [zoom, id, center]);

  React.useEffect(() => {
    if (!window['mapboxgl']) {
      const script = document.createElement('script');
      script.src = 'https://api.mapbox.com/mapbox-gl-js/v2.0.1/mapbox-gl.js';
      document.body.appendChild(script);
      const link = document.createElement('link');
      link.href = 'https://api.mapbox.com/mapbox-gl-js/v2.3.0/mapbox-gl.css';
      link.rel = 'stylesheet';
      document.querySelector('head')?.appendChild(link);
      setMapLoaded(true);
    } else {
      setMapLoaded(true);
    }
  }, []);

  React.useEffect(() => {
    mapLoaded && mapbox?.accessToken && loadMarkers();
  }, [options, mapLoaded, mapbox?.accessToken, apiKey]);

  function loadMarkers() {
    let mapboxgl = window['mapboxgl'];
    const accessToken = mapbox?.accessToken || apiKey;
    if (!accessToken || !mapboxgl) {
      setTimeout(loadMarkers, 100);
    } else {
      if (mapboxgl) {
        if (accessToken && options) {
          mapboxgl.accessToken = accessToken;
          const Map = mapboxgl.Map;
          const map = new Map({
            container: id,
            style: 'mapbox://styles/mapbox/streets-v11',
            ...options,
          });
          setMap(map);
        }

        return () => {
          markers?.length && markers.map((m) => m.remove());
          setMarkers([]);
          map?.remove();
          setMap(undefined);
        };
      }
    }
    return () => {};
  }

  // create layers
  React.useEffect(() => {
    if (map && layers?.length) {
      const markers = layers.map(getLayer);
      setMarkers(markers);
    }
  }, [map, layers]);

  React.useEffect(() => {
    if (map) {
      markers.map((m) => m.addTo(map));
    }
    return () => {
      markers?.length && markers.map((m) => m.remove());
    };
  }, [markers]);

  return <div className={className} id={id} style={{ width, height }} />;
};
export { MapboxMap };

function getLayer(layer) {
  return getMarker(layer);
}
function getMarker({ center, element }: ILayer) {
  let mapboxgl = window['mapboxgl'];
  let marker;
  if (element) {
    const el = document.createElement(element.tag);
    el.innerHTML = element.content || '';
    element.className?.split?.(' ').map((cl) => el.classList.add(cl));
    marker = new mapboxgl.Marker(el);
  } else {
    marker = new mapboxgl.Marker({ color: '#f35530', scale: 0.7 });
  }
  marker.setLngLat(center);
  return marker;
}
