// loader
export const SET_LOADER = 'SET_LOADER';

// user
export const SET_USER = 'SET_USER';
export const RESET_USER = 'RESET_USER';

// stores
export const SET_STORES = 'SET_STORES';

// categories
export const SET_CATEGORIES = 'SET_CATEGORIES';
export const ADD_CATEGORY = 'ADD_CATEGORY';

// provider
export const SET_PROVIDER = 'SET_PROVIDER';

// auth
export const LOGOUT = 'LOGOUT';

// signup category
export const SET_SIGNUP_CATEGORY = 'SET_SIGNUP_CATEGORY';

// requests
export const SET_REQUESTS = 'SET_REQUESTS';
export const REMOVE_REQUEST = 'REMOVE_REQUEST';

// quotes
export const SET_QUOTE_DATA = 'SET_QUOTE_DATA';
export const SET_QUOTE_DATA_FILTER_BY = 'SET_QUOTE_DATA_FILTER_BY';
export const RESET_QUOTE_DATA = 'RESET_QUOTE_DATA';
export const APPEND_REQUEST_TO_QUOTE_DATA = 'APPEND_REQUEST_TO_QUOTE_DATA';

// provider profiles
export const SET_PROVIDER_PROFILE_ITEM = 'SET_PROVIDER_PROFILE_ITEM';
export const UPDATE_PROVIDER_PROFILE_ITEM_STATUS =
  'UPDATE_PROVIDER_PROFILE_ITEM_STATUS';
