import React from 'react';

interface IObjectKeysMapProps {
  object?: any;
  render?: (key, value) => React.ReactNode;
}

const ObjectKeysMap: React.FC<React.PropsWithChildren<IObjectKeysMapProps>> = ({ object, render }) => {
  if (!object || typeof object !== 'object') {
    return null;
  }
  return (
    <>
      {Object.keys(object).map((key) => (
        <React.Fragment key={key}>{render?.(key, object[key])}</React.Fragment>
      ))}
    </>
  );
};
export default ObjectKeysMap;
