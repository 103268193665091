import React from 'react';

export default function useSteps<T = string>(
  allSteps: T[],
  startIndex = 0
): {
  next: () => void;
  prev: () => void;
  setStep: (step: T) => void;
  setSteps: (step: T[]) => void;
  step: T | '';
  index: number;
  steps: T[];
  isFirstStep: boolean;
  isLastStep: boolean;
  nextStep?: { index: number; step: string };
  prevStep?: { index: number; step: string };
} {
  const [steps, setSteps] = React.useState<any>(allSteps);

  const [stepData, setStepData] = React.useState<{
    step: T | '';
    index: number;
  }>({ step: '', index: -1 });

  React.useEffect(() => {
    setStepData({
      step: steps[startIndex],
      index: startIndex,
    });
  }, [steps]);

  function getNextOrPrevStep(next: 'next' | 'prev') {
    const addOrSubtract = (next === 'next' && 1) || -1;
    const index = stepData.index + 1 * addOrSubtract;
    const step = steps[index];
    return {
      step,
      index,
    };
  }

  function next() {
    const nextStepData = getNextOrPrevStep('next');
    if (nextStepData.step) {
      setStepData(nextStepData);
    }
  }

  function prev() {
    const prevStepData = getNextOrPrevStep('prev');
    if (prevStepData.step) {
      setStepData(prevStepData);
    }
  }

  function setStep(step: T) {
    if (steps.includes(step)) {
      setStepData({ step, index: steps.indexOf(step) });
    }
  }

  return {
    next,
    prev,
    setStep,
    setSteps,
    steps,
    isFirstStep: stepData.index === 0,
    isLastStep: stepData.index === steps.length - 1,
    nextStep: getNextOrPrevStep('next'),
    prevStep: getNextOrPrevStep('prev'),
    ...stepData,
  };
}
