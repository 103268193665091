import React from 'react';
import './Spinner.css';
import classnames from 'classnames';

interface ISpinnerProps {
  fullScreen?: boolean;
  showing: boolean;
  className?: string;
}
const Spinner: React.FC<React.PropsWithChildren<ISpinnerProps>> = ({
  fullScreen,
  showing,
  className = '',
}) => {
  if (!showing) {
    return null;
  }
  return (
    <div
      className={classnames(
        'Spinner flex items-center justify-center',
        className,
        {
          'fixed z-30 inset-0 bg-white bg-opacity-50': fullScreen,
        }
      )}
    >
      <div className="lds-spinner">
        <div />
        <div />
        <div />
        <div />
        <div />
        <div />
        <div />
        <div />
        <div />
        <div />
        <div />
        <div />
      </div>
    </div>
  );
};

export { Spinner };
