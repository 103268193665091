import { faCheckCircle, faTrashAlt } from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import { Button } from '../Button/Button';
import { Flex } from '../Flex/Flex';
import { HighlightedText } from '../HighlightedText/HighlightedText';
import { Input } from '../Input/Input';
import { ListItem } from '../ListItem/ListItem';
import { ShowIf } from '../ShowIf/ShowIf';

export interface IListInputProps {
  value?: string[];
  input?: string;
  onChange?: (e?: any) => void;
  onInputChange?: (e?: any) => void;
  placeholder?: string;
  options?: string[];
  className?: string;
}

export const ListInput: React.FC<React.PropsWithChildren<IListInputProps>> = ({
  value = [],
  onChange,
  input = '',
  onInputChange,
  placeholder,
  options = [],
  className,
}) => {
  const [userInput, setUserInput] = React.useState<string>('');

  const [suggestions, setSuggestions] = React.useState<string[]>([]);

  React.useEffect(() => {
    if (userInput !== input) {
      setUserInput(input);
    }
  }, [input]);

  React.useEffect(() => {
    if (input !== userInput) {
      onInputChange?.(userInput);
    }
  }, [userInput]);

  function handleInputChange(e) {
    const { value } = e.target;
    setUserInput(value);
  }

  React.useEffect(() => {
    getSuggestions(userInput);
  }, [userInput]);

  function getSuggestions(value) {
    if (!value) {
      return setSuggestions([]);
    }
    const suggestions = options.filter(
      (opt) =>
        !value.includes(opt) && opt.toLowerCase().includes(value.toLowerCase())
    );

    setSuggestions(suggestions);
  }

  function handleAdd() {
    if (userInput && !value.includes(userInput)) {
      onChange?.([userInput, ...value]);
    }
    clearInput();
  }

  function handleDelete(item) {
    onChange?.(value.filter((val) => val !== item));
  }

  function clearInput() {
    setUserInput('');
  }

  function selectOption(newOption) {
    if (!value.includes(newOption)) {
      onChange?.([newOption, ...value]);
    }
    clearInput();
  }

  function handleKeyUp(e) {
    switch (e.key) {
      case 'Enter':
        e.stopPropagation();
        handleAdd();
        return false;
      case 'Escape':
        e.stopPropagation();
        clearInput();
        return false;
    }
    return true;
  }

  function handleOptionClick(_e, _id, opt) {
    selectOption(opt);
  }

  return (
    <div className={className}>
      <Flex>
        <div className="relative flex-grow">
          <Input
            value={userInput}
            onChange={handleInputChange}
            clearable
            onClear={clearInput}
            size="lg"
            mb="0"
            placeholder={placeholder}
            inputClassName="md:bg-white"
            onKeyUp={handleKeyUp}
          />
          <ShowIf condition={suggestions.length}>
            <div className="absolute z-10 w-full p-3 overflow-y-auto bg-white rounded-b shadow-lg top-full max-h-64">
              {suggestions.map((opt) => (
                <Button block clear data={opt} onClick={handleOptionClick}>
                  <span className="w-full text-left">
                    <HighlightedText text={opt} highlight={userInput} />
                  </span>
                </Button>
              ))}
            </div>
          </ShowIf>
        </div>
        <Flex className="pl-4 my-3">
          <Button clear size="sm" color="hot-pink" onClick={handleAdd}>
            <FontAwesomeIcon icon={faCheckCircle} className="text-2xl" />
          </Button>
        </Flex>
      </Flex>
      <section className="mt-5 overflow-y-auto" style={{ maxHeight: '50vh' }}>
        {value.map((item) => (
          <ListItem
            key={item}
            leftIcon={
              <Button
                clear
                color="hot-pink"
                onClick={() => handleDelete(item)}
                size="sm"
              >
                <FontAwesomeIcon icon={faTrashAlt} className="text-xl" />
              </Button>
            }
            title={item}
            rightIcon={null}
          />
        ))}
      </section>
    </div>
  );
};
