import dayjs from 'dayjs';
import { IAddress } from '../types/location/address';
import { ICategory } from '../types/category';
import { ILocation } from '../types/location';
import { IMapboxLocation } from '../types/mapboxLocation';
import { IService } from '../types/service';
import { ISignupService } from '../types/signupService';
import { IValueBasedOnCountry } from '../types/valueBasedOnCountry';

export function hasAtLeastOneServiceEnabled(services) {
  return services?.some((serv) => serv.status === 'active');
}

export function hasPricingForAtLeastOneService(services?: IService[]) {
  const hasPricing = services?.some((service) =>
    service.services.some(
      (serv) =>
        serv.providerServices?.status === 'active' &&
        serv.providerServices?.cost
    )
  );
  return hasPricing;
}

export function serviceActiveAndHasPricing(services?: ISignupService[]) {
  if (!services) {
    return false;
  }
  const activeItems = services.filter((ser) => ser.status === 'active');
  return !activeItems.some((item) => !item.cost);
}

export function lastStepAndHasPricing(
  services?: { [type: string]: ISignupService[] },
  serviceTypes?: string[],
  currentServiceType?: string
) {
  if (!services || !serviceTypes || !currentServiceType) {
    return false;
  }
  const isLastStep =
    serviceTypes.indexOf(currentServiceType) === serviceTypes.length - 1;
  if (isLastStep) {
    return Object.values(services).some((service) =>
      service.some((ser) => ser.cost > 0)
    );
  }
  return true;
}

export function hasActiveService(services: IService[]) {
  return services.some((service) =>
    service.services.some((serv) => serv.providerServices.status === 'active')
  );
}

function updateServices(services, serviceToUpdate, newValue) {
  // the structure is
  // services [
  //   {
  //     type: {name},
  //     services: [{type: name, services: [
  //            {id, label, provierServices: {cost,status } <== this is what we want to update}
  //      ]}]
  //   }
  // ]

  // find the service that matches servieToUpdate by name
  return services.map((ser) => {
    if (ser.type.name === serviceToUpdate.type.name) {
      return {
        ...ser,
        services: ser.services.map((innerServices) => {
          if (innerServices.id === newValue.service) {
            return {
              ...innerServices,
              providerServices: {
                ...(innerServices.providerServices || {}),
                ...newValue,
              },
            };
          }
          return innerServices;
        }),
      };
    }
    return ser;
  });
}

export function updateServiceInCategories(
  categories: ICategory[],
  categoryId: string,
  selectedService: IService,
  updatedService: any
) {
  // structure is:
  // categories[
  //    services: [
  //        {type:{name}, services:[
  //              {id, label, provierServices: {cost,status } <== this is what we want to update}
  //           ]}
  //    ]
  // ]
  return categories.map((cat) => {
    // find the matching categoryId, then update it
    if (cat.id === categoryId) {
      return {
        ...cat,
        services: updateServices(cat.services, selectedService, updatedService),
      };
    }
    return cat;
  });
}

export const clearedLocationData: ILocation = {
  address: {
    country: '',
    countryCode: '',
    placeName: '',
  },
  coordinates: [],
};

export function getAddress(location: IMapboxLocation) {
  const data: IAddress = {
    country: '',
    countryCode: '',
    placeName: location.place_name,
  };

  try {
    location.context?.map((ctx) => {
      const type = ctx.id.split('.')[0];
      const value = ctx.text;
      data[type] = value;
      if (type === 'country') {
        data.countryCode = ctx.short_code;
      }
      if (type === 'region') {
        data.regionCode = ctx.short_code.split('-')[1];
      }
      return null;
    });
    data[location.place_type[0]] = location.text;

    return data;
  } catch (error) {
    return {
      country: '',
      countryCode: '',
      placeName: location.place_name,
    };
  }
}

export function getValueBasedOnCountry<T>(
  item: IValueBasedOnCountry<T> | string,
  country = 'au'
): T | string {
  if (typeof item === 'object') {
    return item[country];
  }
  return item;
}

export function convertMapboxLocationToLocation(
  data: IMapboxLocation
): ILocation {
  if (!data) {
    return getClearedLocation();
  }
  return {
    type: 'Point',
    address: getAddress(data),
    coordinates: data.center,
  };
}

export function getClearedLocation(): ILocation {
  return {
    type: 'Point',
    address: { placeName: '' },
    coordinates: [],
  };
}

export function getTravelTypeFromQuery(query: any) {
  return { mobileOrStudio: query.mobileOrStudio || 'mobile' };
}

export function getSortDataFromQuery(query: any) {
  const mapping = {
    distance: 'distanceInMeters',
    price: 'pricing.total',
    reviews: '-review.averageScore',
  };
  const fields = Object.keys(mapping);
  if (query.sort && fields.includes(query.sort)) {
    return {
      sort: mapping[query.sort],
    };
  }
  return {
    sort: 'pricing.total,-review.averageScore',
  };
}

export function getPaginationDataFromQuery(query: any) {
  const { page = 1, limit = 20 } = query;
  return {
    page,
    limit,
  };
}

export function isOfferExpired(expiryDate) {
  return dayjs().isAfter(dayjs(expiryDate));
}
