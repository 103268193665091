import React from 'react';
import './RangeSlider.css';

interface RangeSliderProps {
  min?: number;
  max?: number;
  name?: string;
  value?: number;
  step?: number;
  id?: string;
  className?: string;
  onChange?: (e?: any) => void;
}

const RangeSlider: React.FC<React.PropsWithChildren<RangeSliderProps>> = ({
  id,
  min = 1,
  max = 100,
  name,
  value,
  step = 1,
  className = '',
  onChange,
}) => {
  const percentage = (((value || 0) - min) / (max - min)) * 100;
  const style = {
    '--value': `${percentage}%`,
  } as React.CSSProperties;
  return (
    <div className={'RangeSlider ' + className}>
      <input
        style={style}
        className="block"
        type="range"
        id={id}
        min={min}
        max={max}
        value={value}
        step={step}
        name={name}
        onChange={onChange}
      />
    </div>
  );
};
export { RangeSlider };
