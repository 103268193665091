import React from 'react';

interface IDeferLoadingProps {
  component: React.ComponentType<React.PropsWithChildren<unknown>>;
  delay?: number;
  externalProps?: any;
}

const DeferLoading: React.FC<React.PropsWithChildren<IDeferLoadingProps>> = ({
  component: Component,
  delay = 0,
  externalProps = {},
}) => {
  const [deferred, setDeferred] = React.useState<boolean>(false);
  React.useEffect(() => {
    if (!!Component) {
      setTimeout(() => {
        setDeferred(true);
      }, delay);
    }
  }, [delay]);

  return (deferred && <Component {...externalProps} />) || null;
};
export default DeferLoading;
