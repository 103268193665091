import { Flex } from '../../flayr-components/Flex/Flex';
import classNames from 'classnames';
import React from 'react';

interface IScrollerProps {
  className?: string;
  innerContainerClassName?: string;
  scrollerId?: string;
  disableMarginOffset?: boolean;
  onScroll?: (e: any) => void;
}

const Scroller: React.FC<React.PropsWithChildren<IScrollerProps>> = ({
  className = '',
  children,
  innerContainerClassName = '',
  scrollerId,
  disableMarginOffset,
  onScroll,
}) => {
  return (
    <div className={className}>
      <div
        className="w-full px-2 overflow-x-auto md:pb-2"
        id={scrollerId}
        onScroll={onScroll}
      >
        <div
          className={classNames('w-full', { '-mx-2': !disableMarginOffset })}
        >
          <Flex
            className={`w-full ${innerContainerClassName}`}
            wrap={false}
            y="start"
          >
            {children}
            <div className="invisible">|</div>
          </Flex>
        </div>
      </div>
    </div>
  );
};
export default Scroller;
