import React from 'react';
import { Link } from 'react-router-dom';
import { Button } from '../../flayr-components';

interface ICookieConsentProps {}

const CookieConsent: React.FC<React.PropsWithChildren<React.PropsWithChildren<ICookieConsentProps>>> = ({}) => {
  const [cookieConsent, setCookieConsent] = React.useState<string>('');

  React.useEffect(() => {
    let cookieConsent = localStorage.getItem('cookieConsent');
    if (!cookieConsent) {
      cookieConsent = 'pending';
    }
    setCookieConsent(cookieConsent);
  }, []);

  const handleCookieConsent = () => {
    setCookieConsent('accepted');
    localStorage.setItem('cookieConsent', 'accepted');
  };

  return (
    (cookieConsent === 'pending' && (
      <div className="fixed bottom-0 right-0 z-30 p-2 text-xs lg:p-6 lg:w-96">
        <div className="w-full p-4 bg-white shadow-xl rounded-2xl leading-[1.5em]">
          <div className="mb-2 text-sm font-semibold text-center">
            Our Website Uses Cookies
          </div>
          <p>
            We use cookies and similar technologies to enhance your experience
            on our website. By using our website, you agree to our use of
            cookies. <br />
            For full details see our{' '}
            <Link
              className="underline text-primary"
              to="/content/privacy-policy"
              target="__blank"
            >
              Privacy Policy
            </Link>
            .
          </p>
          <div className="flex justify-end mt-4">
            <Button onClick={handleCookieConsent} color="hot-pink" round>
              <span className="text-sm">Accept and Close</span>
            </Button>
          </div>
        </div>
      </div>
    )) ||
    null
  );
};
export default CookieConsent;
