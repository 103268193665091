import classNames from 'classnames';
import React from 'react';

interface ILogoNewProps {
  sizeClassName?: string;
  className?: string;
}

const LogoNew: React.FC<React.PropsWithChildren<ILogoNewProps>> = ({
  sizeClassName = 'text-2xl',
  className = '',
}) => {
  return (
    <div className={classNames(sizeClassName, className)}>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        x="0"
        y="0"
        version="1.1"
        viewBox="300 470 490 135"
        xmlSpace="preserve"
        style={{ width: '3em' }}
      >
        <path
          fill="currentColor"
          d="M297 596.4V483.6h65.8v3.8h-49.5v47.8h32.6v3.9h-32.7v57.4H297zM466.9 592.7v3.8h-78.5V483.6h16.3v109.1h62.2zM536.2 483.6h-3.3l-46.7 112.9h6l36.2-92.5 29.6 71.8s2.5 6.1 8.5 20.7h17.7l-48-112.9zM674 483.6l-41.2 68v44.8h-16.1v-43.7l-43.3-69.1h20.5l36.7 63.5 36.5-63.5h6.9zM725.5 483.6c10.7 0 19.3.6 25.9 2.7 6.6 1.9 12.1 4.7 16.1 9.1 4.1 4.5 6.1 9.9 6.1 16.1 0 7.2-2.7 13.6-8 18.7-5.5 5.2-13.3 9.2-23.7 10.7 4.5 2.2 12.1 5.2 22.4 19.1 0 0 8.3 11.3 18.7 36.5h-12.9c-1.3-5.2-17.9-53.6-48.4-53.6h-4.9V596.5h-16V483.6h24.7zm4.5 55.5c4.4 0 8.2-1.6 11.3-2.5 3.3-.9 6-3.1 7.8-5 2-1.9 3.6-4.4 4.7-7.4 1.3-3.1 1.7-6.7 1.7-11.1 0-7.8-1.9-13.8-5.8-18.5-3.9-4.7-12.9-6.3-25.9-6.3h-7.1v50.8H730z"
        />
      </svg>
    </div>
  );
};
export default LogoNew;
