import React from 'react';
import classnames from 'classnames';
import { IRoundedCorners } from '../types/roundedCorners';
import { IDropShadow } from '../types/dropShadow';
import { Img } from '../Img/Img';
import { Label } from '../Label/Label';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronRight } from '@fortawesome/pro-light-svg-icons';

interface IActionCardProps extends IRoundedCorners, IDropShadow {
  title?: React.ReactNode;
  subTitle?: React.ReactNode;
  titleClassName?: string;
  subTitleClassName?: string;
  hideIcon?: boolean;
  labelPosition?: 'left' | 'right';
  className?: string;
  labelColor?: 'primary' | 'success' | 'info' | 'danger';
  img?: string;
  labelClassName?: string;
  label?: string;
  bg?: string;
  padding?: string;
  onClick?: (e?: any, id?: string, data?: any) => void;
  data?: any;
  id?: string;
}

export const ActionCard: React.FC<React.PropsWithChildren<IActionCardProps>> = ({
  title,
  subTitle,
  hideIcon,
  bg = 'white',
  labelPosition = 'right',
  padding = '3',
  label,
  className = 'border-2 border-white rounded-lg shadow-lg cursor-pointer hover:border-2 hover:border-primary-1',
  labelClassName,
  labelColor,
  subTitleClassName = 'text-sm text-gray-500',
  titleClassName = 'text-xl',
  shadow,
  rounded = true,
  onClick,
  data,
  img,
  id,
  children,
}) => {
  function handleClick(e) {
    onClick?.(e, id, data);
  }

  return (
    <div
      className={classnames(className, `bg-${bg}`, `p-${padding}`, {
        shadow: shadow === true,
        [`shadow-${shadow}`]: typeof shadow === 'string',
        rounded: rounded === true,
        [`rounded-${rounded}`]: typeof rounded === 'string',
        'cursor-pointer hover:opacity-75': !!onClick,
      })}
      onClick={handleClick}
      id={id}
    >
      {label && (
        <div className="flex">
          <div className={classnames({ 'ml-auto': labelPosition === 'right' })}>
            <small>
              {' '}
              <Label
                text={label}
                color={labelColor}
                className={labelClassName}
              />
            </small>
          </div>
        </div>
      )}

      <div className="flex items-center">
        {img && (
          <div className="p-6">
            <Img src={img} round size="64x64" />
          </div>
        )}
        <div className="flex-grow pr-3">
          <div className={titleClassName}>{title}</div>
          <div className={subTitleClassName}>{subTitle}</div>
          {children}
        </div>
        {!hideIcon && <FontAwesomeIcon icon={faChevronRight} />}
      </div>
    </div>
  );
};
