import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronDown } from '@fortawesome/pro-light-svg-icons';
import classnames from 'classnames';
import React from 'react';
import { Flex } from '../Flex/Flex';
interface IAccordionProps {
  title?: React.ReactNode;
  titleClassName?: string;
  hideTitle?: boolean;
  className?: string;
  iconClassName?: string;
  hideIcon?: boolean;
  open?: boolean;
  onToggle?: (e?: any) => void;
}
export const Accordion: React.FC<React.PropsWithChildren<IAccordionProps>> = ({
  title = '',
  titleClassName = 'text-lg',
  hideTitle,
  children,
  className,
  iconClassName,
  hideIcon,
  open = false,
  onToggle,
}) => {
  const [accordionOpen, setAccordionOpen] = React.useState<boolean>(false);

  function toggle() {
    const open = !accordionOpen;
    onToggle?.(open) || setAccordionOpen(open);
  }

  React.useEffect(() => {
    setAccordionOpen(open);
  }, [open]);

  return (
    <div className={classnames(className)}>
      <Flex
        y="center"
        x="between"
        className={classnames('py-2 cursor-pointer', { hidden: hideTitle })}
        onClick={toggle}
      >
        <div className={`flex-grow ${titleClassName}`}>{title}</div>
        {!hideIcon && (
          <span
            className={classnames(
              'text-lg transform transition-transform duration-150',
              iconClassName,
              {
                'rotate-180': accordionOpen,
                'rotate-0': !accordionOpen,
              }
            )}
          >
            <FontAwesomeIcon icon={faChevronDown} />
          </span>
        )}
      </Flex>

      <div
        className={classnames('overflow-hidden', {
          'max-h-0': !accordionOpen,
        })}
      >
        <div className="p-3">{children}</div>
      </div>
    </div>
  );
};
