import classNames from 'classnames';
import React from 'react';

interface IItemContainerProps {
  label?: React.ReactNode;
  className?: string;
  itemClassName?: string;
  active?: boolean;
  name?: string;
  grow?: boolean;
  popDownIdPrefix?: string;
  firstItem?: boolean;
  lastItem?: boolean;
  icon?: React.ReactNode;
  onClick?: (e?: any) => void;
}

const ItemContainer: React.FC<React.PropsWithChildren<IItemContainerProps>> = ({
  children,
  label,
  className,
  itemClassName,
  name,
  active,
  grow,
  popDownIdPrefix,
  onClick,
  firstItem,
  lastItem,
  icon,
}) => {
  const handleClick = React.useCallback(
    (e: any) => {
      // if (!active) {
      // }
      e.stopPropagation();
      return onClick?.(name);
    },
    [name, onClick]
  );

  return (
    <div
      className={classNames(className, 'flex flex-col', {
        'flex-grow': grow,
        'flex-none': !grow,
      })}
      onClick={handleClick}
    >
      <label
        tabIndex={0}
        role="button"
        className={classNames(
          'focus:outline-none relative flex flex-col flex-grow h-full',
          itemClassName
        )}
      >
        <div
          className={classNames(
            ' bg-white hover:bg-gray-200 mr-4 flex border items-center cursor-pointer h-full',
            {
              'shadow-xl border-primary': active,
              'border-transparent': !active,
              'rounded-xl': !firstItem && !lastItem,
              'pl-6': firstItem,
            }
          )}
          style={
            ((firstItem || lastItem) && {
              borderRadius:
                (firstItem && '4rem 0.75rem 0.75rem 4rem') ||
                '.75rem 4rem 4rem .75rem',
            }) ||
            undefined
          }
        >
          <div className="flex flex-col flex-grow">
            <span
              className="flex w-full pt-1 pl-2 text-xs leading-[18px]"
              onClick={handleClick}
            >
              {label}
            </span>
            <div className="relative flex flex-col flex-grow w-full mt-2 text-sm">
              {children}
            </div>
          </div>
          {!!icon && (
            <span
              onClick={handleClick}
              className="grid w-[30px] h-[30px] transform -translate-x-3 translate-y-2 bg-[#F4F4F4] rounded-lg place-items-center"
            >
              {icon}
            </span>
          )}
        </div>
      </label>
      <div
        className="relative flex-none w-full"
        id={`${name}-popdown-${popDownIdPrefix}`}
      />
    </div>
  );
};
export default ItemContainer;
