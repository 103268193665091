import { useLocation, useHistory } from 'react-router';
import useQueryParams from './useQueryParams';
import React from 'react';

export default function useGclidTracking(
  options: {
    trackOnInit: boolean;
  } = { trackOnInit: true }
) {
  const location = useLocation();
  const { query } = useQueryParams();
  const history = useHistory();

  React.useEffect(() => {
    if (options.trackOnInit && query.gclid) {
      localStorage.setItem('gclid', query.gclid);
      pushGclidToDataLayer(query.gclid);
    }
  }, [location, options.trackOnInit]);

  function pushGclidToDataLayer(gclid) {
    if (gclid) {
      const searchParams = new URLSearchParams(location.search);
      if (!searchParams.has('gclid')) {
        searchParams.append('gclid', gclid);
        history.replace(location.pathname + '?' + searchParams.toString());
      }
      const dataLayer = window['dataLayer'] || [];
      const data = { event: 'gclidSet', gclid };
      dataLayer.push(data);
    }
  }

  return { pushGclidToDataLayer };
}
