import React, { RefObject } from 'react';
import * as ReactDom from 'react-dom';

interface PortalProps {
  target?: string;
  className?: string;
  children?: React.ReactNode;
}

const Portal: React.FunctionComponent<React.PropsWithChildren<PortalProps>> = ({
  children,
  target = '#portal-container',
  className = '',
}) => {
  const [element, setElement] = React.useState<any>();

  React.useEffect(() => {
    const el: HTMLElement | null = document.createElement('div');
    if (el) {
      el.classList.add(...`Portal ${className}`.trim().split(' '));

      if (target) {
        const targetEl = document.querySelector(target);
        targetEl?.appendChild(el);
      } else {
        document.body.appendChild(el);
      }
      setElement(el);
    }
    return () => {
      if (el) {
        try {
          if (target) {
            const targetEl = document.querySelector(target);
            targetEl?.removeChild(el);
          } else {
            document.body.removeChild(el);
          }
          setElement(null);
        } catch (error) {}
      }
    };
  }, []);

  if (!element) {
    return null;
  }
  return ReactDom.createPortal(children, element);
};
export default Portal;
