import { faCheck } from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import './Checkbox.css';

export interface ICheckboxProps {
  name?: string;
  value?: string;
  checked?: boolean;
  onChange?: (e: any) => void;
  className?: string;
  id?: string;
  readOnly?: boolean;
  size?: 'sm' | 'md' | 'lg';
}

const sizeClassNameMapping = {
  sm: 'w-4 h-4 text-xs',
  md: 'w-5 h-5 text-base',
  lg: 'w-6 h-6 text-lg',
};

const Checkbox: React.FC<React.PropsWithChildren<ICheckboxProps>> = ({
  name,
  value,
  checked,
  onChange,
  className = '',
  id,
  readOnly,
  size = 'md',
}) => {
  return (
    <label htmlFor={id} className={'Checkbox inline-flex ' + className}>
      <input
        className="hidden w-0 h-0 opacity-0 appearance-none"
        type="checkbox"
        checked={checked}
        name={name}
        value={value}
        id={id}
        onChange={onChange}
        readOnly={readOnly || !onChange}
      />
      <span
        className={`flex items-center justify-center text-white border border-gray-500 rounded ${sizeClassNameMapping[size]}`}
      >
        <FontAwesomeIcon icon={faCheck} />
      </span>
    </label>
  );
};
export { Checkbox };
