import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import { Button } from '../../flayr-components/Button/Button';
import Icon from '../Icon/Icon';

interface IIconBtnProps {
  icon?: any;
  iconClassName?: string;
  color?:
    | 'default'
    | 'primary'
    | 'secondary'
    | 'success'
    | 'warning'
    | 'danger'
    | 'dark'
    | 'black'
    | 'plain'
    | 'accent'
    | 'inherit';
  clear?: boolean;
  shadow?: any;
  className?: string;
  round?: boolean;
  rounded?: any;
  onClick?: (e?: any) => void;
}

const IconBtn: React.FC<React.PropsWithChildren<IIconBtnProps>> = ({
  icon,
  color,
  className,
  round,
  iconClassName,
  shadow,
  rounded,
  onClick,
  clear,
}) => {
  return (
    <Button
      clear={clear}
      color={color}
      round={round}
      className={className}
      shadow={shadow}
      rounded={rounded}
      onClick={onClick}
    >
      <FontAwesomeIcon icon={icon} className={iconClassName} />
      {/* <Icon icon={icon} className={iconClassName} /> */}
    </Button>
  );
};
export default IconBtn;
