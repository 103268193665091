import React from 'react';
import { Select } from '../../flayr-components/Select/Select';
import { timeMapping } from './timeMapping';

interface ITimeSelectProps {
  value?: string;
  name?: string;
  placeholder?: string;
  size?: string;
  label?: string;
  labelClassName?: string;
  id?: string;
  validation?: any[];
  className?: string;
  onChange?: (e: any) => void;
}

const TimeSelect = React.forwardRef<HTMLSelectElement, ITimeSelectProps>(
  (
    {
      name,
      value,
      placeholder,
      size,
      id,
      label = '',
      labelClassName = '',
      className,
      onChange,
      validation = [],
    },
    ref
  ) => {
    return (
      <div className="w-full">
        <label className={`block ${labelClassName}`}>{label}</label>
        <Select
          ref={ref}
          id={id}
          placeholder={placeholder}
          name={name}
          value={value}
          onChange={onChange}
          validation={validation}
          options={Object.keys(timeMapping).map((key) => ({
            label: timeMapping[key],
            value: key,
          }))}
          className={className}
          size={size}
        />
      </div>
    );
  }
);
export default TimeSelect;
