import React from 'react';
import ReviewBadge from '../../lib/ReviewBadge/ReviewBadge';
import { Avatar } from '../Avatar/Avatar';
import { Flex } from '../Flex/Flex';
import { Img } from '../Img/Img';
import { ShowIf } from '../ShowIf/ShowIf';
import { StarRating } from '../StarRating/StarRating';

interface IReviewProps {
  name: string;
  date: string;
  rating?: number;
  comments?: string;
  media?: string[];
  mediaItemWidthClassName?: string;
  reply?: IReviewProps;
  className?: string;
  onMediaClick?: (e?: any) => void;
}

export const Review: React.FC<React.PropsWithChildren<IReviewProps>> = ({
  name,
  date,
  rating,
  comments,
  media = [],
  reply,
  mediaItemWidthClassName = 'w-5/12 lg:w-2/12',
  onMediaClick,
  className = '',
}) => {
  return (
    <div className={`w-full ${className}`}>
      <AvatarSection
        name={name.split(' ')[0]}
        date={date}
        photo={(media.length && media[0]) || undefined}
        score={rating}
      />
      <Comment className="flex-grow my-5" comment={comments} />
      {/* <div className="flex-grow my-3">{comments}</div> */}
      {!!reply && (
        <div className="pl-6 mt-2 border-l-2">
          {/* <Flex>
            <Avatar className="text-base" size="8" name={reply.name} />
            <div className="px-4 ">
              <p className="mb-1">{reply.name.split(' ')[0]}</p>
              <p className="text-gray-500">{reply.date}</p>
            </div>
          </Flex> */}
          <AvatarSection name={reply.name.split(' ')[0]} date={reply.date} />
          <Comment className="flex-grow pl-4 mt-1" comment={reply.comments} />
        </div>
      )}

      {!!media.length && (
        <div className="flex w-full mt-5 space-x-5 overflow-x-auto flex-nowrap">
          {media.map((m, idx) => (
            <div
              key={m + idx}
              className={`flex-none ${mediaItemWidthClassName}`}
            >
              <Img
                src={m}
                size="square"
                roundedCorners="xl"
                shadow
                onClick={onMediaClick}
              />
            </div>
          ))}
        </div>
      )}

      {/* <ShowIf condition={media?.length}>
        <div className="w-full mt-auto overflow-hidden">
          <Flex className="w-full overflow-x-auto" wrap={false} y="start">
            <Flex className="w-full px-1" wrap={false}>
              {media.map((m, idx) => (
                <div
                  key={m + idx}
                  className={`flex-none mr-4 ${mediaItemWidthClassName}`}
                >
                  <Img
                    src={m}
                    size="square"
                    roundedCorners
                    shadow
                    onClick={onMediaClick}
                  />
                </div>
              ))}
              <div className="invisible">|</div>
            </Flex>
          </Flex>
        </div>
      </ShowIf> */}
    </div>
  );
};

function AvatarSection({ name, photo = '', date, score = 0 }) {
  return (
    <Flex>
      <Avatar
        img={photo}
        className="flex-none text-xl capitalize"
        size="14"
        name={name}
      />
      <div className="px-4">
        <p className="text-black text-lg leading-[1.5em] capitalize mb-1">
          {name}
        </p>
        <p className="text-sm text-black">{date}</p>
      </div>
      {!!score && (
        <div className="ml-auto font-medium">
          <ReviewBadge
            // fontSizeClassName="lg:text-lg"
            // starFontSizeClassName="lg:text-base"
            className="text-gray-primary"
            score={score}
          />
        </div>
      )}
    </Flex>
  );
}

function Comment({ comment, className }) {
  return (
    <div
      className={className + ' text-sm text-gray-5E lg:text-lg leading-[1.8em]'}
    >
      {comment}
    </div>
  );
}
