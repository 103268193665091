import { Dayjs } from 'dayjs';

export function getCalendarMatrix(date: Dayjs) {
  const firstDayOfMonth = date.startOf('M');
  const lastDayOfMonth = firstDayOfMonth.add(1, 'month');
  const matrix = {
    Su: [],
    Mo: [],
    Tu: [],
    We: [],
    Th: [],
    Fr: [],
    Sa: [],
  };
  let currentDate = firstDayOfMonth;

  while (!currentDate.isSame(lastDayOfMonth, 'day')) {
    const day = currentDate.format('dd');
    matrix[day].push(currentDate.format('D'));
    currentDate = currentDate.add(1, 'day');
  }
  const daysList = Object.keys(matrix);

  const firstDayOfWeek = firstDayOfMonth.format('dd');
  const lastDayOfWeek = lastDayOfMonth.subtract(1, 'day').format('dd');

  daysList.map((dayOfWeek: any) => {
    if (
      firstDayOfWeek !== 'Su' &&
      daysList.indexOf(firstDayOfWeek) > daysList.indexOf(dayOfWeek)
    ) {
      matrix[dayOfWeek].unshift(' ');
    }
    if (
      lastDayOfWeek !== 'Sa' &&
      dayOfWeek !== lastDayOfWeek &&
      daysList.indexOf(lastDayOfWeek) <= daysList.indexOf(dayOfWeek)
    ) {
      matrix[dayOfWeek].push(' ');
    }
    if (matrix[dayOfWeek].length < 6) {
      matrix[dayOfWeek].push('filler');
    }
  });

  return matrix;
}
