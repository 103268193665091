import React from 'react';

export default function useClearableState<T>(
  initialState?: T,
  useInitialStateAsResetValue = true
): [T | undefined, (e?: T) => void, () => void] {
  const [state, setState] = React.useState<T | undefined>(initialState);

  function reset() {
    const value = useInitialStateAsResetValue ? initialState : undefined;
    setState(value);
  }

  return [state, setState, reset];
}
