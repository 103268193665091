import { createStore, applyMiddleware, compose, Store } from 'redux';
import thunk from 'redux-thunk';
import rootReducer from './reducers';
import { IAppStore } from './types/appStore';

const initialState = {};

const middleware = [thunk];

const store: Store<IAppStore> = createStore(
  rootReducer,
  initialState,
  compose(
    applyMiddleware(...middleware),
    ((window as any).__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ &&
      (window as any).__REDUX_DEVTOOLS_EXTENSION_COMPOSE__()) ||
      compose
  )
);

export default store;
