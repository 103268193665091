import React from 'react';
import { Button } from '../../flayr-components/Button/Button';
import { Select } from '../../flayr-components/Select/Select';
import { ShowIf } from '../../flayr-components/ShowIf/ShowIf';
import useClearableState from '../../hooks/useClearableState';
import ObjectKeysMap from '../../lib/ObjectKeysMap/ObjectKeysMap';
import { IService } from '../../types/service';
import { IServiceType } from '../../types/serviceType';

interface IServiceSelectProps {
  serviceTypes: IServiceType[];
  onCancel?: (e?: any) => void;
  onSelect?: (e?: any) => void;
  serviceTypeLabel?: string;
  serviceLabel?: string;
  size?: string;
}

const ServiceSelect: React.FC<React.PropsWithChildren<React.PropsWithChildren<IServiceSelectProps>>> = ({
  serviceTypes = [],
  onCancel,
  onSelect,
  serviceTypeLabel = 'Select who would like the service',
  serviceLabel = 'What service would you like?',
  size,
}) => {
  const [serviceType, setServiceType, clearServiceType] = useClearableState('');

  function handleServiceTypeSelect(e) {
    setServiceType(e.target.value);
  }

  function handleServiceSelect(e) {
    clearServiceType();
    onSelect?.(e.target.value);
  }

  return (
    <div className="p-4">
      <Select
        labelClassName="text-sm"
        label={serviceTypeLabel}
        options={
          serviceTypes.map((st) => ({ label: st.label, value: st.id })) as any[]
        }
        onChange={handleServiceTypeSelect}
        size={size}
      />
      <ShowIf condition={serviceType}>
        <Select
          labelClassName="text-sm"
          label={serviceLabel}
          options={
            serviceTypes
              .find((st) => st.id === serviceType)
              ?.services.map((service) => ({
                value: service?.id,
                label: service?.label,
              })) as any[]
          }
          size={size}
          onChange={handleServiceSelect}
        />
      </ShowIf>

      <div className="text-right">
        <Button onClick={onCancel}>Cancel</Button>
      </div>
    </div>
  );
};
export default ServiceSelect;
