import classNames from 'classnames';
import React from 'react';
import { useCreateSearchContext } from '../../../../../contexts/createSearchContext';
import PlacesInput from '../../../../../lib/PlacesInput/PlacesInput';

interface ILocationInputProps {
  inputId?: string;
  onOpen?: (e?: any) => void;
  open?: boolean;
  name?: string;
  proximity?: number[];
  placeholder?: string;
  inputClassName?: string;
  onLocationsUpdate?: (e: any) => void;
}

const LocationInput: React.FC<React.PropsWithChildren<ILocationInputProps>> = ({
  inputId,
  onOpen,
  name,
  open,
  placeholder,
  proximity,
  onLocationsUpdate,
  inputClassName,
}) => {
  const { userInput, setUserInput } = useCreateSearchContext();

  const handleLocationChange = (location) => {
    setUserInput?.({ location });
  };

  const handleFocus = () => {
    if (!open) {
      onOpen?.(name);
    }
  };

  return (
    <>
      <label
        htmlFor={inputId}
        className="absolute top-0 left-0 z-10 block w-full opacity-0 cursor-pointer"
      >
        location
      </label>
      <div className="w-full px-2">
        <PlacesInput
          id={inputId}
          formatLocation
          inputClassName={classNames(
            'focus:outline-none w-full bg-transparent',
            inputClassName
          )}
          placeholder={placeholder}
          onSelect={handleLocationChange}
          value={userInput?.location?.address?.placeName as any}
          clearBtnClassName="bg-gray-300 rounded-full bg-opacity-50 transform translate-x-2 -translate-y-2"
          optionsContainerClassName="mt-4 -ml-2"
          optionsContainerStyle={{ left: 0 }}
          onFocus={handleFocus}
          fixedOptions={false}
          proximity={proximity}
          onOptionsUpdate={onLocationsUpdate}
        />
      </div>
    </>
  );
};
export default LocationInput;
