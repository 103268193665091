import {
  faChevronLeft,
  faChevronRight,
  faTimes,
} from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import classnames from 'classnames';
import React from 'react';
import { Button } from '../../flayr-components/Button/Button';
import { Flex } from '../../flayr-components/Flex/Flex';
import { Img } from '../../flayr-components/Img/Img';
import { ShowIf } from '../../flayr-components/ShowIf/ShowIf';
import useDeviceMediaQuery from '../../hooks/useDeviceMediaQuery';
import ArrayMap from '../ArrayMap/ArrayMap';
import Swipeable from '../Swipeable/Swipeable';
interface IImgGalleryModalProps {
  images: string[] | { url: string; alt: string }[];
  open?: boolean;
  arrowClassName?: string;
  className?: string;
  totalImages?: number;
  scrolling?: boolean;
  zIndex?: string;
  onClose?: (e?: any) => void;
  onNext?: (e?: any) => void;
  onPrev?: (e?: any) => void;
}

export const ImgGalleryModal: React.FC<React.PropsWithChildren<IImgGalleryModalProps>> = ({
  open,
  onClose,
  images,
  className = '',
  zIndex = '20',
  totalImages,
  onNext,
  onPrev,
  scrolling,
}) => {
  const [selectedIdx, setSelectedIdx] = React.useState<number>(0);
  const { isDesktop } = useDeviceMediaQuery();
  React.useEffect(() => {
    let html = document.querySelector('html');
    if (open) {
      html?.classList.add('overflow-hidden');
    }
    return () => {
      if (html?.classList.contains('overflow-hidden')) {
        html.classList.remove('overflow-hidden');
      }
    };
  }, [open]);

  React.useEffect(() => {
    if (open) {
      const fn = (e) => {
        switch (e.key) {
          case 'ArrowLeft':
            selectedIdx > 0 && handleNext(selectedIdx - 1);
            break;
          case 'ArrowRight':
            selectedIdx < images.length - 1 && handleNext(selectedIdx + 1);
            break;
          case 'Escape':
            onClose?.();
            break;
        }
      };
      document.addEventListener('keyup', fn);
      return () => {
        document.removeEventListener('keyup', fn);
      };
    }
  }, [open, selectedIdx, images]);

  // function handleListImgClick(e) {
  //   const id = e.id;
  //   setSelectedIdx(+id);
  // }

  function handleNext(idx) {
    onNext?.(idx);
    setSelectedIdx(idx);
  }

  function handlePrev(idx) {
    onPrev?.(idx);
    setSelectedIdx(idx);
  }

  if (!open) {
    return null;
  }

  return (
    <Flex
      className={`fixed top-0 left-0 w-full h-full ${className} z-${zIndex} `}
      column
      center
    >
      <div className="fixed top-0 left-0 flex w-full h-full bg-white" />
      <div className="sticky top-0 flex flex-col self-center w-full h-full">
        <div className="relative z-10 flex items-center self-start w-full px-4 pt-3 pb-2">
          <Button onClick={onClose} className="ml-auto lg:mr-auto">
            <FontAwesomeIcon className="mr-2" icon={faTimes} />
            Close
          </Button>
          <div className="flex-grow text-sm text-center">
            <span className="inline-block -ml-24">
              <span hidden={scrolling}>
                {selectedIdx + 1} of {totalImages || images.length}
              </span>
              <span hidden={!scrolling}>{totalImages} Total</span>
            </span>
          </div>
        </div>

        {!scrolling && (
          <ImgSlider
            images={images}
            selected={selectedIdx}
            onNext={handleNext}
            onPrev={handlePrev}
          />
        )}

        {scrolling && (
          <div className="w-full overflow-y-auto">
            <div
              className="w-11/12 py-4 mx-auto -mt-2 space-y-4 lg:space-y-5 md:py-8 lg:w-6/12"
              // style={{ columns: (isDesktop && 2) || 1 }}
            >
              <ArrayMap
                items={images}
                render={(src) => (
                  <Img
                    src={src}
                    roundedCorners
                    shadow
                    skipUnobserve
                    responsive
                    sizes="(min-width: 769px) 95vw, 50vw"
                  />
                )}
              />
            </div>
          </div>
        )}
      </div>
    </Flex>
  );
};

const ImgSlider: React.FC<React.PropsWithChildren<{
  images: string[] | { url: string; alt: string }[];
  selected: number;
  totalImages?: number;
  onPrev?: (e?: any) => void;
  onNext?: (e?: any) => void;
}>> = ({ images, selected, totalImages, onNext, onPrev }) => {
  const [currentIdx, setCurrentIdx] = React.useState<number>(0);
  const isFirst = currentIdx < 1;
  const isLast = currentIdx >= images.length - 1;

  React.useEffect(() => {
    setCurrentIdx(selected);
  }, [selected]);

  function next() {
    if (currentIdx < images.length - 1) {
      const idx = currentIdx + 1;
      setCurrentIdx(idx);
      onNext?.(idx);
    }
  }
  function prev() {
    if (currentIdx > 0) {
      const idx = currentIdx - 1;
      setCurrentIdx(idx);
      onPrev?.(idx);
    }
  }

  return (
    <Swipeable
      className="flex items-stretch flex-grow"
      onSwipedRight={prev}
      onSwipedLeft={next}
    >
      <Flex
        className="relative z-20 flex-grow w-full pt-20 mx-auto lg:self-center lg:px-10 lg:items-center md:pt-0"
        x="between"
        y="start"
      >
        <div className="hidden w-16 text-center lg:block">
          {currentIdx !== 0 && (
            <Button onClick={prev} className="w-12 h-12" round>
              <FontAwesomeIcon icon={faChevronLeft} className="text-2xl" />
            </Button>
          )}
        </div>

        {(images as any).map((img, idx) => (
          <div
            key={idx}
            className={classnames(
              { hidden: idx !== currentIdx },
              'mx-auto max-w-xl xl:max-w-2xl px-8 w-full'
            )}
          >
            <ShowIf
              condition={typeof img === 'string'}
              elseTemplate={
                <Img
                  className="bg-gray-100"
                  src={img.url}
                  alt={img.alt}
                  loadingAnimation
                />
              }
            >
              <Img className="bg-gray-100" src={img} loadingAnimation />
            </ShowIf>
          </div>
        ))}

        <div className="hidden w-16 text-center lg:block">
          {currentIdx !== (totalImages || images.length) - 1 && (
            <Button className="w-12 h-12" round onClick={next}>
              <FontAwesomeIcon icon={faChevronRight} />
            </Button>
          )}
        </div>
      </Flex>
      <div className="absolute bottom-0 left-0 z-20 flex justify-center w-full pb-10 lg:hidden">
        <Button
          onClick={prev}
          className={classnames('w-12 h-12', { invisible: isFirst })}
          round
        >
          <FontAwesomeIcon icon={faChevronLeft} className="text-2xl" />
        </Button>
        <div className="w-10"></div>
        <Button
          className={classnames('w-12 h-12', {
            invisible: isLast,
          })}
          round
          onClick={next}
        >
          <FontAwesomeIcon icon={faChevronRight} className="text-2xl" />
        </Button>
      </div>
    </Swipeable>
  );
};
