import React from 'react';
import { ShowIf } from '../../flayr-components/ShowIf/ShowIf';

interface ISingularOrPluralTextProps {
  text?: string;
  singular?: string;
  plural?: string;
  number: number;
  showWhenZero?: boolean;
}

const SingularOrPluralText: React.FC<React.PropsWithChildren<ISingularOrPluralTextProps>> = ({
  number,
  singular,
  plural,
  text,
  showWhenZero,
}) => {
  if (!number && !(showWhenZero && number >= 0)) {
    return null;
  }
  return (
    <ShowIf
      condition={!(singular && plural)}
      elseTemplate={(number > 1 && plural) || singular}
    >
      {text}
      {(number !== 1 && 's') || ''}
    </ShowIf>
  );
};
export default React.memo(SingularOrPluralText);
