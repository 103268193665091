import { RESET_USER, SET_USER } from '../actions/types';
import { IUser } from '../types/user';

const initialState: IUser = {
  id: '',
  profileId: '',
  name: '',
  mobile: '',
  email: '',
};

export function userReducer(state = initialState, { type, payload }) {
  switch (type) {
    case SET_USER:
      return { ...state, ...payload };
    case RESET_USER:
      return initialState;
    default:
      return state;
  }
}
