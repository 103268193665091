import { useDispatch, useSelector } from 'react-redux';
import {
  appendRequestToQuoteDataAction,
  resetQuoteDataAction,
  setQuoteDataAction,
  setQuoteDataFilterByAction,
} from '../actions/quoteDataActions';
import { IAppStore } from '../types/appStore';
import { IOffer } from '../types/quote/offer';

export default function useQuoteDataStore() {
  const dispatch = useDispatch();

  const quoteData = useSelector((store: IAppStore) => store.quoteData);

  const setQuoteData = (data: any) => dispatch(setQuoteDataAction(data));

  const resetQuoteData = () => dispatch(resetQuoteDataAction());

  const setQuoteFilterBy = (data: any) =>
    dispatch(setQuoteDataFilterByAction(data));

  const appendRequestToQuoteData = (data: IOffer) =>
    dispatch(appendRequestToQuoteDataAction(data));

  return {
    quoteData,
    setQuoteData,
    resetQuoteData,
    setQuoteFilterBy,
    appendRequestToQuoteData,
  };
}
