import React from 'react';
import { Button, IButtonProps } from '../../flayr-components/Button/Button';
import { Flex } from '../../flayr-components/Flex/Flex';

interface IFooterCtaProps {
  className?: string;
  leftContent?: React.ReactNode;
  rightContent?: React.ReactNode;
  btnLabel?: React.ReactNode;
  ctaDisabled?: boolean;
  ctaClassName?: string;
  ctaProps?: IButtonProps;
  containerProps?: any;
  onCtaClick?: (e?: any) => void;
}

const FooterCta: React.FC<
  React.PropsWithChildren<React.PropsWithChildren<IFooterCtaProps>>
> = ({
  className,
  leftContent,
  rightContent,
  btnLabel,
  ctaDisabled,
  ctaClassName,
  ctaProps,
  containerProps,
  onCtaClick,
}) => {
  return (
    <div
      className={`fixed bottom-0 left-0 z-10 w-full px-2 pb-8 ${
        className || ''
      }`}
      data-testid="search-wizard-footer"
    >
      <div
        className="flex p-3 bg-gray-200 bg-opacity-75 backdrop-blur-5 rounded-xl lg:mx-auto lg:w-2/3"
        {...containerProps}
      >
        <div className="pl-3">{leftContent}</div>
        <div className="px-2"></div>
        <div className="ml-auto">
          {(!!rightContent && rightContent) || (
            <Button
              disabled={ctaDisabled}
              color="hot-pink"
              round
              className={ctaClassName}
              onClick={onCtaClick}
              {...ctaProps}
            >
              {btnLabel}
            </Button>
          )}
        </div>
      </div>
    </div>
  );
};
export default FooterCta;
