import { useDispatch, useSelector } from 'react-redux';
import { setLoaderAction } from '../actions/loaderActions';
import { IAppStore } from '../types/appStore';

const useLoaderStore = () => {
  const dispatch = useDispatch();

  const loader = useSelector((store: IAppStore) => store.loader);

  const showLoader = (payload: string = '') =>
    dispatch(setLoaderAction({ showing: true, message: payload }));

  const hideLoader = () =>
    dispatch(setLoaderAction({ showing: false, message: '' }));

  return {
    loader,
    showLoader,
    hideLoader,
  };
};

export default useLoaderStore;
