export const londonLandingPageData = {
  banner: {
    title: 'Find Hair And Makeup Artists In London',
    subTitle:
      'Get the best prices from 100+ of the most reviewed Makeup Artists and Hair stylists in London. Pick from mobile stylists or salons.',
    img: 'https://cdn.flayr.life/gb/makeup-hair-styling/london/banner/makeup-hair-styling_london_banner.webp',
    alt: 'Makeup Artists in London',
    position: 'center',
    locationPlaceholder: 'Location, e.g. London',
  },
  city: 'London',
  customerReviews: [
    {
      _id: '612438c6d4e5d05e4e9242e8',
      comment:
        'Emma was great, nailed the looks we were after. Attended a wedding, Does an amazing Smokey eye. Knows what colours to use to make your eyes pop. Thank you!',
      coverImg: {
        alt: 'Customer Testimonial for Hair Stylist and Makeup Artist in London',
        src: 'https://cdn.flayr.life/gb/makeup-hair-styling/london/testimonials/makeup-hair-styling_london_612438c6d4e5d05e4e9242e8.webp',
      },
      customerName: 'Laura',
      reviewScore: 5,
    },
    {
      _id: '61243809d4e5d05e4e9242e6',
      comment:
        'I really enjoyed working with Jamie. She did a superb job in understanding my brief, but also had suggestions and recommendation on how to create the look I was after. I loved how it all turned out. Thank you Jamie!',
      coverImg: {
        alt: 'Customer Testimonial for Hair Stylist and Makeup Artist in London',
        src: 'https://cdn.flayr.life/gb/makeup-hair-styling/london/testimonials/makeup-hair-styling_london_61243809d4e5d05e4e9242e6.webp',
      },
      customerName: 'Rebecca',
      reviewScore: 5,
    },
    {
      _id: '612437c8d4e5d05e4e9242e5',
      comment:
        'I was very fortunate when Yiota accepted my request for doing my make up and hair for a Gala event on Friday night. Not only is Yiota brilliant and very professional in her art of make up & hair styling, she is also a lovely person who exudes such warmth and friendliness. I would definitely recommend Yiota to anyone who is looking for a wonderful hair and make up experience.',
      coverImg: {
        alt: 'Customer Testimonial for Hair Stylist and Makeup Artist in London',
        src: 'https://cdn.flayr.life/gb/makeup-hair-styling/london/testimonials/makeup-hair-styling_london_612437c8d4e5d05e4e9242e5.webp',
      },
      customerName: 'Clelia',
      reviewScore: 5,
    },
    {
      _id: '6124377dd4e5d05e4e9242e3',
      comment:
        'Yiota was friendly and fun to deal with. She nailed the look after I explained what I wanted for both hair and makeup. I was incredibly happy with the outcome and would easily pick her all over again!',
      coverImg: {
        alt: 'Customer Testimonial for Hair Stylist and Makeup Artist in London',
        src: 'https://cdn.flayr.life/gb/makeup-hair-styling/london/testimonials/makeup-hair-styling_london_6124377dd4e5d05e4e9242e3.webp',
      },
      customerName: 'Tania',
      reviewScore: 5,
    },
    {
      _id: '6124372ad4e5d05e4e9242e0',
      comment: 'Amazing look and so so fun',
      coverImg: {
        alt: 'Customer Testimonial for Hair Stylist and Makeup Artist in London',
        src: 'https://cdn.flayr.life/gb/makeup-hair-styling/london/testimonials/makeup-hair-styling_london_6124372ad4e5d05e4e9242e0.webp',
      },
      customerName: 'Maddy',
      reviewScore: 5,
    },
    {
      _id: '612436cdd4e5d05e4e9242de',
      comment:
        "Marlies was fantastic! Prior to the appointment Marlies' communication was wonderful. We worked on what I was hoping to achieve so there was no wasted time for either if us at the appointment. On the day Marlies was punctual, prepared, efficient and friendly. She worked with me to achieve the desired look. Her experience was evident and invaluable in that I sent her some photos prior to our appointment so she had an idea of what I wished to achieve and she took it from there. Her suggestions in making small changes to my make up for the daytime event were greatly appreciated and she included me every step of the decision making process. Should I have another event in the area I will definitely contact Marlies! Thank you Marlies, your dedication to client satisfaction and achieving a glamorous look was truly appreciated.\n",
      coverImg: {
        alt: 'Customer Testimonial for Hair Stylist and Makeup Artist in London',
        src: 'https://cdn.flayr.life/gb/makeup-hair-styling/london/testimonials/makeup-hair-styling_london_612436cdd4e5d05e4e9242de.webp',
      },
      customerName: 'Emma',
      reviewScore: 5,
    },
    {
      _id: '6124368cd4e5d05e4e9242dd',
      comment:
        "No bridezilla moments here! I was a very relaxed bride to begin with, but Louise made me feel so comfortable. I'm not really into makeup, so Louise worked with my style to create me - only a princess version. I have never felt more beautiful than I did on my wedding day. Hair, makeup - 100%. My daughter was my maid of honour, and looked absolutely stunning. I would most definitely recommend Louise - very professional, personable and experienced.",
      coverImg: {
        alt: 'Customer Testimonial for Hair Stylist and Makeup Artist in London',
        src: 'https://cdn.flayr.life/gb/makeup-hair-styling/london/testimonials/makeup-hair-styling_london_6124368cd4e5d05e4e9242dd.webp',
      },
      customerName: 'Krystyna',
      reviewScore: 5,
    },
    {
      _id: '61243660d4e5d05e4e9242dc',
      comment:
        'Rula did such an amazing job. I didn’t really know exactly what I wanted and she went above and beyond my expectations',
      coverImg: {
        alt: 'Customer Testimonial for Hair Stylist and Makeup Artist in London',
        src: 'https://cdn.flayr.life/gb/makeup-hair-styling/london/testimonials/makeup-hair-styling_london_61243660d4e5d05e4e9242dc.webp',
      },
      customerName: 'Tahnee',
      reviewScore: 5,
    },
    {
      _id: '612435f4d4e5d05e4e9242da',
      comment:
        'Jacqueline was friendly, professional and created beautiful hair and makeup for both of my daughters and my Mother. I would definitely call on Jacqueline again in the future.',
      coverImg: {
        alt: 'Customer Testimonial for Hair Stylist and Makeup Artist in London',
        src: 'https://cdn.flayr.life/gb/makeup-hair-styling/london/testimonials/makeup-hair-styling_london_612435f4d4e5d05e4e9242da.webp',
      },
      customerName: 'Natasha',
      reviewScore: 5,
    },
    {
      _id: '61243620d4e5d05e4e9242db',
      comment:
        'Amazing website and so easy to use. Such a great way to research the best artist for you. I used them for my wedding and LOVED the Hair and Makeup artist they recommended after I filled in their survey :)',
      coverImg: {
        alt: 'Customer Testimonial for Hair Stylist and Makeup Artist in London',
        src: 'https://cdn.flayr.life/gb/makeup-hair-styling/london/testimonials/makeup-hair-styling_london_61243620d4e5d05e4e9242db.webp',
      },
      customerName: 'Tiffany',
      reviewScore: 5,
    },
    {
      _id: '612435c8d4e5d05e4e9242d9',
      comment:
        "I booked my own, my mother of the bride and 3 bridesmaids hair and makeup through Flayr. The website was easy to use and prices were amazingly affordable. I got multiple quotes pretty much instantly. There's galleries to browse the artists work and reviews when you go through quotes, which is very helpful. The website is great.",
      coverImg: {
        alt: 'Customer Testimonial for Hair Stylist and Makeup Artist in London',
        src: 'https://cdn.flayr.life/gb/makeup-hair-styling/london/testimonials/makeup-hair-styling_london_612435c8d4e5d05e4e9242d9.webp',
      },
      customerName: 'Melanie',
      reviewScore: 5,
    },
    {
      _id: '612436fed4e5d05e4e9242df',
      comment:
        'Thank you Sonia for making everything so easy! You did such an amazing job on myself and making my family look so beautiful for my wedding day! I will definitely be recommending you and Flayr!',
      coverImg: {
        alt: 'Customer Testimonial for Hair Stylist and Makeup Artist in London',
        src: 'https://cdn.flayr.life/gb/makeup-hair-styling/london/testimonials/makeup-hair-styling_london_612436fed4e5d05e4e9242df.webp',
      },
      customerName: 'Natascha',
      reviewScore: 5,
    },
    {
      _id: '612437a3d4e5d05e4e9242e4',
      comment:
        'Sonia is amazing! Listened to what I wanted and delivered! I am beyond happy. Look forward to booking her again!',
      coverImg: {
        alt: 'Customer Testimonial for Hair Stylist and Makeup Artist in London',
        src: 'https://cdn.flayr.life/gb/makeup-hair-styling/london/testimonials/makeup-hair-styling_london_612437a3d4e5d05e4e9242e4.webp',
      },
      customerName: 'Nicole',
      reviewScore: 5,
    },
    {
      _id: '61243756d4e5d05e4e9242e2',
      comment:
        'Nicole was absolutely incredible! She was so friendly and lovely. She very much knew what she was doing and she nailed the looks that my mum and myself gave to her. My wedding was the most beautiful day ever and having the best hair and make-up is so important! I loved it! Thank-you so much, I would definitely recommend her!!!!! Also it’s a bonus that literally all of the products she uses smell delicious xxx',
      coverImg: {
        alt: 'Customer Testimonial for Hair Stylist and Makeup Artist in London',
        src: 'https://cdn.flayr.life/gb/makeup-hair-styling/london/testimonials/makeup-hair-styling_london_61243756d4e5d05e4e9242e2.webp',
      },
      customerName: 'Yasha',
      reviewScore: 5,
    },
    {
      _id: '61243b41d4e5d05e4e9242f0',
      comment:
        'Isabella was exceptional. She was punctual, organised and thorough. More importantly, she picked up my brief with ease and then produced an amazing outcome! Pleasure working with Isabella and highly recommend her services!\n',
      coverImg: {
        alt: 'Customer Testimonial for Hair Stylist and Makeup Artist in London',
        src: 'https://cdn.flayr.life/gb/makeup-hair-styling/london/testimonials/makeup-hair-styling_london_61243b41d4e5d05e4e9242f0.webp',
      },
      customerName: 'Natasha',
      reviewScore: 5,
    },
  ],
  events: [],
  popularPlaces: [],
  providerType: 'Makeup Artists And Hair Stylists',
  providers: [
    {
      _id: 'tahmeena60899',
      name: 'Tahmeena',
      profileImg: {
        src: 'https://cdn.flayr.life/gb/makeup-hair-styling/london/providers/makeup-hair-styling_london_tahmeena.webp',
        alt: 'London Makeup Artists And Hair Stylists Tahmeena',
      },
      coverImg: {
        _id: '60ca04d9838a090c5cdea52e',
        src: 'https://cdn.flayr.life/gb/makeup-hair-styling/london/providers/makeup-hair-styling_london_tahmeena_coverphoto.webp',
        alt: 'Hair Stylist and Makeup Artist in London',
      },
      bio: 'Hi! I’m a freelance hair and makeup artist having 5 years experience in the industry.\nI use high end makeup brands to ensure the quality of my work.\nI make sure that my client is satisfied with the service provided, that is my priority.\nI worked in a studio where I got to work with different skin tones.\nThank you ',
      reviewScore: 4.9,
      reviewCount: 17,
    },
    {
      _id: 'dayna47223',
      name: 'Dayna',
      profileImg: {
        src: 'https://cdn.flayr.life/gb/makeup-hair-styling/london/providers/makeup-hair-styling_london_dayna.webp',
        alt: 'London Makeup Artists And Hair Stylists Dayna',
      },
      coverImg: {
        _id: '60ca04d9838a090c5cdea52e',
        src: 'https://cdn.flayr.life/gb/makeup-hair-styling/london/providers/makeup-hair-styling_london_dayna_coverphoto.webp',
        alt: 'Hair Stylist and Makeup Artist in London',
      },
      bio: "Hey! My name is Dayna & I'm a freelance makeup artist. I'm looking forward to helping you out with your event!\nFully trained in the art of enhancing your natural beauty. With experience in Bridal, Bold and Soft Glam, I can customise your experience to whatever your event may be! ",
      reviewScore: 5,
      reviewCount: 13,
    },
    {
      _id: 'erin28503',
      name: 'Erin',
      profileImg: {
        src: 'https://cdn.flayr.life/gb/makeup-hair-styling/london/providers/makeup-hair-styling_london_erin.webp',
        alt: 'London Makeup Artists And Hair Stylists Erin',
      },
      coverImg: {
        _id: '60ca04d9838a090c5cdea52e',
        src: 'https://cdn.flayr.life/gb/makeup-hair-styling/london/providers/makeup-hair-styling_london_erin_coverphoto.webp',
        alt: 'Hair Stylist and Makeup Artist in London',
      },
      bio: 'Hey! I am a freelance Makeup Artist with over 7 years experience in makeup artistry, I was INGLOT’s Pro Makeup Artist for two years, and work with brands such as Urban Decay and Bperfect Cosmetics. I am available anytime for call outs and will hopefully be working from a studio in some time. ',
      reviewScore: 5,
      reviewCount: 1,
    },
    {
      _id: 'louise90831',
      name: 'Louise',
      profileImg: {
        src: 'https://cdn.flayr.life/gb/makeup-hair-styling/london/providers/makeup-hair-styling_london_louise.webp',
        alt: 'London Makeup Artists And Hair Stylists Louise',
      },
      coverImg: {
        _id: '60ca04d9838a090c5cdea52e',
        src: 'https://cdn.flayr.life/gb/makeup-hair-styling/london/providers/makeup-hair-styling_london_louise_coverphoto.webp',
        alt: 'Hair Stylist and Makeup Artist in London',
      },
      bio: 'An international makeup & hairstyling artist over 8 years. Love to enhance my clients’ face features while providing a flawless look and natural hairstyling. They can feel confident when in any occasions. My aesthetic for hair & makeup stands the test of time, and I know you will love looking back at your photos for years and years to come. ',
      reviewScore: 5,
      reviewCount: 43,
    },
    {
      _id: 'ruby87125',
      name: 'Ruby',
      profileImg: {
        src: 'https://cdn.flayr.life/gb/makeup-hair-styling/london/providers/makeup-hair-styling_london_ruby.webp',
        alt: 'London Makeup Artists And Hair Stylists Ruby',
      },
      coverImg: {
        _id: '60ca04d9838a090c5cdea52e',
        src: 'https://cdn.flayr.life/gb/makeup-hair-styling/london/providers/makeup-hair-styling_london_ruby_coverphoto.webp',
        alt: 'Hair Stylist and Makeup Artist in London',
      },
      bio: 'Ruby is a fully qualified Makeup Artist based in London. She graduated from Blanche MacDonald Centre in Canada. After completing school, she starts her journey in makeup artistry. She has years of experiences on fashion editorials, campaigns, fashion shows, and weddings in New York and London.\n\nRuby is well-experienced on creating flawless skin with minimal products and good at tailoring different looks suit different clients. \n\n\nPublications \n\nVOGUE ITALIA\n\nSCHÖN! MAGAZINE\n\nLYUN MAGAZINE BEAUTY ISSUE-FRONT COVER\n\nFashion weeks\n\nLONDON FASHION WEEK, SS2020\nVANCOUVER FASHION WEEK, AW2017\n',
      reviewScore: 4.9,
      reviewCount: 41,
    },
    {
      _id: 'dora92722',
      name: 'Dora',
      profileImg: {
        src: 'https://cdn.flayr.life/gb/makeup-hair-styling/london/providers/makeup-hair-styling_london_dora.webp',
        alt: 'London Makeup Artists And Hair Stylists Dora',
      },
      coverImg: {
        _id: '60ca04d9838a090c5cdea52e',
        src: 'https://cdn.flayr.life/gb/makeup-hair-styling/london/providers/makeup-hair-styling_london_dora_coverphoto.webp',
        alt: 'Hair Stylist and Makeup Artist in London',
      },
      bio: 'I’m a mobile celebrity makeup artist from London. I’ve been trained by Bobbi Brown, Mac and Nars and I do film, editorial, glam, drag, bridal etc. After 3 years of makeup experience, I can makeup you look and feel your absolute best! ',
      reviewScore: 0,
      reviewCount: 0,
    },
    {
      _id: 'mariya22830',
      name: 'Mariya',
      profileImg: {
        src: 'https://cdn.flayr.life/gb/makeup-hair-styling/london/providers/makeup-hair-styling_london_mariya.webp',
        alt: 'London Makeup Artists And Hair Stylists Mariya',
      },
      coverImg: {
        _id: '60ca04d9838a090c5cdea52e',
        src: 'https://cdn.flayr.life/gb/makeup-hair-styling/london/providers/makeup-hair-styling_london_mariya_coverphoto.webp',
        alt: 'Hair Stylist and Makeup Artist in London',
      },
      bio: 'I’m a fully qualified and insured professional makeup artist ',
      reviewScore: 5,
      reviewCount: 8,
    },
    {
      _id: 'ciara64121',
      name: 'Ciara',
      profileImg: {
        src: 'https://cdn.flayr.life/gb/makeup-hair-styling/london/providers/makeup-hair-styling_london_ciara.webp',
        alt: 'London Makeup Artists And Hair Stylists Ciara',
      },
      coverImg: {
        _id: '60ca04d9838a090c5cdea52e',
        src: 'https://cdn.flayr.life/gb/makeup-hair-styling/london/providers/makeup-hair-styling_london_ciara_coverphoto.webp',
        alt: 'Hair Stylist and Makeup Artist in London',
      },
      bio: "Hi everyone! My names Ciara-Faye, I'm 21 years old and I am a self taught make up artist. I've been doing make up on myself and others for 5 years and i love this job. I can create fun, colourful, glamorous make up looks as well as neutral, stunning make up looks. If you have a make up look/ hairstyle that you would like created then I am up for the job! ",
      reviewScore: 0,
      reviewCount: 0,
    },
    {
      _id: 'abirami66481',
      name: 'Abirami',
      profileImg: {
        src: 'https://cdn.flayr.life/gb/makeup-hair-styling/london/providers/makeup-hair-styling_london_abirami.webp',
        alt: 'London Makeup Artists And Hair Stylists Abirami',
      },
      coverImg: {
        _id: '60ca04d9838a090c5cdea52e',
        src: 'https://cdn.flayr.life/gb/makeup-hair-styling/london/providers/makeup-hair-styling_london_abirami_coverphoto.webp',
        alt: 'Hair Stylist and Makeup Artist in London',
      },
      bio: "Hi I'm a freelance makeup artist. I'm looking forward to helping you out with your event.",
      reviewScore: 0,
      reviewCount: 0,
    },
    {
      _id: 'athina29245',
      name: 'Athina',
      profileImg: {
        src: 'https://cdn.flayr.life/gb/makeup-hair-styling/london/providers/makeup-hair-styling_london_athina.webp',
        alt: 'London Makeup Artists And Hair Stylists Athina',
      },
      coverImg: {
        _id: '60ca04d9838a090c5cdea52e',
        src: 'https://cdn.flayr.life/gb/makeup-hair-styling/london/providers/makeup-hair-styling_london_athina_coverphoto.webp',
        alt: 'Hair Stylist and Makeup Artist in London',
      },
      bio: 'Hair & Makeup Artist covering most aspects of the industry in the highest standards. Fully qualified and mobile or studio based. Covering up to 70 miles from Southampton, travel is free of charge. ',
      reviewScore: 0,
      reviewCount: 0,
    },
    {
      _id: 'sunni23798',
      name: 'Sunni',
      profileImg: {
        src: 'https://cdn.flayr.life/gb/makeup-hair-styling/london/providers/makeup-hair-styling_london_sunni.webp',
        alt: 'London Makeup Artists And Hair Stylists Sunni',
      },
      coverImg: {
        _id: '60ca04d9838a090c5cdea52e',
        src: 'https://cdn.flayr.life/gb/makeup-hair-styling/london/providers/makeup-hair-styling_london_sunni_coverphoto.webp',
        alt: 'Hair Stylist and Makeup Artist in London',
      },
      bio: 'Hi everyone my name is Sunni. I am a Hair and Makeup artist who absolutely loves everything beauty! I am also a Stylist and have previously helped brides put together their perfect wedding look! ',
      reviewScore: 5,
      reviewCount: 1,
    },
    {
      _id: 'hajra28011',
      name: 'Hajra',
      profileImg: {
        src: 'https://cdn.flayr.life/gb/makeup-hair-styling/london/providers/makeup-hair-styling_london_hajra.webp',
        alt: 'London Makeup Artists And Hair Stylists Hajra',
      },
      coverImg: {
        _id: '60ca04d9838a090c5cdea52e',
        src: 'https://cdn.flayr.life/gb/makeup-hair-styling/london/providers/makeup-hair-styling_london_hajra_coverphoto.webp',
        alt: 'Hair Stylist and Makeup Artist in London',
      },
      bio: 'I’m a freelance makeup artist based in london and makeup is my passion 💄',
      reviewScore: 5,
      reviewCount: 5,
    },
    {
      _id: 'mia16740',
      name: 'Mia',
      profileImg: {
        src: 'https://cdn.flayr.life/gb/makeup-hair-styling/london/providers/makeup-hair-styling_london_mia.webp',
        alt: 'London Makeup Artists And Hair Stylists Mia',
      },
      coverImg: {
        _id: '60ca04d9838a090c5cdea52e',
        src: 'https://cdn.flayr.life/gb/makeup-hair-styling/london/providers/makeup-hair-styling_london_mia_coverphoto.webp',
        alt: 'Hair Stylist and Makeup Artist in London',
      },
      bio: 'A friendly , bubbly individual who has a passion for all things creative and making people feel beautiful ',
      reviewScore: 0,
      reviewCount: 0,
    },
    {
      _id: 'sumas10854',
      name: 'Sumas',
      profileImg: {
        src: 'https://cdn.flayr.life/gb/makeup-hair-styling/london/providers/makeup-hair-styling_london_sumas.webp',
        alt: 'London Makeup Artists And Hair Stylists Sumas',
      },
      coverImg: {
        _id: '60ca04d9838a090c5cdea52e',
        src: 'https://cdn.flayr.life/gb/makeup-hair-styling/london/providers/makeup-hair-styling_london_sumas_coverphoto.webp',
        alt: 'Hair Stylist and Makeup Artist in London',
      },
      bio: 'Hello, i am a make-up artist i love my job,and i am focuse to become better and better everyday, i love to be creative and share a good vibes with everyone',
      reviewScore: 0,
      reviewCount: 0,
    },
    {
      _id: 'marwa16302',
      name: 'Marwa',
      profileImg: {
        src: 'https://cdn.flayr.life/gb/makeup-hair-styling/london/providers/makeup-hair-styling_london_marwa.webp',
        alt: 'London Makeup Artists And Hair Stylists Marwa',
      },
      coverImg: {
        _id: '60ca04d9838a090c5cdea52e',
        src: 'https://cdn.flayr.life/gb/makeup-hair-styling/london/providers/makeup-hair-styling_london_marwa_coverphoto.webp',
        alt: 'Hair Stylist and Makeup Artist in London',
      },
      bio: 'Very easy going and always do what customers like ',
      reviewScore: 0,
      reviewCount: 0,
    },
  ],
  totalReviewCount: '14718',
  totalReviewScore: '4.9',
  center: {
    lat: 51.507321899999994,
    lng: -0.12764739999999997,
  },
  faqs: [
    {
      _id: '6124d67d0753c40684c70f5f',
      question: 'How much does it cost for a makeup artist?',
      answer:
        'Prices for makeup artists start from £30. You can do a simple search on Flayr and instantly see pricing, photos and verified customer reviews of multiple makeup artists. You can go to a home salon or have the makeup artist come to you.',
    },
    {
      _id: '6124d67d0753c40684c70f60',
      question: 'What is the price for makeup and hair packages?',
      answer:
        'Makeup and hair packages start from £50. On Flayr, you can instantly get quotes from multiple makeup artists and hairstylists, view their photos, profiles and verified customer reviews. You can choose to go to a home salon or have the makeup artist come to you.',
    },
    {
      _id: '6124d67d0753c40684c70f61',
      question: 'Where can I see photos of the makeup artist’s work?',
      answer:
        'Photos of the makeup artist and hairstylists work are available on their Flayr profile. In addition to photos, the profile also has a bio and verified customer reviews so you know that you can feel comfortable around the makeup artist. ',
    },
    {
      _id: '6124d67d0753c40684c70f62',
      question: 'Where can I see the products used by the makeup artist?',
      answer:
        'The full list of products used by the makeup artist appears on their Flayr profile under the heading “My Kit.” Each makeup artist has their own favourite brands that they will bring and use on the day of your appointment. ',
    },
    {
      _id: '6124d67d0753c40684c70f63',
      question: 'Can the makeup artist or hairstylist travel to my home?',
      answer:
        'Yes, you can easily pick a Flayr mobile makeup artist or hairstylist to travel to your home, office or any other location. Please enter the exact suburb e.g. Richmond, Putney to help find makeup artists that are mobile in your area.',
    },
    {
      _id: '6124d67d0753c40684c70f64',
      question:
        'How to find makeup artists and hairstylists with salons near me?',
      answer:
        'You can search for home salons near you on Flayr. Select ‘Studio’ and sort by distance to browse multiple profiles of makeup artists and hairstylists. The profiles have photos, customer reviews and bio, so you can pick the makeup artist best suited to you.',
    },
    {
      _id: '6124d67d0753c40684c70f65',
      question:
        'How far in advance should I book my hairstylist and makeup artist?',
      answer:
        'Flayr’s most popular makeup artists and hairstylists are usually booked 6 to 10 months in advance, so it is best to book early. Booking early allows you to find exactly the makeup artist and hairstylists that suits your desired look.',
    },
    {
      _id: '6124d67d0753c40684c70f66',
      question: 'How can I find makeup artists near me?',
      answer:
        'On Flayr, it is easy to find makeup artists near you. Simply do a search for your suburb and you get instantly shown prices, profiles and reviews of makeup artists that service your area. You can then pick the makeup artist that you prefer.',
    },
  ],
  state: 'England',
  type: 'makeup-hair-styling',
  createdAt: '2021-08-24T11:22:37.162Z',
  updatedAt: '2021-11-08T00:58:42.531Z',
  basePath: '/gb/makeup-hair-styling/london',
  media: [],
  breadcrumbs: [
    {
      label: 'Flayr',
      link: '/',
    },
    {
      label: 'London Makeup Artists',
      link: '/gb/makeup-hair-styling/london',
    },
  ],
  jsonLd: {
    websiteSchema: {
      '@context': 'http://schema.org',
      '@type': 'Organization',
      name: 'Flayr',
      url: 'https://www.flayr.life',
      sameAs: [
        'https://www.facebook.com/withflayr',
        'https://www.instagram.com/flayr.life',
      ],
    },
    faqSchema: {
      '@context': 'https://schema.org',
      '@type': 'FAQPage',
      mainEntity: [
        {
          '@type': 'Question',
          name: 'How much does it cost for a makeup artist?',
          acceptedAnswer: {
            '@type': 'Answer',
            text: 'Prices for makeup artists start from £30. You can do a simple search on Flayr and instantly see pricing, photos and verified customer reviews of multiple makeup artists. You can go to a home salon or have the makeup artist come to you.',
          },
        },
        {
          '@type': 'Question',
          name: 'What is the price for makeup and hair packages?',
          acceptedAnswer: {
            '@type': 'Answer',
            text: 'Makeup and hair packages start from £50. On Flayr, you can instantly get quotes from multiple makeup artists and hairstylists, view their photos, profiles and verified customer reviews. You can choose to go to a home salon or have the makeup artist come to you.',
          },
        },
        {
          '@type': 'Question',
          name: 'Where can I see photos of the makeup artist’s work?',
          acceptedAnswer: {
            '@type': 'Answer',
            text: 'Photos of the makeup artist and hairstylists work are available on their Flayr profile. In addition to photos, the profile also has a bio and verified customer reviews so you know that you can feel comfortable around the makeup artist. ',
          },
        },
        {
          '@type': 'Question',
          name: 'Where can I see the products used by the makeup artist?',
          acceptedAnswer: {
            '@type': 'Answer',
            text: 'The full list of products used by the makeup artist appears on their Flayr profile under the heading “My Kit.” Each makeup artist has their own favourite brands that they will bring and use on the day of your appointment. ',
          },
        },
        {
          '@type': 'Question',
          name: 'Can the makeup artist or hairstylist travel to my home?',
          acceptedAnswer: {
            '@type': 'Answer',
            text: 'Yes, you can easily pick a Flayr mobile makeup artist or hairstylist to travel to your home, office or any other location. Please enter the exact suburb e.g. Richmond, Putney to help find makeup artists that are mobile in your area.',
          },
        },
        {
          '@type': 'Question',
          name: 'How to find makeup artists and hairstylists with salons near me?',
          acceptedAnswer: {
            '@type': 'Answer',
            text: 'You can search for home salons near you on Flayr. Select ‘Studio’ and sort by distance to browse multiple profiles of makeup artists and hairstylists. The profiles have photos, customer reviews and bio, so you can pick the makeup artist best suited to you.',
          },
        },
        {
          '@type': 'Question',
          name: 'How far in advance should I book my hairstylist and makeup artist?',
          acceptedAnswer: {
            '@type': 'Answer',
            text: 'Flayr’s most popular makeup artists and hairstylists are usually booked 6 to 10 months in advance, so it is best to book early. Booking early allows you to find exactly the makeup artist and hairstylists that suits your desired look.',
          },
        },
        {
          '@type': 'Question',
          name: 'How can I find makeup artists near me?',
          acceptedAnswer: {
            '@type': 'Answer',
            text: 'On Flayr, it is easy to find makeup artists near you. Simply do a search for your suburb and you get instantly shown prices, profiles and reviews of makeup artists that service your area. You can then pick the makeup artist that you prefer.',
          },
        },
      ],
    },
    breadcrumbSchema: {
      '@context': 'http://schema.org',
      '@type': 'BreadcrumbList',
      itemListElement: [
        {
          '@type': 'ListItem',
          position: 1,
          name: 'Flayr',
          item: 'https://flayr.life',
        },
        {
          '@type': 'ListItem',
          position: 2,
          name: 'London Makeup Artists',
          item: 'https://flayr.life/gb/makeup-hair-styling/london',
        },
      ],
    },
    productSchema: {
      '@context': 'http://schema.org',
      '@type': 'Product',
      name: 'Flayr',
      description:
        "Here is a list of the Best Makeup Artists And Hair Stylists in London as rated by other customers. Flayr makes booking Makeup Artists And Hair Stylists as easy as booking a hotel. We are United Kingdom's favourite platform that connects customers with freelance Makeup Artists And Hair Stylists. Book today on Flayr.",
      image:
        'https://s3-ap-southeast-2.amazonaws.com/flayr-landing-page/img/favicon.ico',
      brand: {
        '@type': 'Brand',
        name: 'Flayr',
      },
      aggregateRating: {
        '@type': 'AggregateRating',
        bestRating: '5',
        worstRating: '1',
        ratingValue: '4.9',
        reviewCount: '14718',
      },
      offers: {
        lowPrice: 65,
        highPrice: 257,
        offerCount: 117,
        priceCurrency: 'AUD',
        '@type': 'AggregateOffer',
      },
    },
    localBusinessSchema: [
      {
        '@context': 'http://schema.org',
        '@type': 'LocalBusiness',
        image:
          'https://cdn.flayr.life/gb/makeup-hair-styling/london/providers/makeup-hair-styling_london_tahmeena.webp',
        name: 'Tahmeena',
        description:
          'Hi! I’m a freelance hair and makeup artist having 5 years experience in the industry.\nI use high end makeup brands to ensure the quality of my work.\nI make sure that my client is satisfied with the service provided, that is my priority.\nI worked in a studio where I got to work with different skin tones.\nThank you ',
        address: {
          addressLocality: 'London',
        },
      },
      {
        '@context': 'http://schema.org',
        '@type': 'LocalBusiness',
        image:
          'https://cdn.flayr.life/gb/makeup-hair-styling/london/providers/makeup-hair-styling_london_dayna.webp',
        name: 'Dayna',
        description:
          "Hey! My name is Dayna & I'm a freelance makeup artist. I'm looking forward to helping you out with your event!\nFully trained in the art of enhancing your natural beauty. With experience in Bridal, Bold and Soft Glam, I can customise your experience to whatever your event may be! ",
        address: {
          addressLocality: 'London',
        },
      },
      {
        '@context': 'http://schema.org',
        '@type': 'LocalBusiness',
        image:
          'https://cdn.flayr.life/gb/makeup-hair-styling/london/providers/makeup-hair-styling_london_erin.webp',
        name: 'Erin',
        description:
          'Hey! I am a freelance Makeup Artist with over 7 years experience in makeup artistry, I was INGLOT’s Pro Makeup Artist for two years, and work with brands such as Urban Decay and Bperfect Cosmetics. I am available anytime for call outs and will hopefully be working from a studio in some time. ',
        address: {
          addressLocality: 'London',
        },
      },
      {
        '@context': 'http://schema.org',
        '@type': 'LocalBusiness',
        image:
          'https://cdn.flayr.life/gb/makeup-hair-styling/london/providers/makeup-hair-styling_london_louise.webp',
        name: 'Louise',
        description:
          'An international makeup & hairstyling artist over 8 years. Love to enhance my clients’ face features while providing a flawless look and natural hairstyling. They can feel confident when in any occasions. My aesthetic for hair & makeup stands the test of time, and I know you will love looking back at your photos for years and years to come. ',
        address: {
          addressLocality: 'London',
        },
      },
      {
        '@context': 'http://schema.org',
        '@type': 'LocalBusiness',
        image:
          'https://cdn.flayr.life/gb/makeup-hair-styling/london/providers/makeup-hair-styling_london_ruby.webp',
        name: 'Ruby',
        description:
          'Ruby is a fully qualified Makeup Artist based in London. She graduated from Blanche MacDonald Centre in Canada. After completing school, she starts her journey in makeup artistry. She has years of experiences on fashion editorials, campaigns, fashion shows, and weddings in New York and London.\n\nRuby is well-experienced on creating flawless skin with minimal products and good at tailoring different looks suit different clients. \n\n\nPublications \n\nVOGUE ITALIA\n\nSCHÖN! MAGAZINE\n\nLYUN MAGAZINE BEAUTY ISSUE-FRONT COVER\n\nFashion weeks\n\nLONDON FASHION WEEK, SS2020\nVANCOUVER FASHION WEEK, AW2017\n',
        address: {
          addressLocality: 'London',
        },
      },
      {
        '@context': 'http://schema.org',
        '@type': 'LocalBusiness',
        image:
          'https://cdn.flayr.life/gb/makeup-hair-styling/london/providers/makeup-hair-styling_london_dora.webp',
        name: 'Dora',
        description:
          'I’m a mobile celebrity makeup artist from London. I’ve been trained by Bobbi Brown, Mac and Nars and I do film, editorial, glam, drag, bridal etc. After 3 years of makeup experience, I can makeup you look and feel your absolute best! ',
        address: {
          addressLocality: 'London',
        },
      },
      {
        '@context': 'http://schema.org',
        '@type': 'LocalBusiness',
        image:
          'https://cdn.flayr.life/gb/makeup-hair-styling/london/providers/makeup-hair-styling_london_mariya.webp',
        name: 'Mariya',
        description:
          'I’m a fully qualified and insured professional makeup artist ',
        address: {
          addressLocality: 'London',
        },
      },
      {
        '@context': 'http://schema.org',
        '@type': 'LocalBusiness',
        image:
          'https://cdn.flayr.life/gb/makeup-hair-styling/london/providers/makeup-hair-styling_london_ciara.webp',
        name: 'Ciara',
        description:
          "Hi everyone! My names Ciara-Faye, I'm 21 years old and I am a self taught make up artist. I've been doing make up on myself and others for 5 years and i love this job. I can create fun, colourful, glamorous make up looks as well as neutral, stunning make up looks. If you have a make up look/ hairstyle that you would like created then I am up for the job! ",
        address: {
          addressLocality: 'London',
        },
      },
      {
        '@context': 'http://schema.org',
        '@type': 'LocalBusiness',
        image:
          'https://cdn.flayr.life/gb/makeup-hair-styling/london/providers/makeup-hair-styling_london_abirami.webp',
        name: 'Abirami',
        description:
          "Hi I'm a freelance makeup artist. I'm looking forward to helping you out with your event.",
        address: {
          addressLocality: 'London',
        },
      },
      {
        '@context': 'http://schema.org',
        '@type': 'LocalBusiness',
        image:
          'https://cdn.flayr.life/gb/makeup-hair-styling/london/providers/makeup-hair-styling_london_athina.webp',
        name: 'Athina',
        description:
          'Hair & Makeup Artist covering most aspects of the industry in the highest standards. Fully qualified and mobile or studio based. Covering up to 70 miles from Southampton, travel is free of charge. ',
        address: {
          addressLocality: 'London',
        },
      },
      {
        '@context': 'http://schema.org',
        '@type': 'LocalBusiness',
        image:
          'https://cdn.flayr.life/gb/makeup-hair-styling/london/providers/makeup-hair-styling_london_sunni.webp',
        name: 'Sunni',
        description:
          'Hi everyone my name is Sunni. I am a Hair and Makeup artist who absolutely loves everything beauty! I am also a Stylist and have previously helped brides put together their perfect wedding look! ',
        address: {
          addressLocality: 'London',
        },
      },
      {
        '@context': 'http://schema.org',
        '@type': 'LocalBusiness',
        image:
          'https://cdn.flayr.life/gb/makeup-hair-styling/london/providers/makeup-hair-styling_london_hajra.webp',
        name: 'Hajra',
        description:
          'I’m a freelance makeup artist based in london and makeup is my passion 💄',
        address: {
          addressLocality: 'London',
        },
      },
      {
        '@context': 'http://schema.org',
        '@type': 'LocalBusiness',
        image:
          'https://cdn.flayr.life/gb/makeup-hair-styling/london/providers/makeup-hair-styling_london_mia.webp',
        name: 'Mia',
        description:
          'A friendly , bubbly individual who has a passion for all things creative and making people feel beautiful ',
        address: {
          addressLocality: 'London',
        },
      },
      {
        '@context': 'http://schema.org',
        '@type': 'LocalBusiness',
        image:
          'https://cdn.flayr.life/gb/makeup-hair-styling/london/providers/makeup-hair-styling_london_sumas.webp',
        name: 'Sumas',
        description:
          'Hello, i am a make-up artist i love my job,and i am focuse to become better and better everyday, i love to be creative and share a good vibes with everyone',
        address: {
          addressLocality: 'London',
        },
      },
      {
        '@context': 'http://schema.org',
        '@type': 'LocalBusiness',
        image:
          'https://cdn.flayr.life/gb/makeup-hair-styling/london/providers/makeup-hair-styling_london_marwa.webp',
        name: 'Marwa',
        description: 'Very easy going and always do what customers like ',
        address: {
          addressLocality: 'London',
        },
      },
    ],
  },
  seoTitle: 'Book Makeup Artists in London. Free Lashes ❤️',
  seoDescription:
    'Get the best prices from 100+ of the most reviewed Makeup Artists & Hairdressers in London . Pick from mobile stylists or salons.',
};
