import React from 'react';
import { IDropShadow } from '../types/dropShadow';
import classnames from 'classnames';
import { getShadowClassNames } from '../utils/component-library-utils';

interface IAlertBarProps extends IDropShadow {
  type?:
    | 'default'
    | 'primary'
    | 'secondary'
    | 'danger'
    | 'info'
    | 'warning'
    | 'success'
    | 'dark';
  title?: React.ReactNode;
  titleClassName?: string;
  subTitle?: React.ReactNode;
  subTitleClassName?: string;
  className?: string;
  backdropBlur?: boolean;
  onClick?: (e?: any) => void;
}

export const AlertBar: React.FC<React.PropsWithChildren<IAlertBarProps>> = ({
  title,
  subTitle,
  shadow,
  children,
  titleClassName = 'text-lg',
  subTitleClassName = 'text-sm',
  type = 'default',
  className,
  backdropBlur,
  onClick,
}) => {
  return (
    <div
      className={classnames(
        'block w-full px-6 py-2',
        className,
        getShadowClassNames(shadow),
        {
          'bg-gray-200': type === 'default',
          'bg-primary-1 text-white': type === 'primary',
          'bg-secondary-1 text-white': type === 'secondary',
          'bg-danger text-white': type === 'danger',
          'bg-indigo-500 text-white': type === 'info',
          'bg-orange-500 text-white': type === 'warning',
          'bg-green-500 text-white': type === 'success',
          'bg-gray-900 text-white': type === 'dark',
        },
        { 'backdrop-blur-20': backdropBlur }
      )}
      onClick={onClick}
    >
      <div className={`leading-tight ${titleClassName}`}>{title}</div>
      <div className={subTitleClassName}>{subTitle}</div>
      {children}
    </div>
  );
};
