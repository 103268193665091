import React from 'react';
import { escapeRegExp } from 'lodash';

export interface IHighlightedTextProps {
  className?: string;
  text: string;
  highlight?: string;
  highlightClassName?: string;
  textClassName?: string;
}

export const HighlightedText: React.FC<React.PropsWithChildren<IHighlightedTextProps>> = ({
  className = '',
  text = '',
  highlight = '',
  highlightClassName = 'text-black',
  textClassName = 'text-black text-opacity-50',
}) => {
  if (!highlight.trim()) {
    return <span className={textClassName}>{text}</span>;
  }

  const regex = new RegExp(`(${escapeRegExp(highlight)})`, 'gi');
  const parts = text.split(regex);
  return (
    <div className={className}>
      {parts
        .filter(part => part)
        .map((part, i) =>
          regex.test(part) ? (
            <span key={i} className={highlightClassName}>
              {part}
            </span>
          ) : (
            <span key={i} className={textClassName}>
              {part}
            </span>
          )
        )}
    </div>
  );
};
