const {
  REACT_APP_API_URL = '',
  REACT_APP_AWS_IMG_UPLOAD_URL = '',
  REACT_APP_STRIPE_KEY = '',
  REACT_APP_GOOGLE_MAPS_ACCESS_TOKEN = '',
  REACT_APP_MAPBOX_ACCESS_TOKEN = '',
  REACT_APP_ENV = '',
  REACT_APP_SITE_URL = '',
  REACT_APP_GA_ID = '',
  REACT_APP_HEAP_APP_ID,
  REACT_APP_PLACES_INPUT_AVAILABLE_COUNTRIES = 'US',
  REACT_APP_STORE_CATEGORY = '',
  REACT_APP_DEFAULT_STORE,
  REACT_APP_DEFAULT_CATEGORY,
  REACT_APP_STREAM_CHAT_API_KEY = '',
  REACT_APP_RECAPTCHA_SITE_KEY = '',
} = process.env;

const PHONE_INPUT_SUPPORTED_COUNTRIES = ['AU', 'US', 'GB'];

const PHONE_INPUT_DEFAULT_COUNTRY = 'AU';

const PLACES_INPUT_AVAILABLE_COUNTRIES =
  REACT_APP_PLACES_INPUT_AVAILABLE_COUNTRIES;

// const localApi = 'http://192.168.86.238:5000';
// const localApi = 'http://localhost:5000';
const localApi = 'https://2203-61-68-229-226.ngrok-free.app';
const useLocalApi = false;
const ENV = REACT_APP_ENV;
const API_URL =
  (ENV === 'development' && useLocalApi && `${localApi}/api`) ||
  REACT_APP_API_URL;
const AWS_IMG_UPLOAD_URL = REACT_APP_AWS_IMG_UPLOAD_URL;
const STRIPE_KEY = REACT_APP_STRIPE_KEY;
const GOOGLE_MAPS_ACCESS_TOKEN = REACT_APP_GOOGLE_MAPS_ACCESS_TOKEN;
const MAPBOX_ACCESS_TOKEN = REACT_APP_MAPBOX_ACCESS_TOKEN;
const SUPPORT_EMAIL = 'hey@flayr.life';
// const SUPPORT_MOBILE = '+1 (831) 789-2688';
const SUPPORT_MOBILE = '0488 851 655';

const SITE_URL = REACT_APP_SITE_URL;

const HEAP_APP_ID = REACT_APP_HEAP_APP_ID;
const GA_ID = REACT_APP_GA_ID;
const STORE_CATEGORY = REACT_APP_STORE_CATEGORY;
const DEFAULT_STORE = REACT_APP_DEFAULT_STORE;
const DEFAULT_CATEGORY = REACT_APP_DEFAULT_CATEGORY;

const LIVE_COURSES_CATEGORY_SLUG = 'makeup-hair-lessons';

const STREAM_CHAT_API_KEY = REACT_APP_STREAM_CHAT_API_KEY;

const LOCAL_STORAGE_USER_INFO_FIELD = 'flayr_user';
const LOCAL_STORAGE_USER_CHAT_FIELD = 'chatUserToken';
const RECAPTCHA_SITE_KEY = REACT_APP_RECAPTCHA_SITE_KEY;

export {
  ENV,
  API_URL,
  AWS_IMG_UPLOAD_URL,
  STRIPE_KEY,
  GOOGLE_MAPS_ACCESS_TOKEN,
  MAPBOX_ACCESS_TOKEN,
  PHONE_INPUT_DEFAULT_COUNTRY,
  PHONE_INPUT_SUPPORTED_COUNTRIES,
  SUPPORT_EMAIL,
  SUPPORT_MOBILE,
  SITE_URL,
  GA_ID,
  HEAP_APP_ID,
  PLACES_INPUT_AVAILABLE_COUNTRIES,
  STORE_CATEGORY,
  DEFAULT_STORE,
  DEFAULT_CATEGORY,
  STREAM_CHAT_API_KEY,
  LOCAL_STORAGE_USER_INFO_FIELD,
  LOCAL_STORAGE_USER_CHAT_FIELD,
  LIVE_COURSES_CATEGORY_SLUG,
  RECAPTCHA_SITE_KEY,
};
