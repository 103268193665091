import React from 'react';
import { Img, IScreenSizes } from '../Img/Img';
import { ShowIf } from '../ShowIf/ShowIf';

interface IAvatarProps {
  img?: string;
  alt?: string;
  size?: '8' | '10' | '12' | '14' | '16' | '20' | '32';
  name?: string;
  singleChar?: boolean;
  className?: string;
  index?: number;
  responsiveImg?: boolean;
  staticResponsiveImgSize?: number;
}

function getInitials(value?: string) {
  if (!value) {
    return value;
  }
  const split = value.split(' ');
  return split.map((sp) => sp[0]).join('');
}

export const Avatar: React.FC<React.PropsWithChildren<IAvatarProps>> = ({
  img,
  size = '12',
  name,
  singleChar,
  className = '',
  alt = '',
  responsiveImg,
  staticResponsiveImgSize,
}) => {
  return (
    <div
      className={`w-${size} h-${size} rounded-full flex justify-center items-center ${className} overflow-hidden ${
        !img && 'bg-primary text-primary bg-opacity-10'
      } text-white`}
    >
      <ShowIf
        condition={img}
        elseTemplate={(singleChar && name?.[0]) || getInitials(name)}
      >
        <div className="w-full h-full">
          <Img
            size="square"
            responsive={responsiveImg}
            staticResponsiveSize={staticResponsiveImgSize}
            src={img}
            round
            alt={alt}
            hideOnError
          />
        </div>
      </ShowIf>
    </div>
  );
};
