import React from 'react';
import CalendarNextPrevBtns from '../CalendarNextPrevBtns/CalendarNextPrevBtns';

interface ICalendarNavBarProps {
  month?: string;
  year?: string;
  onNextYear?: (e?: any) => void;
  onPrevYear?: (e?: any) => void;
  onNextMonth?: (e?: any) => void;
  onPrevMonth?: (e?: any) => void;
}

const CalendarNavBar: React.FC<React.PropsWithChildren<ICalendarNavBarProps>> = ({
  month,
  year,
  onNextMonth,
  onPrevMonth,
  onNextYear,
  onPrevYear,
}) => {
  return (
    <div className="flex justify-center">
      <CalendarNextPrevBtns
        title={`${month} ${year}`}
        className="flex justify-center w-full font-medium"
        onNext={onNextMonth}
        onPrev={onPrevMonth}
      />
      {/* <CalendarNextPrevBtns
        title={year}
        className="font-medium"
        onNext={onNextYear}
        onPrev={onPrevYear}
      /> */}
    </div>
  );
};
export default CalendarNavBar;
