import { SET_REQUESTS, REMOVE_REQUEST } from '../actions/types';
import { IAppointment } from '../types/appointment';
import { addOrRemoveItemInArray, removeItemFromArray } from '../utils';

const initialState: {
  pendingRequests: IAppointment[];
  pendingRequestsCount: number;
} = {
  pendingRequests: [],
  pendingRequestsCount: 0,
};

export function requestsReducer(state = initialState, { type, payload }) {
  switch (type) {
    case SET_REQUESTS:
      return payload;
    case REMOVE_REQUEST:
      const { pendingRequests, pendingRequestsCount } = state;
      return {
        pendingRequests: removeItemFromArray(pendingRequests, payload),
        pendingRequestsCount: pendingRequestsCount - 1,
      };
    default:
      return state;
  }
}
