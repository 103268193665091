import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import { getFontAwesomeIconArray } from '../utils';
import { Flex } from '../Flex/Flex';
import { ShowIf } from '../ShowIf/ShowIf';

interface IIconLabelProps extends React.HTMLAttributes<HTMLDivElement> {
  icon?: React.ReactNode;
  label?: React.ReactNode;
  iconContainerClassName?: string;
}

export const IconLabel: React.FC<React.PropsWithChildren<IIconLabelProps>> = ({
  icon,
  label,
  iconContainerClassName = 'w-6 flex-none',
  ...props
}) => {
  return (
    <Flex {...props}>
      <Flex className={iconContainerClassName}>
        <ShowIf condition={typeof icon === 'string'} elseTemplate={icon}>
          <FontAwesomeIcon icon={getFontAwesomeIconArray(icon)} />
        </ShowIf>
      </Flex>
      <div className="pl-3">{label}</div>
    </Flex>
  );
};
