import React from 'react';
import classnames from 'classnames';
import { Spinner } from '../Spinner/Spinner';
import { IDropShadow } from '../types/dropShadow';
import {
  getRoundedClassNames,
  getShadowClassNames,
} from '../utils/component-library-utils';
import { IRoundedCorners } from '../types/roundedCorners';

type TBtnColor =
  | 'default'
  | 'primary'
  | 'secondary'
  | 'success'
  | 'warning'
  | 'danger'
  | 'dark'
  | 'black'
  | 'plain'
  | 'accent'
  | 'inherit'
  | 'hot-pink';

type TBtnSize = 'xs' | 'sm' | 'md' | 'lg' | 'xl';

export interface IButtonProps
  extends IDropShadow,
    IRoundedCorners,
    React.HTMLAttributes<HTMLButtonElement> {
  type?: 'button' | 'submit';
  onClick?: (e?: any, id?: string, data?: any) => void;
  className?: string;
  color?: TBtnColor;
  outline?: boolean;
  round?: boolean;
  clear?: boolean;
  size?: TBtnSize;
  block?: boolean;
  loading?: boolean;
  hidden?: boolean;
  disabled?: boolean;
  style?: React.CSSProperties;
  id?: string;
  data?: any;
  ariaLabel?: string;
}

function getBgColor(bg: TBtnColor, outline?: boolean, clear?: boolean) {
  return {
    // solid bg colors
    'bg-gray-200 text-gray-900 lg:hover:bg-gray-100 active:bg-gray-100':
      !clear && !outline && bg === 'default',
    'text-white bg-gradient-to-t from-primary-1 to-primary-2 lg:hover:from-indigo-300 lg:hover:to-purple-300 active:from-indigo-300 active:to-purple-300':
      !clear && !outline && bg === 'primary',
    'text-white bg-primary lg:hover:bg-rose-600 active:bg-rose-600':
      !clear && !outline && bg === 'hot-pink',
    'text-white bg-gradient-to-t from-secondary-1 to-secondary-2 lg:hover:from-lightblue-200 lg:hover:to-blue-300 active:from-lightblue-200 active:to-blue-300':
      !clear && !outline && bg === 'secondary',
    'bg-red-500 text-white lg:hover:bg-red-400 active:bg-red-400':
      !clear && !outline && bg === 'danger',
    'bg-orange-400 text-white lg:hover:bg-orange-400 active:bg-orange-400':
      !clear && !outline && bg === 'warning',
    'bg-success text-white lg:hover:bg-green-400 active:bg-green-400':
      !clear && !outline && bg === 'success',
    'bg-white text-gray-600 lg:hover:bg-gray-100 active:bg-gray-100':
      !clear && !outline && bg === 'plain',
    'bg-gray-900 text-white lg:hover:bg-gray-700 active:bg-gray-700':
      !clear && !outline && bg === 'black',
    'bg-gray-700 text-white lg:hover:bg-gray-500 active:bg-gray-500':
      !clear && !outline && bg === 'dark',
    'bg-indigo-500 text-white lg:hover:bg-indigo-400 active:bg-indigo-400':
      !clear && !outline && bg === 'accent',
    'text-inherit': !clear && !outline && bg === 'inherit',
    // outline bg colors
    'border border-gray-400 text-gray-700 md:hover:bg-gray-200 hover:text-gray-900':
      outline && bg === 'default',
    'border border-primary-1 text-primary-1 md:hover:bg-primary-1 md:hover:text-white':
      outline && bg === 'primary',
    'border border-primary text-primary lg:hover:bg-primary lg:hover:text-white':
      outline && bg === 'hot-pink',
    'border border-secondary-1 text-secondary-1 md:hover:bg-secondary-1 md:hover:text-white':
      outline && bg === 'secondary',
    'border border-orange-400 text-orange-400 hover:bg-orange-400 hover:text-white':
      outline && bg === 'warning',
    'border border-red-500 text-red-500 hover:bg-red-500 hover:text-white':
      outline && bg === 'danger',
    'border border-green-500 text-green-500 hover:bg-success hover:text-white':
      outline && bg === 'success',
    'border border-gray-900 text-gray-900 hover:bg-gray-900 hover:text-white':
      outline && bg === 'black',
    'border border-gray-700 text-gray-700 hover:bg-gray-700 hover:text-white':
      outline && bg === 'dark',
    'border text-gray-700 hover:bg-gray-100 bg-opacity-50 hover:text-gray-900':
      outline && bg === 'plain',
    'border border-indigo-500 text-indigo-500 hover:bg-indigo-500 hover:text-white':
      outline && bg === 'accent',
  };
}

function getBtnSize(size: TBtnSize) {
  return {
    'px-2 text-xs': size === 'xs',
    'px-3 py-px text-sm': size === 'sm',
    'px-4 py-1 text-base': size === 'md',
    'px-6 py-2 text-xl': size === 'lg',
    'px-8 text-3xl py-2': size === 'xl',
  };
}

function getClearBtnTextColor(color: TBtnColor, clear?: boolean) {
  return {
    'text-gray-900': clear && color === 'default',
    'text-primary-1': clear && color === 'primary',
    'text-primary': clear && color === 'hot-pink',
    'text-secondary-1': clear && color === 'secondary',
    'text-white': clear && color === 'plain',
    'text-red-500': clear && color === 'danger',
  };
}
export const Button: React.FC<React.PropsWithChildren<IButtonProps>> = ({
  type = 'button',
  onClick,
  children,
  className = '',
  color = 'default',
  size = 'md',
  outline,
  round,
  shadow,
  clear,
  block,
  loading,
  hidden,
  disabled,
  style,
  id,
  data,
  ariaLabel,
  rounded = true,
  ...props
}) => {
  function handleClick(e) {
    onClick?.(e, id, data);
  }
  if (round) {
    rounded = 'full';
  }
  return (
    <button
      {...props}
      type={type}
      onClick={handleClick}
      aria-label={ariaLabel}
      disabled={loading}
      className={classnames(
        'justify-center items-center text-center focus:outline-none',
        className,
        getShadowClassNames(shadow),
        getRoundedClassNames(rounded),
        getBgColor(color, outline, clear),
        getBtnSize(size),
        getClearBtnTextColor(color, clear),
        {
          'bg-transparent border-none': clear,
          'text-current': clear && ['default', 'plain'].includes(color),
          hidden: hidden,
          'inline-flex': !block,
          'flex w-full': block,
          'opacity-50 pointer-events-none cursor-not-allowed': disabled,
        }
      )}
      style={style}
      id={id}
    >
      {children}
      {loading && (
        <span style={{ fontSize: '.27em' }} className="ml-3">
          <Spinner showing />
        </span>
      )}
    </button>
  );
};
