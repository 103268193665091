import React from 'react';
import classnames from 'classnames';
import { IRoundedCorners } from '../types/roundedCorners';
import { IDropShadow } from '../types/dropShadow';

export interface ICardProps extends IRoundedCorners, IDropShadow {
  title?: React.ReactNode;
  footer?: React.ReactNode;
  padding?: string;
  onClick?: () => void;
  bodyClassName?: string;
  className?: string;
}

const Card: React.FC<React.PropsWithChildren<ICardProps>> = ({
  title,
  footer,
  padding = '3',
  bodyClassName = 'bg-gray-100 bg-opacity-75',
  className = '',
  children,
  onClick,
  shadow = true,
  rounded = true,
}) => {
  return (
    <div
      className={classnames(className, {
        shadow: shadow === true,
        rounded: rounded === true,
        [`shadow-${shadow}`]: typeof shadow === 'string',
        [`rounded-${rounded}`]: typeof rounded === 'string',
      })}
      onClick={onClick}
    >
      {!!title && (
        <header
          className={classnames(`p-${padding}`, 'border-b border-gray-200')}
        >
          {title}
        </header>
      )}
      <section className={classnames(`p-${padding}`, bodyClassName)}>
        {children}
      </section>
      {!!footer && (
        <footer
          className={classnames(`p-${padding}`, 'border-t border-gray-200')}
        >
          {footer}
        </footer>
      )}
    </div>
  );
};
export { Card };
