import { useCreateSearchContext } from '../../../../contexts/createSearchContext';
import { Flex } from '../../../../flayr-components/Flex/Flex';
import { ILocation } from '../../../../types/location/location';
import { ObjectUtils } from '../../../../utils/objectUtils';
import { faCalendarDay, faUserPlus } from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import DateInput from './DateIntput/DateInput';
import ItemContainer from './ItemContainer/ItemContainer';
import LocationInput from './LocationInput/LocationInput';
import SearchBtn from './SearchBtn/SearchBtn';
import ServicesInput from './ServicesInput/ServicesInput';
import useDeviceMediaQuery from '../../../../hooks/useDeviceMediaQuery';

interface ICreateSearchBarProps {
  userInput?: {
    location?: ILocation;
    eventDate?: string;
    services?: string[];
    currency?: string;
    currencySymbol?: string;
    distanceUnit?: string;
    finishTime?: string;
    requesterInfo?: {
      name: string;
      mobile: string;
      email: string;
      event: string;
    };
  };
  locationPlaceholder?: string;
  popDownIdPrefix?: string;
  proximity?: number[];
  openedItem?: 'location' | 'date' | 'time' | 'services';
  onChange?: (e?: any) => void;
  onSubmit?: (e?: any) => void;
}

const CreateSearchBar: React.FC<React.PropsWithChildren<ICreateSearchBarProps>> = ({
  popDownIdPrefix = '',
  onSubmit,
  locationPlaceholder,
  proximity,
  openedItem,
}) => {
  const [activeItem, setActiveItem] = React.useState<string>('');
  const locations = React.useRef([]);
  const { isMobile } = useDeviceMediaQuery();

  const { userInput, store, category, setUserInput, loading } =
    useCreateSearchContext();
  const previousValue = React.useRef<any>(userInput);

  React.useEffect(() => {
    // clear locations list if the value already exists
    if (userInput.location?.coordinates.length && locations.current?.length) {
      locations.current = [];
    }
    let { updated } = ObjectUtils.diff(previousValue.current, userInput, true);
    const allUnchanged = JSON.stringify(updated) === '{}';
    // skip focus next if the changed item is services since we want to do this only after the user hits 'done'
    if (!allUnchanged && !updated.hasOwnProperty('services')) {
      openNextItem();
    }
    previousValue.current = userInput;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userInput]);

  React.useEffect(() => {
    if (openedItem) {
      setTimeout(() => {
        setActiveItem(openedItem);
      }, 0);
    }
  }, [openedItem]);

  React.useEffect(() => {
    const eventListener = () => {
      setActiveItem('');
    };
    window.addEventListener('click', eventListener);
    return () => {
      window.removeEventListener('click', eventListener);
    };
  }, []);

  React.useEffect(() => {
    focusInput();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeItem]);

  function openNextItem(e?) {
    if (e) {
      e.stopPropagation();
    }
    const { location, eventDate, finishTime, services } = userInput;

    let nextItem =
      (!(location?.coordinates?.length || locations.current?.length) &&
        'location') ||
      (!eventDate && 'eventDate') ||
      // (!finishTime && 'finishTime') ||
      (!services?.length && 'services') ||
      '';
    if (nextItem) {
      switch (nextItem) {
        case 'eventDate':
          nextItem = 'date';
          break;
        case 'finishTime':
          nextItem = 'time';
          break;
      }
      if (nextItem !== activeItem) {
        return setActiveItem(nextItem);
      } else {
        return focusInput();
      }
    }
    setActiveItem('');
    const storeSlug = store?.slug;
    const categorySlug = category?.slug;
    const data = { ...userInput, storeSlug, categorySlug };
    if (!data.location?.coordinates?.length && locations.current?.length) {
      return setUserInput?.({ location: locations.current[0] });
    }
    onSubmit?.(data);
  }

  function focusInput() {
    // focus next item when active item is set
    let target = '';
    switch (activeItem) {
      case 'location': {
        target = `#${popDownIdPrefix}-location`;
        (document.querySelector(target) as HTMLInputElement)?.focus();
        break;
      }
      case 'time': {
        target = activeItem;
        (document.querySelector(target) as HTMLInputElement)?.focus();
        const evt = new KeyboardEvent('keydown', { keyCode: 32 } as any);
        document.dispatchEvent(evt);
        break;
      }
    }
  }

  function handleLocationsUpdate(opt) {
    locations.current = opt;
  }

  return (
    <Flex
      className="w-full p-1 text-left bg-white rounded-full h-[77px]"
      y="stretch"
    >
      <ItemContainer
        className="min-w-[20rem]"
        grow
        label={
          <>
            Location{' '}
            {openedItem === 'location' && (
              <span className="px-3 text-xs text-primary leading-[1.5em]">
                Please enter location
              </span>
            )}
          </>
        }
        name="location"
        onClick={setActiveItem}
        popDownIdPrefix={popDownIdPrefix}
        active={activeItem === 'location'}
        firstItem
      >
        {!isMobile && (
          <LocationInput
            inputId={`${popDownIdPrefix}-location`}
            name="location"
            placeholder={locationPlaceholder}
            open={activeItem === 'location'}
            onOpen={setActiveItem}
            proximity={proximity}
            onLocationsUpdate={handleLocationsUpdate}
            inputClassName="placeholder:text-[#A3A3A3] leading-[21px]"
          />
        )}
      </ItemContainer>

      <div className="flex items-stretch self-stretch flex-none py-2 pr-[12px] bg-white">
        <div className="border-l border-[#E2E2E2]" />
      </div>

      <ItemContainer
        className="w-48"
        label="Date"
        name="date"
        onClick={setActiveItem}
        active={activeItem === 'date'}
        popDownIdPrefix={popDownIdPrefix}
        icon={<FontAwesomeIcon icon={faCalendarDay} />}
      >
        {!isMobile && (
          <DateInput
            open={activeItem === 'date'}
            onOpen={setActiveItem}
            popDownIdPrefix={popDownIdPrefix}
          />
        )}
      </ItemContainer>

      <div className="flex items-stretch self-stretch flex-none py-2 pr-[12px] bg-white">
        <div className="border-l border-[#E2E2E2]" />
      </div>

      {/* <ItemContainer
        className="w-2/12"
        label="Time"
        name="time"
        onClick={setActiveItem}
        active={activeItem === 'time'}
        popDownIdPrefix={popDownIdPrefix}
      >
        {isDesktop && (
          <TimeInput
            placeholder="Ready by time"
            onOpen={setActiveItem}
            open={activeItem === 'time'}
          />
        )}
      </ItemContainer>

      <div className="flex items-stretch self-stretch flex-none py-2 pr-1 bg-white">
        <div className="border-l border-gray-100" />
      </div> */}

      <ItemContainer
        className="w-48"
        label="Services"
        name="services"
        onClick={setActiveItem}
        active={activeItem === 'services'}
        popDownIdPrefix={popDownIdPrefix}
        icon={<FontAwesomeIcon icon={faUserPlus} />}
      >
        {!isMobile && (
          <ServicesInput
            open={activeItem === 'services'}
            onOpen={setActiveItem}
            popDownIdPrefix={popDownIdPrefix}
            onFinish={openNextItem}
          />
        )}
      </ItemContainer>

      <SearchBtn onSubmit={openNextItem} loading={loading} />
    </Flex>
  );
};
export default CreateSearchBar;
