import React from 'react';
import useBackendApi from '../hooks/useBackendApi';
import { getFormSettings } from '../services';
import { IBasicInfo } from '../types/basicInfo';
import { ILocation } from '../types/location/location';
import { IServiceType } from '../types/serviceType';
import { getClearedLocation } from '../utils/projectSpecificUtils';
interface ICreateSearchContext {
  userInput: {
    location?: ILocation;
    eventDate?: string;
    services?: string[];
    currency?: string;
    currencySymbol?: string;
    distanceUnit?: string;
    finishTime?: string;
    requesterInfo?: {
      name: string;
      mobile: string;
      email: string;
      event: string;
    };
  };
  store?: IBasicInfo<string>;
  category?: IBasicInfo<string>;
  serviceTypes?: IServiceType[];
  dataFetched?: boolean;
  initialSearch?: any;
  landingPage?: string; // tracking
  heapId?: string; // tracking
  loading?: boolean;
  setLoading?: (loading: boolean) => void;
  setUserInput?: (e?: any) => void;
  fetchFormSettings?: (e?: any) => void;
}

const CreateSearchContext = React.createContext<ICreateSearchContext>({
  userInput: {},
});

function reducer(state, action) {
  switch (action.type) {
    case 'SET_USER_INPUT':
      return { ...state, userInput: { ...state.userInput, ...action.payload } };
    case 'SET_STATE':
      return { ...state, ...action.payload };
    case 'SET_LOADING':
      return { ...state, loading: action.payload };
    default:
      return state;
  }
}

const CreateSearchContextProvider = (props) => {
  const [
    {
      userInput,
      store,
      category,
      serviceTypes,
      dataFetched,
      initialSearch,
      landingPage,
      heapId,
      loading,
    },
    dispatch,
  ] = React.useReducer(reducer, {
    userInput: {
      location: getClearedLocation(),
      eventDate: '',
      finishTime: '',
      services: [],
      experimentId: '',
    },
    store: {},
    category: {},
    serviceTypes: [],
    initialSearch: undefined,
    dataFetched: false,
    loading: false,
    // for tracking
    heapId: '',
    landingPage: '',
  });
  const { post } = useBackendApi();

  React.useEffect(() => {
    if (props.fetchStoreOnLoad) {
      const options = props.fetchFormSettingsParam;
      fetchFormSettings(options.fetchFormSettingsParam);
    }
  }, [props.fetchStoreOnLoad]);

  React.useEffect(() => {
    if (props.experimentId) {
      dispatch({
        type: 'SET_USER_INPUT',
        payload: {
          experimentId: props.experimentId,
        },
      });
    }
  }, [props.experimentId]);

  React.useEffect(() => {
    if (
      dataFetched &&
      serviceTypes.length &&
      props.enabledServiceTypeLables?.length
    ) {
      const filteredServiceTypes = serviceTypes.filter((st) =>
        props.enabledServiceTypeLables.includes(st.label.toLowerCase())
      );
      dispatch({
        type: 'SET_STATE',
        payload: { serviceTypes: filteredServiceTypes },
      });
    }
  }, [props.enabledServiceTypeLables, dataFetched]);

  const fetchFormSettings = React.useCallback(
    async (options?: any) => {
      let data: any = options || {};
      const res = await getFormSettings(post, data, { loader: false });
      let payload: any = {};
      const formSettings = res.data.formSettings;
      const { store, category, serviceTypes } = formSettings;

      let search = res.data.search;
      let searchValue = { ...userInput };

      if (search) {
        const {
          location,
          appointmentSpot,
          services,
          experimentId = '',
          landingPage = '',
          heapId = '',
        } = search;
        if (search.gclid) {
          localStorage.setItem('gclid', search.gclid);
        }

        const locationValue = {
          coordinates: location.coordinates,
          type: 'Point',
          address: location.addressFull,
        };
        searchValue = {
          location: locationValue,
          eventDate: appointmentSpot.eventDate,
          finishTime: appointmentSpot.finishTime,
          services: services.map((s) => s._id),
          experimentId,
        };
        if (landingPage) {
          payload.landingPage = landingPage;
        }
        if (heapId) {
          payload.heapId = heapId;
          setTimeout(() => {
            const heap = window['heap'];
            if (heap) {
              heap.identify(heapId);
            }
          }, 0);
        }
      }

      payload = {
        ...payload,
        store,
        category,
        serviceTypes,
        dataFetched: true,
        userInput: searchValue,
        initialSearch: { ...searchValue },
      };

      dispatch({
        type: 'SET_STATE',
        payload,
      });
    },
    [store.id, userInput, dispatch]
  );

  const setUserInput = (value) => {
    dispatch({ type: 'SET_USER_INPUT', payload: value });
  };

  const setLoading = React.useCallback(
    (loading: any) => {
      dispatch({
        type: 'SET_LOADING',
        payload: loading,
      });
    },
    [dispatch]
  );

  return (
    <CreateSearchContext.Provider
      value={{
        userInput,
        store,
        category,
        serviceTypes,
        dataFetched,
        setUserInput,
        fetchFormSettings,
        initialSearch,
        landingPage,
        heapId,
        loading,
        setLoading,
      }}
      children={props.children}
    />
  );
};

function useCreateSearchContext() {
  const ctx = React.useContext(CreateSearchContext);
  return ctx;
}

export default CreateSearchContextProvider;
export { useCreateSearchContext };
