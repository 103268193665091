import { SET_PROVIDER } from '../actions/types';
import { IProvider } from '../types/provider';
import { IUser } from '../types/user';

const initialState: IProvider = {
  providerCategories: undefined,
  userDetails: undefined,
  providerCategoryCount: NaN,
};

export function providerReducer(state = initialState, { type, payload }) {
  switch (type) {
    case SET_PROVIDER:
      return { ...state, ...payload };
    default:
      return state;
  }
}
