import { SET_LOADER } from '../actions/types';
import { ILoader } from '../types/loader';

const initialState: ILoader = {
  showing: false,
  message: '',
};

export function loaderReducer(state = initialState, { type, payload }) {
  switch (type) {
    case SET_LOADER:
      return { ...state, ...payload };
    default:
      return state;
  }
}
