import {
  APPEND_REQUEST_TO_QUOTE_DATA,
  RESET_QUOTE_DATA,
  SET_QUOTE_DATA,
  SET_QUOTE_DATA_FILTER_BY,
} from '../actions/types';
import { IOffer } from '../types/quote/offer';
import { IQuoteDataStore } from '../types/redux/quoteDataStore';

const initialState: IQuoteDataStore = {
  quote: undefined,
  offers: { mobile: [], studio: [] },
  profiles: { mobile: [], studio: [] },
  bookedOffer: undefined,
  filterBy: '',
  sorted: {
    mobileOrStudio: 'mobile',
    offeredList: [],
    requestedList: [],
    featuredList: [],
    providersList: [],
    bookingsList: [],
    bookedOutList: [],
    bookedOutPercentage: 0,
    sortBy: '',
    offersCount: {
      mobile: 0,
      studio: 0,
    },
  },
};

export function quoteDataReducer(state = initialState, { type, payload }) {
  switch (type) {
    case SET_QUOTE_DATA:
      return { ...state, ...payload };
    case RESET_QUOTE_DATA:
      return { ...initialState };
    case SET_QUOTE_DATA_FILTER_BY:
      return { ...state, filterBy: payload };
    case APPEND_REQUEST_TO_QUOTE_DATA:
      const { offer, customer, provider, search } = payload as IOffer;
      const mobileOrStudio = offer.location.mobileOrStudio;
      const newOffer: IOffer = { offer, customer, provider, search };
      const offers = {
        ...state.offers,
        [mobileOrStudio]: [...(state.offers?.[mobileOrStudio] || []), newOffer],
      };
      // after adding offers to the state, we also need to remove the provider from the profiles
      const removedProvidersList = state.profiles[mobileOrStudio].filter(
        (item) => {
          return item.provider.profileId !== newOffer.provider.profileId;
        }
      );
      const profiles = {
        ...state.profiles,
        [mobileOrStudio]: removedProvidersList,
      };
      return {
        ...state,
        profiles,
        offers,
      };
    default:
      return state;
  }
}
