import React from 'react';
import HamburgerMenu from '../../../../components/HamburgerMenu/HamburgerMenu';
import { Flex } from '../../../../flayr-components/Flex/Flex';
import Logo from '../../../../lib/Logo/Logo';
import LogoNew from '../../../../lib/LogoNew/LogoNew';

interface INavbarProps {
  onCtaClick?: (e?: any) => void;
}

const Navbar: React.FC<React.PropsWithChildren<INavbarProps>> = ({ onCtaClick }) => {
  return (
    <Flex className="sticky top-0 p-2 bg-black">
      <LogoNew className="px-3 text-white" />
      <div className="ml-auto">
        <HamburgerMenu iconClassName="text-white text-2xl" />
      </div>
    </Flex>
  );
};
export default Navbar;
