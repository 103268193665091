import React from 'react';
import { useClearableState } from '../hooks/useClearableState';
import { defaultFileUploader, fileToDataURI } from '../utils/fileUtils';
import { ShowIf } from '../ShowIf/ShowIf';
import { Img } from '../Img/Img';
import { Spinner } from '../Spinner/Spinner';

interface ImgUploaderProps {
  // container
  className?: string;
  showErrorMessage?: boolean;
  errorMessage?: string;

  // functions
  uploadFunction?: (e?: any, id?: any) => any;
  // renameFileFunction?: (e: File) => string;

  enableBuiltInImgUpload?: boolean;
  name?: string;
  disabled?: boolean;
  accept?: string;
  id?: string;
  maxFileSize?: number; // file size in mb
  multiple?: boolean;

  // preview
  previewUploadingImg?: boolean;
  previewClassName?: string;
  showChildrenDuringUpload?: boolean;

  // events
  onChange?: (e?: any) => void;
  onUpload?: (e?: any) => void;
  onUploadStart?: (e?: any) => void;
  onUploadError?: (e?: any) => void;
}

const ImgUploader: React.FC<React.PropsWithChildren<ImgUploaderProps>> = ({
  className = '',
  showErrorMessage,
  errorMessage = 'file size is too big.',

  enableBuiltInImgUpload,
  previewUploadingImg,
  showChildrenDuringUpload,
  name,
  disabled,
  accept = 'image/png, image/jpeg, image/heic',
  children,
  previewClassName = '',
  id,
  maxFileSize = 4.1,
  multiple,

  // functions
  uploadFunction,
  // renameFileFunction,

  // events
  onUpload,
  onChange,
  onUploadStart,
  onUploadError,
}) => {
  const [uploadingImgs, setUploadingImgs, clearUploadingImgs] =
    useClearableState<string[]>([]);

  const [error, setError] = React.useState<string>('');

  async function handleChange(e) {
    if (!multiple) {
      const file: File = e.target.files[0];
      const isFileValid = verifyFile(file);
      if (!isFileValid) {
        return;
      }
      onChange?.(e);
      if (enableBuiltInImgUpload || uploadFunction) {
        const url = await uploadFile(file);
        return onUpload?.(url);
      }
    }

    if (multiple) {
      const files: FileList = e.target.files;
      onChange?.(e);
      const promises: any[] = [];
      for (let i = 0; i < files.length; i++) {
        const file: File = files[i];
        const isFileValid = verifyFile(file);
        if (!isFileValid) {
          break;
        }
        if (enableBuiltInImgUpload || uploadFunction) {
          promises.push(uploadFile(file));
        }
      }
      if (promises.length === files.length) {
        const urls = await Promise.all(promises);
        return onUpload?.(urls);
      }
    }
  }

  function verifyFile(file: File) {
    if (!file) {
      return false;
    }
    const sizeInMb = file.size / 1024 ** 2;
    if (sizeInMb >= maxFileSize) {
      showErrorMessage && setError(errorMessage);
      onUploadError?.(errorMessage);
      return false;
    } else if (error) {
      setError('');
    }
    onUploadError?.('');
    return true;
  }

  async function uploadFile(file: File) {
    let previewUrl = '';
    if (previewUploadingImg) {
      previewUrl = await fileToDataURI(file);
      const urls: string[] = [...(uploadingImgs || []), previewUrl];
      setUploadingImgs(urls);
    }
    onUploadStart?.(previewUrl || file);
    const uploader = uploadFunction || defaultFileUploader;
    const url = await uploader(file, id);
    previewUploadingImg && clearUploadingImgs();
    return url;
  }

  return (
    <label className={className}>
      <input
        className="hidden w-0 h-0 opacity-0 appearance-none"
        type="file"
        accept={accept}
        disabled={disabled || !!uploadingImgs?.length}
        name={name}
        onChange={handleChange}
        multiple
      />
      {showChildrenDuringUpload && children}
      <ShowIf
        condition={previewUploadingImg && uploadingImgs?.length}
        elseTemplate={(!showChildrenDuringUpload && children) || null}
      >
        {uploadingImgs?.map((uploadingImg, idx) => (
          <div key={idx} className={'relative ' + previewClassName}>
            <Img src={uploadingImg} size="square" grayScale />
            <Spinner
              className="absolute top-0 left-0 flex items-center justify-center w-full h-full text-xs text-white"
              showing={!!uploadingImg}
            />
          </div>
        ))}
      </ShowIf>

      <ShowIf condition={error}>
        <div className="mt-2 text-sm text-red-500">{error}</div>
      </ShowIf>
    </label>
  );
};
export { ImgUploader };
