import React from 'react';

export function useToggle(isOn = false) {
  const [state, setState] = React.useState<{ on: boolean; off: boolean }>({
    on: isOn,
    off: !isOn,
  });

  function toggle() {
    setState({
      on: !state.on,
      off: !state.off,
    });
  }

  function close() {
    state.on && toggle();
  }

  function open() {
    state.off && toggle();
  }

  React.useEffect(() => {
    return () => {
      setState({
        on: false,
        off: true,
      });
    };
  }, []);

  return {
    toggle,
    opened: state.on,
    closed: state.off,
    open,
    close,
  };
}
