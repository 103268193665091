import React from 'react';
import classnames from 'classnames';
import { Modal } from '../../flayr-components/Modal/Modal';
import { Button } from '../../flayr-components/Button/Button';

interface IModalToastProps {
  type?: string;
  message?: string;
  onClose?: (e?: any) => void;
  open?: boolean;
}

const ModalToast: React.FC<React.PropsWithChildren<IModalToastProps>> = ({
  type,
  message,
  onClose,
  open,
}) => {
  return (
    <Modal removeWhenClosed open={open} size="xs" onClose={onClose}>
      <div
        className={classnames('text-2xl text-center uppercase font-bold', {
          'text-red-500': type === 'error',
          'text-green-500': type === 'success',
        })}
      >
        {type === 'error' && 'Error'}
        {type === 'success' && 'Success'}!
      </div>

      <div className="mt-5 text-center">{message}</div>

      <div className="mt-5 text-center">
        <Button
          size="lg"
          color={(type === 'error' && 'danger') || 'success'}
          onClick={onClose}
        >
          Close
        </Button>
      </div>
    </Modal>
  );
};
export default ModalToast;
