import { replaceUrlParamsWithValue } from '../utils';

export function fetchSeoInfo(reqFn, data?: any, options?: any) {
  const url = '/v1/seo/info';
  return reqFn(url, data, options);
}

export function fetchFormSettings(
  reqFn,
  data?: any,
  options?: any
): Promise<any> {
  const url = '/v1/search/formsettings';
  return reqFn(url, data, options);
}

export function getFormSettings(
  reqFn,
  data?: any,
  options?: any
): Promise<any> {
  const url = '/v1/quotes/formSettings';
  return reqFn(url, data, options);
}

export function getTestimonials(
  reqFn,
  data?: { profileId: string; categorySlug?: string; appointmentId?: string },
  options?: any
): Promise<any> {
  const { profileId, categorySlug, appointmentId } = data || {};
  const url = replaceUrlParamsWithValue('/v1/@/:profileId/testimonials', {
    profileId,
  });
  return reqFn(url, { categorySlug, appointmentId }, options);
}

export function patchReorderImages(reqFn, { albumId, mediaId, position }) {
  const url = `/v1/providers/albums/${albumId}/media/${mediaId}`;
  return reqFn(url, { position });
}

export function getBookedOutDates(reqFn, params?) {
  const url = `/v1/providers/bookedoutdates`;
  return reqFn(url, params);
}

export function postBookOutEntireDay(reqFn, { eventDate }, options?) {
  const url = `/v1/providers/bookedoutdates`;
  return reqFn(url, { eventDate }, options);
}

export function deleteBookedOutDay(reqFn, { eventDate }, options?) {
  const url = replaceUrlParamsWithValue(
    `/v1/providers/bookedoutdates/fullday/:eventDate`,
    { eventDate }
  );
  return reqFn(url, {}, options);
}

export function getCalendarAppointments(reqFn, params = {}, options?) {
  const url = `/v1/providers/appointments`;
  return reqFn(url, params, options);
}

export function getGigDetails(reqFn, { gigId }, options?) {
  const url = replaceUrlParamsWithValue(`/v1/providers/gigs/:gigId`, { gigId });
  return reqFn(url, {}, options);
}

export function getAppointmentDetails(reqFn, { appointmentId }, options?) {
  const url = replaceUrlParamsWithValue(
    `/v1/providers/appointments/:appointmentId`,
    { appointmentId }
  );
  return reqFn(url, {}, options);
}

export function getQuoteDetails(reqFn, { quoteId }, options?) {
  const url = replaceUrlParamsWithValue(`/v1/quotes/:quoteId`, { quoteId });
  return reqFn(url, {}, options);
}
