import React from 'react';
import PageBanner from '../../../../components/PageBanner/PageBanner';
import { DEFAULT_CATEGORY } from '../../../../config';
import MediaContainer from '../../../../containers/MediaContainer/MediaContainer';
import { Button } from '../../../../flayr-components/Button/Button';
import useToggle from '../../../../hooks/useToggle';
import { ImgGalleryModal } from '../../../../lib/ImgGalleryModal/ImgGalleryModal';
import Portal from '../../../../lib/Portal/Portal';
import { IMedia } from '../../../../types/media';
import Navbar from '../Navbar/Navbar';

interface IDesktopBannerProps {
  bannerTitle?: string;
  bannerSubTitle?: string;
  bannerImg?: string;
  bannerImgAlt?: string;
  imgPosition?: string;
  locationPlaceholder?: string;
  media?: IMedia[];
  popularPlaces?: any[];
  proximity?: number[];
  onLoaded?: (e?: any) => void;
}

const DesktopBanner: React.FC<React.PropsWithChildren<IDesktopBannerProps>> = ({
  bannerTitle,
  bannerSubTitle,
  bannerImg,
  bannerImgAlt,
  imgPosition = 'center',
  onLoaded,
  locationPlaceholder,
  media = [],
  popularPlaces = [],
  proximity,
}) => {
  const gallery = useToggle();

  return (
    <>
      <div>
        <div className="sticky top-0 pb-4 bg-black">
          <Navbar />
        </div>

        <PageBanner
          img={bannerImg}
          alt={bannerImgAlt}
          title={bannerTitle}
          subTitle={bannerSubTitle}
          imgPosition={imgPosition as any}
          onLoaded={onLoaded}
          locationPlaceholder={locationPlaceholder}
          className="z-20 hidden h-[calc(100vh-50px)] -mt-4 md:block"
          popularPlaces={popularPlaces}
          proximity={proximity}
          extension={
            // <MediaContainer categorySlug={DEFAULT_CATEGORY}>
            //   {({ media, totalMediaCount, fetchNextMediaSet }) => {
            //     return (

            //     );
            //   }}
            // </MediaContainer>
            <div
              hidden={!media.length}
              className="absolute bottom-0 right-0 z-10 p-5"
            >
              {/* <Button onClick={gallery.toggle} shadow="lg">
                Show Gallery
              </Button> */}
              <Portal target="body">
                <ImgGalleryModal
                  images={media.map((m) => m.mediaUrl)}
                  totalImages={media.length}
                  // onNext={fetchNextMediaSet}
                  open={gallery.opened}
                  onClose={gallery.close}
                  zIndex="40"
                />
              </Portal>
            </div>
          }
        />
      </div>
    </>
  );
};
export default DesktopBanner;
