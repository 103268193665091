import React from 'react';
import { ILocation } from '../types/location';
import { IStore } from '../types/store';
import { getClearedLocation } from '../utils/projectSpecificUtils';

export interface ISearchFormContext {
  value: {
    location: ILocation;
    date: string;
    services: string[];
    currency?: string;
    currencySymbol?: string;
    distanceUnit?: string;
    finishTime?: string;
    requesterInfo?: {
      name: string;
      mobile: string;
      email: string;
      event: string;
    };
  };
  popularPlaces?: any[];
  availableServices?: any[];
  searchFormData?: IStore;
  category?: string;
  store?: string;
  formOpen?: boolean;
  setValue?: (e?: any) => void;
  setCategory?: (e?: any) => void;
  setStore?: (e?: any) => void;
  setSearchFormData?: (e?: any) => void;
  setFormOpen?: (e?: any) => void;
  setPopularPlaces?: (e: any) => void;
  setAvailableServices?: (e: any) => void;
}
const SearchFormContext = React.createContext<ISearchFormContext>({
  value: {
    location: getClearedLocation(),
    date: '',
    services: [],
  },
});

// const SearchFormContextProvider = SearchFormContext.Provider;
const SearchFormContextProvider: React.FC<React.PropsWithChildren<any>> = (props) => {
  const [selectedCategory, setSelectedCategory] = React.useState('');
  const [store, setStore] = React.useState('');
  const [searchFormData, setSearchFormData] = React.useState();
  const [formOpen, setFormOpen] = React.useState(false);
  const [searchInput, setSearchInput] = React.useState({
    location: getClearedLocation(),
    date: '',
    services: [],
  });
  const [popularPlaces, setPopularPlaces] = React.useState<any[]>([]);
  const [availableServices, setAvailableServices] = React.useState<any[]>([]);
  return (
    <SearchFormContext.Provider
      value={{
        value: searchInput,
        category: selectedCategory,
        searchFormData,
        store,
        formOpen,
        popularPlaces,
        availableServices,
        setValue: setSearchInput,
        setCategory: setSelectedCategory,
        setStore,
        setSearchFormData,
        setPopularPlaces,
        setFormOpen,
        setAvailableServices,
      }}
      {...props}
    />
  );
};

export default SearchFormContext;
export { SearchFormContextProvider };
