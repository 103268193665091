import React from 'react';
import classnames from 'classnames';
import { IFontWeight } from '../types/fontWeight';
import { IFontSize } from '../types/fontSize';

export interface IHeadingProps extends IFontWeight, IFontSize {
  className?: string;
  tag?: 'h1' | 'h2' | 'h3' | 'h4' | 'h5' | 'h6';
  style?: React.CSSProperties;
  onClick?: (e?: any) => void;
}

const Heading: React.FC<React.PropsWithChildren<IHeadingProps>> = ({
  className = '',
  size = 'base',
  children,
  tag = 'h4',
  bold,
  style = {},
  onClick,
}) => {
  const Tag = tag as keyof JSX.IntrinsicElements;
  return (
    <Tag
      className={classnames(className, {
        [`text-${size}`]: !className.includes('text-'),
        'font-medium': bold === true,
        [`font-${bold}`]: typeof bold === 'string',
      })}
      style={style}
      onClick={onClick}
    >
      {children}
    </Tag>
  );
};
export { Heading };
