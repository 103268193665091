import { SET_SIGNUP_CATEGORY } from '../actions/types';
import { ISignupProviderCategory } from '../types/signupProviderCategory';

const initialState: ISignupProviderCategory | null = null;

export function signupCategoryReducer(state = initialState, { type, payload }) {
  switch (type) {
    case SET_SIGNUP_CATEGORY:
      return { ...(state || {}), ...payload };
    default:
      return state;
  }
}
