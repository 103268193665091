import React from 'react';
import { useCreateSearchContext } from '../contexts/createSearchContext';
import { ObjectUtils } from '../utils/objectUtils';
import { getClearedLocation } from '../utils/projectSpecificUtils';

interface ICreateSearchFormProps {
  useLocalState?: boolean; // use local state for input, otherwise use it directly from context
  disableFetchingFormSettingsOnChange?: boolean;
  onNextStep?: (e?: any) => void;
}
export default function useCreateSearchForm({
  useLocalState = false,
  onNextStep,
  disableFetchingFormSettingsOnChange = false,
}: ICreateSearchFormProps) {
  const createSearchContext = useCreateSearchContext();
  const [userInput, setUserInput] = React.useState<any>({
    location: getClearedLocation(),
    eventDate: '',
    finishTime: '',
    services: [],
  });
  const [resettingState, setResettingState] = React.useState<boolean>(false);

  const previousValue = React.useRef<any>(userInput);
  const store = createSearchContext.store;
  const category = createSearchContext.category;
  const serviceTypes = createSearchContext.serviceTypes;

  const inputValue =
    (useLocalState && userInput) || createSearchContext.userInput;
  const changeHandler =
    (useLocalState && handleInputChange) || createSearchContext.setUserInput;

  React.useEffect(() => {
    if (useLocalState) {
      setUserInput(createSearchContext.userInput);
      previousValue.current = createSearchContext.userInput;
    }
  }, [createSearchContext.userInput, useLocalState]);

  React.useEffect(() => {
    if (resettingState) {
      setUserInput(createSearchContext.userInput);
    }
  }, [resettingState]);

  React.useEffect(() => {
    if (resettingState) {
      return setResettingState(false);
    }
    let { updated } = ObjectUtils.diff(previousValue.current, inputValue, true);
    const inputChanged = JSON.stringify(updated) !== '{}';
    // skip next if the changed item is services since we want to do this only after the user hits 'done'
    // or if locoation was deleted
    const locationDeleted =
      updated.hasOwnProperty('location') &&
      !inputValue?.location?.coordinates.length;

    if (
      inputChanged &&
      !updated.hasOwnProperty('services') &&
      !locationDeleted
    ) {
      if (!inputValue.skipSubmit) {
        onNextStep?.();
      }
    }
    previousValue.current = inputValue;
  }, [inputValue]);

  React.useEffect(() => {
    // fetch store info if no store info is present
    let { updated } = ObjectUtils.diff(previousValue.current, inputValue, true);
    const inputChanged = JSON.stringify(updated) !== '{}';
    if (
      !store?.id &&
      inputChanged &&
      !disableFetchingFormSettingsOnChange &&
      useLocalState
    ) {
      createSearchContext.fetchFormSettings?.();
    }
  }, [userInput, useLocalState, disableFetchingFormSettingsOnChange]);

  function handleInputChange(input) {
    setUserInput({ ...userInput, ...input });
  }

  function resetLocalState() {
    if (useLocalState) {
      setResettingState(true);
    }
  }

  return {
    userInput: inputValue,
    searchContext: createSearchContext,
    setUserInput: changeHandler,
    resetLocalState,
    store,
    category,
    serviceTypes,
  };
}
