import React from 'react';
import classnames from 'classnames';
import { Button } from '../Button/Button';
import { IDropShadow } from '../types/dropShadow';
import {
  getShadowClassNames,
  getZIndexClassNames,
} from '../utils/component-library-utils';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronLeft } from '@fortawesome/pro-light-svg-icons';

interface INavBarProps extends IDropShadow {
  backBtn?: boolean;
  className?: string;
  padding?: '1' | '2' | '3' | '4';
  title?: React.ReactNode;
  titleClassName?: string;
  rightContent?: React.ReactNode;
  sticky?: boolean;
  zIndex?: '10' | '20' | '30' | '40' | '50';
  onBack?: (e?: any) => void;
  backBtnText?: string;
}

export const NavBar: React.FC<React.PropsWithChildren<INavBarProps>> = ({
  className,
  backBtn,
  padding = '3',
  shadow,
  title,
  titleClassName = '',
  rightContent,
  sticky,
  zIndex,
  onBack,
  backBtnText,
}) => {
  return (
    <nav
      className={classnames(
        'flex items-center',
        className,
        backBtn,
        `p-${padding}`,
        getShadowClassNames(shadow),
        { 'sticky top-0 left-0': sticky },
        getZIndexClassNames(zIndex)
      )}
    >
      {backBtn && (
        <Button clear className="-mx-3" onClick={onBack}>
          <FontAwesomeIcon icon={faChevronLeft} />
          {!!backBtnText && (
            <span className="inline-block ml-2">{backBtnText}</span>
          )}
        </Button>
      )}
      {!!title && <div className={`px-3 ${titleClassName}`}>{title}</div>}
      <div className="ml-auto">{rightContent}</div>
    </nav>
  );
};
