import React from 'react';
import { useInView } from 'react-intersection-observer';
import SearchFormContainer from '../../../../containers/SearchFormContainer/SearchFormContainer';
import CreateSearchContextProvider from '../../../../contexts/createSearchContext';
import useDeviceMediaQuery from '../../../../hooks/useDeviceMediaQuery';
import CreateSearchBar from '../CreateSearchBar/CreateSearchBar';
import DesktopSearchBarAlt from '../DesktopSearchBarAlt/DesktopSearchBarAlt';

interface IDesktopSearchBarProps {
  proximity?: number[];
  locationPlaceholder?: string;
  formSettings?: any;
  openedItem?: 'location' | 'date' | 'time' | 'services';
}

const DesktopSearchBar: React.FC<React.PropsWithChildren<IDesktopSearchBarProps>> = ({
  proximity,
  locationPlaceholder,
  formSettings,
  openedItem,
}) => {
  const desktopRef = useInView({ threshold: 1 });
  const { isDesktop, isTablet } = useDeviceMediaQuery();

  if (!isDesktop && !isTablet) {
    return null;
  }

  return (
    <section
      className={`
        relative hidden
        md:flex justify-center w-full md:mx-auto lg:w-11/12 xl:w-[70%] 2xl:w-7/12`}
    >
      <CreateSearchContextProvider formSettings={formSettings}>
        {(isDesktop || isTablet) && <div ref={desktopRef.ref} />}
        <div className="w-full">
          <SearchFormContainer
            component={CreateSearchBar}
            externalProps={{
              locationPlaceholder,
              proximity,
              openedItem: (desktopRef.inView && openedItem) || undefined,
            }}
          />
        </div>

        {!desktopRef.inView && (
          <DesktopSearchBarAlt
            locationPlaceholder={locationPlaceholder}
            proximity={proximity}
          />
        )}
      </CreateSearchContextProvider>
    </section>
  );
};
export default DesktopSearchBar;
